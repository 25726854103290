import { QuiBox, QuiButton, QuiButtonLink, QuiFlexBoxColumn, QuiIcon, QuiIconEnum } from '@tonicai/ui-quinine';
import { PageTitle } from '../PageTitle/PageTitle';
import { CurrentUserAvatar } from '../CurrentUserAvatar/CurrentUserAvatar';
import { useAtomValue } from 'jotai';
import { currentUserAtom } from '@/stores/auth';
import { CopyToClipboardButton } from '../CopyToClipboardButton/CopyToClipboardButton';
import { UploadFilesButton } from './UploadFilesButton';
import { RunPipelineButton } from './RunPipelineButton';
import { usePipelineContext } from './usePipelineContext';
import { NextSteps } from './NextSteps';
import { usePolling } from '@/hooks/usePolling';
import { client } from '@/services/HTTPClient';
import { useGetQuery } from '@/hooks/useGetQuery';
import useMutate, { mutationMethods } from '@/hooks/useMutate';
import { CancelFileParseJobButton } from '../CancelFileParseJobButton/CancelFileParseJobButton';

export function PipelineSidebar() {
    const { parseJobConfig } = usePipelineContext();
    const currentUser = useAtomValue(currentUserAtom);
    const needsRescan = useGetQuery(client, `/api/ParseJobConfig/${parseJobConfig.id}/needs-rescan`);
    const runningJobs = useGetQuery<{ runningJobs: string[] }>(client, `/api/ParseJobConfig/${parseJobConfig.id}/jobs/is_running`);
    const rescan = useMutate({ client, url: `/api/parsejobconfig/${parseJobConfig.id}/start`, method: mutationMethods.POST });
    const canRescan = (!runningJobs.data || runningJobs.data?.runningJobs.length === 0) && !!needsRescan.data;

    usePolling(
        () => {
            needsRescan.refetch();
            runningJobs.refetch();
        },
        true,
        1000
    );

    const runningJobId = (runningJobs.data?.runningJobs.length ?? 0) ? runningJobs.data?.runningJobs[0] : undefined;

    return (
        <QuiFlexBoxColumn gap="md">
            <PageTitle
                icon="git-branch"
                to={`/pipelines/${parseJobConfig.id}`}
                title={
                    <QuiFlexBoxColumn gap="xs">
                        <QuiBox overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                            {parseJobConfig.name}
                        </QuiBox>
                        <QuiBox display="flex" alignItems="center" gap="xs">
                            <div>
                                <CurrentUserAvatar size="sm" />
                            </div>
                            {currentUser?.displayName ? (
                                <QuiBox
                                    color="text-subdued"
                                    text="text-xs"
                                    flexGrow="1"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    whiteSpace="nowrap"
                                >{`Created by ${currentUser?.displayName}`}</QuiBox>
                            ) : null}
                        </QuiBox>
                    </QuiFlexBoxColumn>
                }
            >
                <QuiBox justifyContent="space-between" text="mono-text-xs" color="text-subdued" display="flex" gap="sm" alignItems="center">
                    <span data-test="pipeline-config-id">{parseJobConfig.id}</span>
                    <CopyToClipboardButton icon="clipboard" content={parseJobConfig.id} size="xs" />
                </QuiBox>
            </PageTitle>
            <QuiBox display="flex" gap="sm">
                <QuiBox display="flex" flexGrow="1" flexDirection="column">
                    {parseJobConfig.useInternalBucket ? <UploadFilesButton /> : <RunPipelineButton />}
                </QuiBox>
                <QuiButtonLink to={`/pipelines/${parseJobConfig.id}/settings`} iconLeft="settings" />
            </QuiBox>
            {!!canRescan && (
                <QuiBox bg="surface-warning" padding="sm" text="text-sm" display="flex" alignItems="start" gap="sm">
                    <div>
                        <QuiIcon size="md" color="text-warning" icon={QuiIconEnum.Info} />
                    </div>
                    <QuiBox>
                        Scan files to apply changes.
                        <QuiBox text="text-xs" color="text-brand-black-600">
                            A file scan is required to apply recent changes to your files.
                        </QuiBox>
                        {parseJobConfig.useInternalBucket && (
                            <QuiButton
                                onClick={() => {
                                    rescan.mutate({});
                                }}
                            >
                                Scan
                            </QuiButton>
                        )}
                    </QuiBox>
                </QuiBox>
            )}
            {parseJobConfig.useInternalBucket && runningJobId && !canRescan && (
                <CancelFileParseJobButton fileParseJobId={runningJobId} parseJobConfigId={parseJobConfig.id} />
            )}
            <NextSteps />
        </QuiFlexBoxColumn>
    );
}
