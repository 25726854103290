import { client } from '@/services/HTTPClient';
import { createGetEndpoint } from '@/stores';
import { FetchStatus, JobStatusString, LocalParseFileUploadRequestModel, UploadedFilesResponse } from '@/types';
import { AxiosError, AxiosProgressEvent, isCancel } from 'axios';
import { atom, createStore } from 'jotai';
import { atomWithRefresh } from 'jotai/utils';
import { nanoid } from 'nanoid';
import { PipelineFileUpload, PipelineFileUploadStatus } from './types';
import { isPipelineUploadStatus } from './utils';

export type FileUploadPipelineFileRowStatus = JobStatusString | PipelineFileUploadStatus;

export type FileUploadPipelineFileRow = {
    id: string;
    fileName: string;
    isUpload: boolean;
    uploadId?: string;
    status: FileUploadPipelineFileRowStatus;
    isDeletable: boolean;
    errorMessage?: string;
    fileLink?: string;
};

export function fileUploadPipelineAPI(parseJobConfigId: string) {
    const uploadEndpoint = `/api/parsejobconfig/${parseJobConfigId}/local-files/upload`;
    const store = createStore();
    const fileUploadsAtom = atom<PipelineFileUpload[]>([]);

    const {
        fetchData: fetchUploadedFiles,
        dataAtom: uploadedFilesResponseAtom,
        statusAtom: uploadedFilesResponseStatusAtom,
        cancelFetching: cancelFetchingUploadedFiles,
    } = createGetEndpoint<UploadedFilesResponse>(`/api/parsejobconfig/${parseJobConfigId}/local-files/all`, store);

    const uiStatusAtom = atom<Extract<FetchStatus, 'loading' | 'error' | 'success' | 'refreshing'>>((get) => {
        const status = get(uploadedFilesResponseStatusAtom);

        if (status === 'init' || status === 'accepted') {
            return 'loading';
        }

        if (status === 'not-found') {
            return 'error';
        }

        return status;
    });

    // This atom is used to generate the data for the FilesTableLocal component, so that
    // the rendering of the table is simpler and doesn't need to know about the underlying
    // data structures that make it up.
    const filesTableRowDataAtom = atomWithRefresh<FileUploadPipelineFileRow[]>((get) => {
        const fileList: FileUploadPipelineFileRow[] = [];

        const fileUploads = get(fileUploadsAtom);
        const uploadedFilesResponse: UploadedFilesResponse = get(uploadedFilesResponseAtom) ?? { files: [] };

        // Active File Uploads
        fileUploads.forEach((fileUpload) => {
            const matchingProcessedFile =
                (fileUpload.uploadStatus === 'success' ? uploadedFilesResponse?.files?.find((f) => f.fileName === fileUpload.fileName) : null) ??
                null;

            let status: FileUploadPipelineFileRowStatus = fileUpload.uploadStatus;

            // If the file has been uploaded but not processed yet, show it as queued
            if (fileUpload.uploadStatus === 'success' && ((matchingProcessedFile && !matchingProcessedFile?.result) || !matchingProcessedFile)) {
                status = 'Queued';
            }

            // If the file has been uploaded and has a file parse result status, show the file parse result status
            if (matchingProcessedFile?.result) {
                status = matchingProcessedFile.result.fileStatus;
            }

            const fileLink =
                matchingProcessedFile?.result?.fileStatus === 'Completed'
                    ? `/pipelines/${parseJobConfigId}/runs/${matchingProcessedFile.result.fileParseJobId}/files/${matchingProcessedFile.result.id}`
                    : undefined;
            fileList.push({
                id: fileUpload.id,
                fileName: fileUpload.fileName,
                isUpload: true,
                status,
                uploadId: fileUpload.id,
                isDeletable: matchingProcessedFile?.result ? true : false,
                errorMessage: fileUpload.errorMessage ?? undefined,
                fileLink,
            });
        });

        // Files that have been uploaded but have not been processed yet
        uploadedFilesResponse?.files?.forEach((file) => {
            const matchingFileUpload = fileUploads.find((f) => f.fileName === file.fileName && f.uploadStatus === 'success');

            // Only add the file to the list if it has not been processed by an
            // existing file parse job & it doesn't match a successfully uploaded file.
            // We don't want duplicates in the list.
            if (!matchingFileUpload) {
                const fileLink =
                    file?.result && file.result?.fileStatus === 'Completed'
                        ? `/pipelines/${parseJobConfigId}/runs/${file.result.fileParseJobId}/files/${file.result.id}`
                        : undefined;
                fileList.push({
                    id: file.fileName,
                    fileName: file.fileName,
                    isUpload: false,
                    status: file.result?.fileStatus ?? 'Queued',
                    isDeletable: true,
                    fileLink,
                });
            }
        });

        return fileList;
    });

    const hasUnprocessedFilesAtom = atom<boolean>((get) => {
        const filesTableRowData = get(filesTableRowDataAtom);

        return filesTableRowData.some((f) => f.status === 'Queued' || f.status === 'Running' || isPipelineUploadStatus(f.status));
    });

    const numberOfActiveUploadsAtom = atom<number>((get) => {
        return get(fileUploadsAtom).filter((f) => f.uploadStatus === 'uploading').length;
    });

    const allFileUploadsCompleteAtom = atom<boolean>((get) => {
        const fileUploads = get(fileUploadsAtom);
        const atLeastOneSucceeded = fileUploads.some((f) => f.uploadStatus === 'success');
        return fileUploads.length > 0 && atLeastOneSucceeded && fileUploads.every((f) => f.uploadStatus === 'success' || f.uploadStatus === 'error');
    });

    function abortFileUploads() {
        const fileUploads = store.get(fileUploadsAtom);

        Object.values(fileUploads).forEach((fileUpload) => {
            fileUpload.abortController?.abort();
        });
    }

    function addFileUpload(fileUpload: PipelineFileUpload) {
        store.set(fileUploadsAtom, (prev) => [fileUpload, ...prev]);
    }

    function deleteFileUpload(uploadId: string) {
        store.set(fileUploadsAtom, (prev) => {
            return prev.filter((f) => {
                return f.id !== uploadId;
            });
        });
    }

    function handleFileProgress(progressEvent: AxiosProgressEvent, uploadId: string, fileSize: number) {
        store.set(fileUploadsAtom, (prev) => {
            return prev.map((f) => {
                if (f.id !== uploadId) {
                    return f;
                }

                const totalSize = typeof progressEvent?.total === 'number' ? progressEvent.total : fileSize;
                const percentCompleted = Math.round((progressEvent.loaded * 100) / totalSize);

                return {
                    ...f,
                    percentCompleted,
                    uploadStatus: percentCompleted === 100 ? 'success' : 'uploading',
                };
            });
        });
    }

    function handleFileSuccess(uploadId: string) {
        store.set(fileUploadsAtom, (prev) => {
            return prev.map((f) => {
                if (f.id === uploadId) {
                    const updatedFileUpload = { ...f };

                    updatedFileUpload.uploadStatus = 'success';
                    updatedFileUpload.percentCompleted = 100;

                    return updatedFileUpload;
                }

                return f;
            });
        });
    }

    function uploadFile(file: File, onError: (error: AxiosError) => void | undefined) {
        const id = nanoid();
        const abortController = new AbortController();

        const metadata: LocalParseFileUploadRequestModel = {
            fileName: file.name,
            csvConfig: {},
        };

        const metadataBlob = new Blob([JSON.stringify(metadata)], {
            type: 'application/json',
        });

        // order matters here, file must be the last item
        const payload = new FormData();
        payload.append('document', metadataBlob);
        payload.append('file', file, file.name);

        addFileUpload({
            id,
            fileName: file.name,
            fileSize: file.size,
            percentCompleted: 0,
            uploadStatus: 'uploading',
            abortController,
        });

        client
            .post(uploadEndpoint, payload, {
                onUploadProgress: (progressEvent) => {
                    handleFileProgress(progressEvent, id, file.size);
                },
                signal: abortController.signal,
            })
            .then(() => {
                handleFileSuccess(id);
            })
            .catch((e: AxiosError) => {
                if (isCancel(e)) {
                    return;
                }

                if (onError) onError(e);
            });
    }

    function cleanup() {
        abortFileUploads();
        cancelFetchingUploadedFiles();
    }

    return {
        uploadFile,
        fileUploadsAtom,
        deleteFileUpload,
        uploadedFilesResponseAtom,
        store,
        numberOfActiveUploadsAtom,
        abortFileUploads,
        allFileUploadsCompleteAtom,
        storeOptions: {
            store,
        },
        uiStatusAtom,
        cleanup,
        fetchUploadedFiles,
        hasUnprocessedFilesAtom,
        filesTableRowDataAtom,
    };
}
