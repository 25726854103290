import { useDatasetContext } from '@/contexts/DatasetContext';
import { useGetQuery } from '@/hooks/useGetQuery';
import { client } from '@/services/HTTPClient';
import { DatasetFormState, deprecatedEntities, PiiTypeGeneratorState } from '@/types';
import { CustomEntity } from '@/types/custom-entities';
import { QuiBox, QuiButton, QuiIconEnum, QuiSegmentedControlButton, QuiSegmentedControlField, QuiSwitch, useQuiModal } from '@tonicai/ui-quinine';
import { useState } from 'react';
import { useForm } from 'react-final-form';
import { ENTITIES_ENDPOINT } from '../CustomEntities/CustomEntities';
import CustomEntityForm from '../CustomEntities/CustomEntityForm';
import { usePipelineContext } from '@/components/PipelineLayout/usePipelineContext';

type ICustomEntityRowProps = {
    entity: CustomEntity;
    canEditState: boolean;
    pipelineId?: string;
};
export function CustomEntityLabel({ displayName, description }: { displayName: string; description: string }) {
    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            (.*)
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className={'pii-type-label'}>{displayName}</span>
                <span className={'pii-type-description'}>{description}</span>
            </div>
        </div>
    );
}

const getCurrentStates = (generatorSetup: Record<string, PiiTypeGeneratorState>): Set<string> => {
    const states = Object.entries(generatorSetup)
        .filter(([piiType, state]) => !deprecatedEntities.includes(piiType) && state !== undefined && state !== null)
        .map(([, state]) => state);
    return new Set(states);
};

export default function CustomEntityRow({ entity, canEditState, pipelineId }: ICustomEntityRowProps) {
    const { parseJobConfig } = usePipelineContext();
    const customEntityQuery = useGetQuery<CustomEntity[]>(client, ENTITIES_ENDPOINT, {}, { enabled: false });
    const { dataset } = useDatasetContext();
    const [isActive, setIsActive] = useState(() => {
        return pipelineId ? entity.pipelineIds.includes(pipelineId) : entity.datasetIds.includes(dataset.id);
    });

    const form = useForm<DatasetFormState>();
    const formModal = useQuiModal();

    return (
        <QuiBox padding="none md" key={entity.name}>
            <QuiBox padding="sm none" borderTop="stroke-base">
                <QuiBox display="flex" alignItems="center" justifyContent="space-between">
                    <CustomEntityLabel displayName={entity.displayName} description={entity.description} />

                    <QuiBox display="flex" gap="sm" alignItems="center">
                        {!!parseJobConfig?.synthesizeFiles && canEditState && (
                            <QuiSegmentedControlField size="sm" name={`generatorSetup.${entity.name}`}>
                                <QuiSegmentedControlButton style={{ backgroundColor: 'transparent' }} value="Synthesis">
                                    Synthesis
                                </QuiSegmentedControlButton>

                                <QuiSegmentedControlButton style={{ backgroundColor: 'transparent' }} value="Redaction">
                                    Redaction
                                </QuiSegmentedControlButton>

                                <QuiSegmentedControlButton style={{ backgroundColor: 'transparent' }} value="Off">
                                    Off
                                </QuiSegmentedControlButton>
                            </QuiSegmentedControlField>
                        )}

                        <QuiButton iconLeft={QuiIconEnum.Settings} size="sm" onClick={formModal.open} />

                        <QuiSwitch
                            checked={isActive}
                            onChange={async (value) => {
                                setIsActive(value);
                                const states = getCurrentStates(form.getState().values.generatorSetup);

                                if (!value) {
                                    form.change(`generatorSetup.${entity.name}` as keyof DatasetFormState, undefined);
                                } else if (states.size !== 1) {
                                    form.change(`generatorSetup.${entity.name}` as keyof DatasetFormState, 'Redaction');
                                } else {
                                    const state = states.values().next().value;
                                    form.change(`generatorSetup.${entity.name}` as keyof DatasetFormState, state);
                                }

                                if (pipelineId) {
                                    const pipelineIds = [...entity.pipelineIds.filter((id) => id !== pipelineId), ...(value ? [pipelineId] : [])];
                                    await client.put(ENTITIES_ENDPOINT, { ...entity, pipelineIds });
                                } else {
                                    const datasetIds = [...entity.datasetIds.filter((id) => id !== dataset.id), ...(value ? [dataset.id] : [])];
                                    await client.put(ENTITIES_ENDPOINT, { ...entity, datasetIds });
                                }
                                form.submit();
                                customEntityQuery.refetch();
                            }}
                        />
                    </QuiBox>
                </QuiBox>

                <CustomEntityForm editEntity={entity} formModal={formModal} />
            </QuiBox>
        </QuiBox>
    );
}
