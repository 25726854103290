import { useCallback, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import styles from './FileDrop.module.scss';
import classNames from 'classnames';
import { QuiBox, QuiIcon, QuiText, useQuiToasts } from '@tonicai/ui-quinine';

type FileDropProps = {
    onFileSelect: (file: File) => void;
};

const accept = {
    'text/plain': ['.txt'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
};

export function FileDrop({ onFileSelect }: FileDropProps) {
    const [onDrag, setOnDrag] = useState(false);
    const addToast = useQuiToasts();

    const onDrop = useCallback(
        (acceptedFiles: File[], fileRejections: FileRejection[]) => {
            if (acceptedFiles.length > 0) {
                onFileSelect(acceptedFiles[0]);
            }

            //Files rejected because too-many-files were selected. This happens if you D&D 2 supported files at the same time.
            const filesThatCouldBeAccepted = fileRejections.filter(
                (f) => f.errors.filter((e) => e.code === 'too-many-files').length === 1 && f.errors.length === 1
            );

            if (fileRejections.length > 0) {
                let didAcceptRejectedFile = false;
                if (acceptedFiles.length === 0 && filesThatCouldBeAccepted.length > 0) {
                    onFileSelect(filesThatCouldBeAccepted[0].file);
                    didAcceptRejectedFile = true;
                }
                if (acceptedFiles.length === 0 && !didAcceptRejectedFile) {
                    addToast({
                        title: `The playground only supports txt and DocX files.  Please create a Dataset to process other file types such as PDF, Xlsx, and Png, Jpg, etc.`,
                        variant: 'warning',
                        timeout: 10000,
                    });
                }
                if (acceptedFiles.length === 0 && didAcceptRejectedFile) {
                    addToast({
                        title: `Only one file can be uploaded at a time, other files ignored.  To process multiple files, create a Dataset.`,
                        variant: 'info',
                        timeout: 10000,
                    });
                }
            }
        },
        [onFileSelect, addToast]
    );

    const onDragEnter = () => {
        setOnDrag(true);
    };

    const onDragLeave = () => {
        setOnDrag(false);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false, accept, onDragEnter, onDragLeave });

    return (
        <QuiBox padding={'lg'} style={{ pointerEvents: 'auto', height: 175 }}>
            <QuiBox
                padding={'sm'}
                bg={'background-base'}
                className={onDrag ? classNames(styles.dropZone, styles.onDrag) : styles.dropZone}
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                <QuiBox
                    display={'flex'}
                    flexDirection={'column'}
                    color="text-base--disabled"
                    gap={'md'}
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    <QuiIcon color={'text-subdued'} icon={'upload'} size={'lg'} />
                    <QuiText size="text-md">Or drag and drop a file to upload...</QuiText>
                </QuiBox>
            </QuiBox>
        </QuiBox>
    );
}
