import { PREVIEW_HIGHLIGHT_COLOR } from '@/constants';
import { PdfDimensions, PdfRedaction, PdfAndImageRedactionSource, PdfRedactionType, PdfWord, PiiTypeGeneratorState, Rectangle } from '../../types';
import { getAddedDrawnAreas, getPiiTypeGeneratorStateOrDefault, isRectangleEncompassingOther } from './PdfPageCalculationUtils';

const REDACTION_BACKGROUND_COLOR = 'rgba(0, 0, 0, 1)';
const SYNTHESIS_BACKGROUND_COLOR = 'rgba(255, 255, 255, 1)';

const ADDED_REDACTION_STROKE_COLOR = 'rgba(0, 117, 90, 1)';
const ADDED_REDACTION_FILL_COLOR = 'rgba(0, 117, 90, .25)';

const REMOVE_REDACTION_STROKE_COLOR = 'rgba(255, 76, 48, 1)';
const REMOVE_REDACTION_FILL_COLOR = 'rgba(255, 76, 48, .25)';

const SELECTED_REDACTION_STROKE_COLOR = 'rgba(16, 62, 182, 1)';
const SELECTED_REDACTION_FILL_COLOR = 'rgba(16, 62, 182, .25)';

const TEMPLATE_REDACTION_STROKE_COLOR = 'rgba(153, 154, 155, 1)';

const STROKE_WIDTH = 2;

const drawRectangle = (ctx: CanvasRenderingContext2D, rectangle: Rectangle, strokeStyle?: string, fillStyle?: string, text?: string) => {
    const { x, y, width, height } = rectangle;

    ctx.beginPath();
    ctx.rect(x, y, width, height);
    if (strokeStyle) {
        ctx.strokeStyle = strokeStyle;
        ctx.lineWidth = STROKE_WIDTH;
        ctx.stroke();
    }
    if (fillStyle) {
        ctx.fillStyle = fillStyle;
        ctx.fill();
    }
    if (text) {
        const fontSize = getFontSize(ctx, rectangle, text);

        ctx.font = `${fontSize}px sans-serif`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = 'black';

        ctx.fillText(text, x + width / 2, y + height / 2);
    }
};

const getFontSize = (ctx: CanvasRenderingContext2D, rectangle: Rectangle, text: string) => {
    const { width, height } = rectangle;

    let fontSize = 100;
    ctx.font = `${fontSize}px sans-serif`;

    while (ctx.measureText(text).width > width || fontSize > height) {
        fontSize--;
        ctx.font = `${fontSize}px sans-serif`;
    }

    return fontSize;
};

export const drawCurrentBox = (ctx: CanvasRenderingContext2D, currentDrawnBox: Rectangle, newAreaType?: PdfRedactionType) => {
    const strokeStyle = newAreaType === PdfRedactionType.AddRedaction ? ADDED_REDACTION_STROKE_COLOR : REMOVE_REDACTION_STROKE_COLOR;
    const fillStyle = newAreaType === PdfRedactionType.AddRedaction ? ADDED_REDACTION_FILL_COLOR : REMOVE_REDACTION_FILL_COLOR;

    drawRectangle(ctx, currentDrawnBox, strokeStyle, fillStyle);
};

export const drawRedactionArea = (
    ctx: CanvasRenderingContext2D,
    automaticRedaction: Rectangle,
    isCoveredByCurrentBox: boolean,
    isCoveredByExistingBox: boolean,
    isPreview: boolean,
    newAreaType: PdfRedactionType,
    generatorState?: PiiTypeGeneratorState,
    piiTypeLabel?: string,
    exampleRedaction?: string
) => {
    if (!isPreview && (isCoveredByCurrentBox || isCoveredByExistingBox)) return;

    const { strokeStyle, fillStyle } = getRedactionAreaStrokeAndFillStyles(
        isCoveredByCurrentBox,
        isCoveredByExistingBox,
        isPreview,
        newAreaType,
        generatorState
    );

    let text: string | undefined = undefined;
    if (!isPreview) {
        text = generatorState == 'Synthesis' ? (exampleRedaction ?? piiTypeLabel) : 'UNKNOWN';
    }

    drawRectangle(ctx, automaticRedaction, strokeStyle, fillStyle, text);
};

function getFillStyle(isPreview: boolean, generatorState: PiiTypeGeneratorState | undefined) {
    if (isPreview) return PREVIEW_HIGHLIGHT_COLOR;
    if (generatorState == 'Synthesis') return SYNTHESIS_BACKGROUND_COLOR;
    return REDACTION_BACKGROUND_COLOR;
}

const getRedactionAreaStrokeAndFillStyles = (
    isCoveredByCurrentBox: boolean,
    isCoveredByExistingBox: boolean,
    isPreview: boolean,
    newAreaType: PdfRedactionType,
    generatorState?: PiiTypeGeneratorState
) => {
    let strokeStyle: string | undefined;
    let fillStyle: string | undefined;

    if (isCoveredByCurrentBox && !isCoveredByExistingBox && newAreaType === PdfRedactionType.RemoveRedaction) {
        strokeStyle = REMOVE_REDACTION_STROKE_COLOR;
    } else if (isCoveredByExistingBox) {
        strokeStyle = REMOVE_REDACTION_FILL_COLOR;
    } else {
        fillStyle = getFillStyle(isPreview, generatorState);
    }

    return { strokeStyle, fillStyle };
};

export const drawNewWordOutlines = (
    ctx: CanvasRenderingContext2D,
    newWordOutlines: PdfRedaction[] | null,
    words: PdfWord[],
    isPreview: boolean,
    newAreaType?: PdfRedactionType,
    generatorSetup?: Record<string, PiiTypeGeneratorState>,
    drawnRectangle?: Rectangle
) => {
    if (drawnRectangle && newAreaType === PdfRedactionType.AddRedaction) {
        getAddedDrawnAreas(drawnRectangle, words).forEach((area) => {
            drawRedactionArea(ctx, area, false, false, isPreview, newAreaType ?? PdfRedactionType.RemoveRedaction, 'Redaction');
        });
    }

    [...(newWordOutlines ?? [])].forEach((redaction) => {
        redaction.areas.forEach((area) => {
            const generatorState = getPiiTypeGeneratorStateOrDefault(redaction.piiTypeLabel, generatorSetup);

            drawRedactionArea(
                ctx,
                area,
                false,
                false,
                isPreview ?? false,
                newAreaType ?? PdfRedactionType.RemoveRedaction,
                generatorState,
                redaction.piiTypeLabel,
                redaction.exampleRedaction
            );
        });
    });
};

export function scaleRectangle(rectangle: Rectangle, pageDimensions: PdfDimensions, canvasDimensions: PdfDimensions): Rectangle {
    const orientation = pageDimensions.orientation ?? 1;
    let { x, y, width, height } = rectangle;

    const rotatedWidth = [6, 8].includes(orientation) ? pageDimensions.height : pageDimensions.width;
    const rotatedHeight = [6, 8].includes(orientation) ? pageDimensions.width : pageDimensions.height;

    switch (orientation) {
        case 3: // 180°
            x = rotatedWidth - x - width;
            y = rotatedHeight - y - height;
            break;
        case 6: // 90° CW
            [x, y, width, height] = [rotatedWidth - y - height, x, height, width];
            break;
        case 8: // 270° CW
            [x, y, width, height] = [y, rotatedHeight - x - width, height, width];
            break;
    }

    const relativeX = x / rotatedWidth;
    const relativeY = y / rotatedHeight;
    const relativeWidth = width / rotatedWidth;
    const relativeHeight = height / rotatedHeight;

    return {
        x: relativeX * canvasDimensions.width * window.devicePixelRatio,
        y: relativeY * canvasDimensions.height * window.devicePixelRatio,
        width: relativeWidth * canvasDimensions.width * window.devicePixelRatio,
        height: relativeHeight * canvasDimensions.height * window.devicePixelRatio,
    };
}

export function scalePdfRedaction(pdfRedaction: PdfRedaction, pdfDimensions: PdfDimensions, canvasRect: DOMRect): PdfRedaction {
    return {
        ...pdfRedaction,
        areas: pdfRedaction.areas.map((a) => ({
            ...a,
            ...scaleRectangle(a, pdfDimensions, canvasRect),
        })),
    };
}

export const drawAutomaticRedactions = (
    ctx: CanvasRenderingContext2D,
    automaticJobRedactions: PdfRedaction[] | null,
    removingRedactions: PdfRedaction[] | null,
    drawnRectangle: Rectangle | undefined,
    newAreaType: PdfRedactionType | undefined,
    isPreview: boolean | undefined,
    generatorSetup: Record<string, PiiTypeGeneratorState> | undefined
) => {
    [...(automaticJobRedactions ?? [])].forEach((redaction) => {
        redaction.areas.forEach((pdfRedactionArea) => {
            const isCoveredByDrawnBox = !!drawnRectangle && isRectangleEncompassingOther(drawnRectangle, pdfRedactionArea);

            const isCoveredByOtherRedaction = (removingRedactions ?? [])
                .flatMap((d) => d.areas)
                .some((otherArea) => {
                    return isRectangleEncompassingOther(otherArea, pdfRedactionArea);
                });

            const generatorState = getPiiTypeGeneratorStateOrDefault(redaction.piiTypeLabel, generatorSetup);

            drawRedactionArea(
                ctx,
                pdfRedactionArea,
                isCoveredByDrawnBox,
                isCoveredByOtherRedaction,
                isPreview ?? false,
                newAreaType ?? PdfRedactionType.RemoveRedaction,
                generatorState,
                redaction.piiTypeLabel,
                redaction.exampleRedaction
            );
        });
    });
};

function getManualRedactionsStrokeColor(
    source: PdfAndImageRedactionSource | undefined,
    newSource: PdfAndImageRedactionSource | undefined,
    redactionType: PdfRedactionType,
    isSelected: boolean
) {
    if (isSelected) return SELECTED_REDACTION_STROKE_COLOR;
    if (source !== newSource) return TEMPLATE_REDACTION_STROKE_COLOR;
    if (redactionType === PdfRedactionType.AddRedaction) return ADDED_REDACTION_STROKE_COLOR;
    return REMOVE_REDACTION_STROKE_COLOR;
}

export const drawManualRedactions = (
    ctx: CanvasRenderingContext2D,
    redactions: PdfRedaction[],
    newRedactionSource: PdfAndImageRedactionSource,
    isPreview: boolean
) => {
    if (!isPreview) return;

    redactions.forEach((redaction) => {
        redaction.areas.forEach((area) => {
            const isSelected = !!redaction.isSelected;

            const strokeStyle = getManualRedactionsStrokeColor(redaction.source, newRedactionSource, redaction.type, isSelected);
            const fillStyle = isSelected ? SELECTED_REDACTION_FILL_COLOR : undefined;

            drawRectangle(ctx, area, strokeStyle, fillStyle);
        });
    });
};

export function resizeCanvas(container: HTMLDivElement | null, canvas: HTMLCanvasElement | null) {
    if (!container || !canvas) return;

    const containerRect = container.getBoundingClientRect();
    canvas.width = containerRect.width * window.devicePixelRatio;
    canvas.height = containerRect.height * window.devicePixelRatio;
    canvas.style.width = `${containerRect.width}px`;
    canvas.style.height = `${containerRect.height}px`;
}
