import { useDatasetContext } from '@/contexts/DatasetContext';
import CustomEntityRow from '@/pages/Dataset/CustomEntityRow';
import { PiiTypeInfo } from '@/types';
import { CustomEntity } from '@/types/custom-entities';
import { QuiBox, QuiButton, QuiIcon, QuiIconEnum, QuiText } from '@tonicai/ui-quinine';
import { useRef, useState } from 'react';
import UndetectedEntity from './UndetectedEntity';

type ExpandedPiiTypeInfo = PiiTypeInfo & {
    entity?: CustomEntity;
};

type UndetectedEntityTypesProps = Readonly<{
    undetectedEntities: ExpandedPiiTypeInfo[];
    undetectedCustomEntities?: CustomEntity[];
    customEntityMap: Record<string, CustomEntity> | undefined;
}>;

export default function UndetectedEntityTypes({ undetectedEntities, undetectedCustomEntities }: UndetectedEntityTypesProps) {
    const [isExpanded, setIsExpanded] = useState(true);
    const scrollRef = useRef<HTMLDivElement>(null);
    const { dataset } = useDatasetContext();
    const hasFiles = dataset.files?.length > 0;
    const undetectedCustomNames =
        undetectedCustomEntities?.map((e) => ({
            value: e.name,
            label: e.displayName,
            icon: QuiIconEnum.Help,
            description: e.description,
            entity: e,
        })) ?? [];
    const entities = undetectedEntities.concat(undetectedCustomNames).sort((a, b) => a.label.localeCompare(b.label));
    if (!hasFiles)
        return (
            <div>
                <QuiText weight="medium" size="text-sm" style={{ marginBottom: '1em' }}>
                    Entity types
                </QuiText>
                <QuiBox display="flex" flexDirection="column" gap="xs" border="stroke-base" borderRadius="md">
                    {entities.map((entity) =>
                        entity.entity ? (
                            <CustomEntityRow key={entity.entity.id} entity={entity.entity} canEditState={true} />
                        ) : (
                            <QuiBox key={entity.label} style={{ backgroundColor: 'white' }}>
                                <UndetectedEntity entity={entity} />
                            </QuiBox>
                        )
                    )}
                </QuiBox>
            </div>
        );

    return (
        <QuiBox display="flex" flexDirection="column" gap="xs" border="stroke-base" borderRadius="md">
            <QuiBox padding="md">
                <QuiBox display="flex" alignItems="center" justifyContent="space-between">
                    <QuiBox display="flex" alignItems="center" gap="sm">
                        <QuiIcon icon={QuiIconEnum.Minus} />
                        <div ref={scrollRef}>
                            <div className={'pii-type-label'}>Entity types not found</div>
                            <div className={'pii-type-description'}>Textual did not find these entity types in the files</div>
                        </div>
                    </QuiBox>
                    <QuiButton
                        iconRight={isExpanded ? QuiIconEnum.ChevronUp : QuiIconEnum.ChevronDown}
                        size="sm"
                        variant="outline-default"
                        onClick={() => {
                            setIsExpanded((current) => !current);
                            scrollRef?.current?.scrollIntoView({
                                block: 'center',
                                inline: 'center',
                            });
                        }}
                    />
                </QuiBox>
            </QuiBox>
            {isExpanded &&
                entities.map((entity) => {
                    if (entity.entity) {
                        return <CustomEntityRow key={entity.entity.id} entity={entity.entity} canEditState={true} />;
                    }
                    return <UndetectedEntity key={entity.label} entity={entity} />;
                })}
        </QuiBox>
    );
}
