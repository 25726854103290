import { SdkSnippet } from '@/components/GettingStartedPlayground/SdkSnippet';
import { fetchDeidentifyResponse, fileAtom, rulesAtom, updateGeneratorSetup } from './Playground/state';
import { usePlaygroundContext } from './Playground/usePlaygroundContext';
import styles from './GettingStartedPlayground.module.scss';
import { Editor } from './Playground/Editor';
import { PiiTypeEnum, PiiTypeGeneratorState } from '@/types';
import { QuiBox } from '@tonicai/ui-quinine';
import { useEffect, useState } from 'react';
import { useAtomValue } from 'jotai';

export function GettingStartedPlayground() {
    const storeOptions = usePlaygroundContext();

    const rules = useAtomValue(rulesAtom, storeOptions);
    const selectedFile = useAtomValue(fileAtom, storeOptions);

    const [editorContent, setEditorContent] = useState<string>('');

    useEffect(() => {
        fetchDeidentifyResponse(storeOptions.store);
        // eslint-disable-next-line
    }, [rules]);

    const handleClear = () => {
        updateGeneratorSetup(storeOptions.store, new Map<PiiTypeEnum, PiiTypeGeneratorState>());
    };

    return (
        <QuiBox display={'flex'} flexDirection={'column'} style={{ height: '100%' }} flexGrow={'1'} gap={'md'}>
            <Editor
                spellCheck={false}
                extraContainerClassName={styles.fullHeightEditor}
                extraAppClassName={styles.fullHeightEditor}
                onContentChange={setEditorContent}
                onClear={handleClear}
            />
            <SdkSnippet content={editorContent} fileName={selectedFile?.name} />
        </QuiBox>
    );
}
