import { useDatasetContext } from '@/contexts/DatasetContext';
import { CustomEntity } from '@/types/custom-entities';
import { QuiBox, QuiButton, QuiIconEnum, QuiText } from '@tonicai/ui-quinine';
import { useRef, useState } from 'react';
import CustomEntityRow from './CustomEntityRow';

type CustomEntityTypesProps = Readonly<{
    customEntities: CustomEntity[] | undefined;
    pipelineId?: string;
}>;

export default function CustomEntityTypes({ customEntities, pipelineId }: CustomEntityTypesProps) {
    const [isExpanded, setIsExpanded] = useState(true);
    const scrollRef = useRef<HTMLDivElement>(null);
    const { dataset } = useDatasetContext();
    const hasFiles = !!pipelineId || dataset.files?.length > 0;

    if (!hasFiles)
        return (
            <div>
                <QuiText weight="medium" size="text-sm" style={{ marginBottom: '1em' }}>
                    Custom Entity types
                </QuiText>
                <QuiBox display="flex" flexDirection="column" gap="xs" border="stroke-base" borderRadius="md">
                    {customEntities?.map((entity) => {
                        return (
                            <QuiBox key={entity.id} style={{ backgroundColor: 'white' }}>
                                <CustomEntityRow entity={entity} canEditState={false} />
                            </QuiBox>
                        );
                    })}
                </QuiBox>
            </div>
        );

    return (
        <QuiBox display="flex" flexDirection="column" gap="xs" border="stroke-base" borderRadius="md">
            <QuiBox padding="md">
                <QuiBox display="flex" alignItems="center" justifyContent="space-between">
                    <QuiBox display="flex" alignItems="center" gap="sm">
                        (.*)
                        <div ref={scrollRef}>
                            <div className={'pii-type-label'}>Inactive custom entity types</div>
                            <div className={'pii-type-description'}>These custom entity types are not currently active in this dataset</div>
                        </div>
                    </QuiBox>
                    <QuiButton
                        iconRight={isExpanded ? QuiIconEnum.ChevronUp : QuiIconEnum.ChevronDown}
                        size="sm"
                        variant="outline-default"
                        onClick={() => {
                            setIsExpanded((current) => !current);
                            scrollRef?.current?.scrollIntoView({
                                block: 'center',
                                inline: 'center',
                            });
                        }}
                    />
                </QuiBox>
            </QuiBox>
            {isExpanded &&
                customEntities?.map((entity) => <CustomEntityRow key={entity.id} entity={entity} canEditState={false} pipelineId={pipelineId} />)}
        </QuiBox>
    );
}
