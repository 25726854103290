import {
    clearFileUploadState,
    REDACT_FILE_STATUS_FAILURE_REASONS,
    redactFileResponseAtom,
    redactFileResponseStatusAtom,
    redactFileStatusAtom,
} from '@/components/GettingStartedPlayground/Playground/state';
import { usePlaygroundContext } from '@/components/GettingStartedPlayground/Playground/usePlaygroundContext';
import { QuiBadge, QuiBox, QuiButton, QuiSpinner, QuiText } from '@tonicai/ui-quinine';
import { useAtomValue } from 'jotai';
import styles from './PlaygroundFileEditorContainer.module.scss';
import classNames from 'classnames';

type ErrorNotificationProps = {
    errorReason: string;
    onClose: () => void;
};

type PlaygroundFilePreviewContainerProps = {
    extraClassName?: string;
};

function getErrorMessage(errorReason: string) {
    const isPrecannedFailureReason = REDACT_FILE_STATUS_FAILURE_REASONS.includes(errorReason as string);

    if (!isPrecannedFailureReason && errorReason.length > 0) {
        return errorReason;
    }

    if (errorReason === 'JobTookTooLong') {
        return 'This job took loo long to process. Please try again with another file or GO TO DATASETS';
    }

    if (errorReason === 'JobFailedToUpload_InvalidCsv') {
        return 'Unable to process CSV.  Make sure your CSV is valid and try again.';
    }
    return 'Something happened when processing your file. Please try again or chat with support.';
}

function ErrorNotification({ errorReason, onClose }: ErrorNotificationProps) {
    let errorText = getErrorMessage(errorReason);
    const isPrecannedFailureReason = REDACT_FILE_STATUS_FAILURE_REASONS.includes(errorReason as string);

    if (!isPrecannedFailureReason && errorReason.length > 0) {
        errorText = errorReason;
    }

    if (errorReason === 'JobTookTooLong') {
        errorText = 'This job took loo long to process. Please try again with another file or GO TO DATASETS';
    }

    if (errorReason === 'JobFailedToUpload_InvalidCsv') {
        errorText = 'Unable to process CSV.  Make sure your CSV is valid and try again.';
    }

    return (
        <QuiBox className={styles.errorNotification} display={'flex'} padding={'md'} gap={'xs'} borderRadius={'lg'} border={'stroke-base'}>
            <QuiBadge variant={'danger'} iconLeft={'alert-circle'} />
            <QuiBox display={'flex'} flexDirection={'column'}>
                <QuiText color={'text-danger'}>Error uploading file</QuiText>
                <QuiText size={'text-xs'}>{errorText}</QuiText>
            </QuiBox>
            <QuiButton variant={'minimal'} iconLeft={'x'} onClick={onClose} />
        </QuiBox>
    );
}

export function PlaygroundFileEditorContainer({ extraClassName }: PlaygroundFilePreviewContainerProps) {
    const storeOptions = usePlaygroundContext();

    const redactionRequestStatus = useAtomValue(redactFileResponseStatusAtom, storeOptions);
    const redactStatus = useAtomValue(redactFileStatusAtom, storeOptions);
    const redactFileResponse = useAtomValue(redactFileResponseAtom, storeOptions);

    const handleErrorClose = () => {
        clearFileUploadState(storeOptions.store);
    };

    if (redactionRequestStatus === 'loading') {
        return (
            <QuiBox className={classNames(extraClassName, styles.playgroundFileContainer, styles.spinnerContainer)}>
                <QuiText size={'text-xl'}>Processing...</QuiText>
                <QuiSpinner size={'xl'} />
            </QuiBox>
        );
    }

    if (redactStatus === 'Success') {
        return (
            <QuiBox className={classNames(extraClassName, styles.playgroundFileContainer, 'fs-mask')}>
                <div style={{ height: '100%' }} dangerouslySetInnerHTML={{ __html: redactFileResponse!.previewData }} />
            </QuiBox>
        );
    } else {
        return (
            <QuiBox className={classNames(extraClassName, styles.playgroundFileContainer)}>
                <ErrorNotification onClose={handleErrorClose} errorReason={redactStatus} />
            </QuiBox>
        );
    }
}
