import { PiiTypeEnum, PiiTypeGeneratorState } from '@/types';
import { QuiBox, QuiButton, QuiFlexBoxColumn } from '@tonicai/ui-quinine';
import { useCallback } from 'react';
import { useForm } from 'react-final-form';
import { GeneratorConfig } from './GeneratorConfig';
import CustomEntityTypes from '@/pages/Dataset/CustomEntityTypes';
import { useGetQuery } from '@/hooks/useGetQuery';
import { CustomEntity } from '@/types/custom-entities';
import { client } from '@/services/HTTPClient';
import { ENTITIES_ENDPOINT } from '@/pages/CustomEntities/CustomEntities';
import CustomEntityRow from '@/pages/Dataset/CustomEntityRow';
import { usePipelineContext } from '../PipelineLayout/usePipelineContext';

type GeneratorFieldsProps = Readonly<{
    generatorStatesName: string;
    generatorMetadataName: string;
    disabled?: boolean;
}>;

export function GeneratorFields({ generatorStatesName, disabled }: GeneratorFieldsProps) {
    const { parseJobConfigId, parseJobConfig } = usePipelineContext();
    disabled = disabled ?? false;
    const form = useForm();

    const customEntityQuery = useGetQuery<CustomEntity[]>(client, ENTITIES_ENDPOINT);
    const customEntities = customEntityQuery.data;
    const pipelineId = parseJobConfigId!;

    const inactiveCustomEntities = customEntities?.filter((customEntity) => {
        return !customEntity.pipelineIds.includes(pipelineId);
    });

    const activeCustomEntities = customEntities?.filter((customEntity) => {
        return customEntity.pipelineIds.includes(pipelineId);
    });

    const bulkApplySetting = useCallback(
        (piiTypeGeneratorState: PiiTypeGeneratorState) => {
            form.batch(() => {
                Object.entries(PiiTypeEnum).forEach(([, value]) => {
                    form.change(`${generatorStatesName}.${value}`, piiTypeGeneratorState);
                });
                activeCustomEntities?.forEach((entity) => {
                    form.change(`${generatorStatesName}.${entity.name}`, piiTypeGeneratorState);
                });
            });
        },
        [generatorStatesName, form, activeCustomEntities]
    );

    return (
        <QuiFlexBoxColumn gap="sm">
            {parseJobConfig.synthesizeFiles && (
                <QuiBox
                    bg="background-base"
                    border="stroke-base"
                    padding="sm"
                    alignItems="center"
                    borderRadius="md"
                    display="flex"
                    justifyContent="space-between"
                >
                    <QuiBox text="text-sm" weight="medium">
                        Bulk Edit
                    </QuiBox>

                    <QuiBox display="flex" gap="xs">
                        <QuiButton
                            size="sm"
                            type="button"
                            disabled={disabled}
                            onClick={() => {
                                bulkApplySetting('Synthesis');
                            }}
                        >
                            Synthesize
                        </QuiButton>
                        <QuiButton
                            size="sm"
                            type="button"
                            disabled={disabled}
                            onClick={() => {
                                bulkApplySetting('Redaction');
                            }}
                        >
                            Redact
                        </QuiButton>
                        <QuiButton
                            size="sm"
                            type="button"
                            disabled={disabled}
                            onClick={() => {
                                bulkApplySetting('Off');
                            }}
                        >
                            Off
                        </QuiButton>
                    </QuiBox>
                </QuiBox>
            )}

            <QuiFlexBoxColumn gap="xs" border="stroke-base" overflow="hidden" borderRadius="md" bg="background-base">
                {parseJobConfig.synthesizeFiles &&
                    Object.entries(PiiTypeEnum).map(([, value], index, list) => (
                        <QuiBox key={value} borderBottom={index < list.length - 1 ? 'stroke-base' : undefined}>
                            <GeneratorConfig disabled={disabled} piiType={value} />
                        </QuiBox>
                    ))}
                {activeCustomEntities
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    .map((value, index, list) => (
                        <QuiBox key={value.id} borderBottom={index < list.length - 1 ? 'stroke-base' : undefined}>
                            <CustomEntityRow entity={value} canEditState pipelineId={pipelineId} />
                        </QuiBox>
                    ))}
            </QuiFlexBoxColumn>
            <CustomEntityTypes pipelineId={pipelineId} customEntities={inactiveCustomEntities} />
        </QuiFlexBoxColumn>
    );
}
