import { EndpointGuard } from '@/components/EndpointGuard/EndpointGuard';
import { ParseJobFileParseJobs } from '@/components/ParseJobFileParseJobs/ParseJobFileParseJobs';
import { PipelineFileStatistics } from '@/components/PipelineFileStatistics/PipelineFileStatistics';
import { useEffect, useState } from 'react';
import { fetchFileStats, fetchParseJobConfigFileParseJobs, fetchParseJobConfigFiles } from '@/stores';
import { FilesParsedResponse, FileStatistics } from '@/types';
import { PipelineEntityConfigForm } from './PipelineEntityConfigForm';
import { usePipelineContext } from '@/components/PipelineLayout/usePipelineContext';
import { Tabs } from '@/components/Tabs/Tabs';
import { QuiBox, QuiButton, QuiFlexBoxColumn, useQuiModal } from '@tonicai/ui-quinine';
import CustomEntityForm from '../CustomEntities/CustomEntityForm';
import { FilesTable } from '../FileParseJob/FilesTable';

export function AmazonS3Pipeline() {
    const { parseJobConfigId, parseJobConfig } = usePipelineContext();
    const [fileStatistics, setFileStatistics] = useState<FileStatistics>();

    const formModal = useQuiModal();
    useEffect(() => {
        fetchParseJobConfigFileParseJobs(parseJobConfigId);
        fetchParseJobConfigFiles(parseJobConfigId);
        fetchFileStats(parseJobConfigId).then(setFileStatistics);
    }, [parseJobConfigId]);

    return (
        <QuiFlexBoxColumn gap="md">
            <Tabs.Container defaultTab="files">
                <QuiBox display="flex" alignItems="center" justifyContent="space-between">
                    <Tabs.TabTriggers>
                        <Tabs.TabTrigger data-test="pipeline-files-tab" icon="inbox" id="files">
                            Files
                        </Tabs.TabTrigger>
                        {parseJobConfig.useInternalBucket ? null : (
                            <Tabs.TabTrigger data-test="pipeline-runs-tab" icon="shield" id="runs">
                                Pipeline Runs
                            </Tabs.TabTrigger>
                        )}
                        <Tabs.TabTrigger data-test="generator-config" icon="eye" id="generator-config">
                            Generator Config
                        </Tabs.TabTrigger>
                    </Tabs.TabTriggers>

                    <QuiButton onClick={formModal.open} style={{ marginBottom: '.75em' }}>
                        Create Custom Entity Type
                    </QuiButton>
                </QuiBox>

                <Tabs.TabContent id="files">
                    <QuiBox display={'flex'} gap={'lg'}>
                        <EndpointGuard<FilesParsedResponse>
                            endpoint={`/api/parsejobconfig/${parseJobConfigId}/files/all`}
                            errorMessage="Error loading files"
                            Component={FilesTable}
                            notFoundMessage="No files found"
                        />
                        <PipelineFileStatistics statistics={fileStatistics} />
                    </QuiBox>
                </Tabs.TabContent>
                <Tabs.TabContent id="runs">
                    <ParseJobFileParseJobs />
                </Tabs.TabContent>
                <Tabs.TabContent id="generator-config">
                    <PipelineEntityConfigForm />
                </Tabs.TabContent>
            </Tabs.Container>

            <CustomEntityForm formModal={formModal} />
        </QuiFlexBoxColumn>
    );
}
