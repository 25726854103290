import React, { useEffect, useState } from 'react';
import { QuiSpinner } from '@tonicai/ui-quinine';

type ImageDimensions = {
    width: number;
    height: number;
    orientation?: number;
};

type ScalableImageProps = Readonly<{
    src: string;
    scale: number;
    onLoad: (dimensions: { width: number; height: number; orientation?: number }) => void;
}>;

function getRotationStyle(orientation?: number) {
    switch (orientation) {
        case 3:
            return 'rotate(180deg)';
        case 6:
            return 'rotate(90deg)';
        case 8:
            return 'rotate(-90deg)';
        default:
            return 'none';
    }
}

function shouldSwapDimensions(orientation?: number) {
    return orientation === 6 || orientation === 8;
}

function parseChunk(view: DataView, offset: number, imageData: ArrayBuffer) {
    const length = view.getUint32(offset);
    const type = String.fromCharCode(view.getUint8(offset + 4), view.getUint8(offset + 5), view.getUint8(offset + 6), view.getUint8(offset + 7));

    const data = new TextDecoder().decode(new Uint8Array(imageData, offset + 8, length));

    return { length, type, data };
}

function parseOrientationFromText(data: string): number | undefined {
    if (!data.includes('Orientation')) return undefined;

    const match = data.match(/Orientation[=:](\d+)/);
    return match ? parseInt(match[1], 10) : undefined;
}

function parseOrientationFromXMP(data: string): number | undefined {
    if (!data.includes('http://ns.adobe.com/tiff/1.0/')) return undefined;

    const patterns = [/<tiff:Orientation>(\d+)<\/tiff:Orientation>/, /tiff:Orientation="(\d+)"/, /Orientation>(\d+)</];

    for (const pattern of patterns) {
        const match = data.match(pattern);
        if (match) return parseInt(match[1], 10);
    }

    return undefined;
}

export function ScalableImage({ src, scale, onLoad }: ScalableImageProps) {
    const [dimensions, setDimensions] = useState<ImageDimensions | null>(null);
    const [imageData, setImageData] = useState<ArrayBuffer | null>(null);

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const response = await fetch(src);
                const buffer = await response.arrayBuffer();
                setImageData(buffer);
                setDimensions(null);
            } catch (error) {
                console.error('Failed to fetch image:', error);
            }
        };

        fetchImage();
    }, [src]);

    useEffect(() => {
        if (!imageData) return;

        const img = new Image();
        const blob = new Blob([imageData], { type: 'image/png' });
        img.src = URL.createObjectURL(blob);

        async function parseOrientation() {
            try {
                if (!imageData) return undefined;

                const view = new DataView(imageData);
                let offset = 8; // Skip PNG header

                while (offset < imageData.byteLength - 12) {
                    const { length, type, data } = parseChunk(view, offset, imageData);

                    if (type === 'oRNT') {
                        return view.getUint8(offset + 8);
                    }

                    if (type === 'tEXt') {
                        const orientation = parseOrientationFromText(data);
                        if (orientation) return orientation;
                    }

                    if (type === 'iTXt') {
                        const orientation = parseOrientationFromXMP(data);
                        if (orientation) return orientation;
                    }

                    offset += length + 12;
                }

                return undefined;
            } catch (error) {
                console.error('Error parsing orientation:', error);
                return undefined;
            }
        }

        function onLoadHandler() {
            parseOrientation().then((orientation) => {
                const dims: ImageDimensions = {
                    width: img.width,
                    height: img.height,
                    orientation,
                };

                setDimensions(dims);

                onLoad({
                    width: dims.width,
                    height: dims.height,
                    orientation,
                });
            });
        }

        img.addEventListener('load', onLoadHandler);

        return () => {
            URL.revokeObjectURL(img.src);
            img.removeEventListener('load', onLoadHandler);
        };
    }, [imageData, onLoad]);

    if (!dimensions) {
        return <QuiSpinner />;
    }

    const rotation = getRotationStyle(dimensions.orientation);
    const swapped = shouldSwapDimensions(dimensions.orientation);

    const containerStyle: React.CSSProperties = {
        width: `${(swapped ? dimensions.height : dimensions.width) * scale}px`,
        height: `${(swapped ? dimensions.width : dimensions.height) * scale}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
    };

    const imageStyle: React.CSSProperties = {
        display: 'block',
        width: `${dimensions.width * scale}px`,
        height: `${dimensions.height * scale}px`,
        transform: rotation,
        transformOrigin: 'center',
    };

    return (
        <div style={containerStyle}>
            <img alt="" src={src} style={imageStyle} />
        </div>
    );
}
