import { client } from '@/services/HTTPClient';
import { useEffect } from 'react';
import { useGetQuery } from './useGetQuery';
import usePrevious from './usePrevious';

export default function usePipelineJobCompleteEffect(parseJobConfigId: string, callback: () => void) {
    const runningJobs = useGetQuery<{ runningJobs: string[] }>(client, `/api/ParseJobConfig/${parseJobConfigId}/jobs/is_running`);
    const runningJobsCount = runningJobs.data?.runningJobs.length;
    const previousRunningJobsCount = usePrevious(runningJobsCount);
    useEffect(() => {
        if (runningJobsCount === 0 && previousRunningJobsCount === 1) {
            callback();
        }
    }, [runningJobsCount, previousRunningJobsCount, callback]);
}
