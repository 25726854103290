import { Dataset, PiiTypeEnum } from '@/types';
import { CustomEntity } from '@/types/custom-entities';
import { QuiBox } from '@tonicai/ui-quinine';
import fastDeepEqual from 'fast-deep-equal';
import { useEffect, useRef } from 'react';
import { useField, useForm } from 'react-final-form';
import { RedactionTypeOptions } from './RedactTypeOptions';

type RedactionTypesProps = Readonly<{
    keys: string[];
    dataset: Dataset;
    customEntityMap: Record<string, CustomEntity> | undefined;
}>;

export function RedactionTypes({ keys, dataset, customEntityMap }: RedactionTypesProps) {
    const form = useForm();

    // Automatically submit the form anytime a generator setup value changes
    const {
        input: { value: generatorSetupValue },
    } = useField(`generatorSetup`);
    const generatorSetupValueRef = useRef(generatorSetupValue);

    const {
        input: { value: datasetGeneratorMetadataValue },
    } = useField('datasetGeneratorMetadata');

    useEffect(() => {
        if (!fastDeepEqual(generatorSetupValueRef.current, generatorSetupValue)) {
            form.submit();
        }

        generatorSetupValueRef.current = generatorSetupValue;
    }, [generatorSetupValue, form, datasetGeneratorMetadataValue]);

    return (
        <QuiBox display="flex" flexDirection="column" gap="xs">
            {keys.map((key) => {
                const customEntity = customEntityMap?.[key];
                return <RedactionTypeOptions key={key} piiType={key as PiiTypeEnum} dataset={dataset} customEntity={customEntity} />;
            })}
        </QuiBox>
    );
}
