import {
    QuiBadge,
    QuiBox,
    QuiButton,
    QuiCell,
    QuiCheckboxField,
    QuiGridLayout,
    QuiModalContent,
    QuiModalDialog,
    QuiModalFooter,
    QuiSelect,
    QuiText,
    QuiTextAreaField,
    QuiTextField,
    useQuiModal,
    useQuiToasts,
} from '@tonicai/ui-quinine';
import { useEffect, useState } from 'react';
import { FormSpy, useForm } from 'react-final-form';
import { CustomEntityRegexList } from './CustomEntityRegexList';
import { PipelinesDatasetsTable } from '@/components/PipelinesDatasetsTable/PipelinesDatasetsTable';
import { RegexPreviewMatch, RegexPreviewResponse } from '@/types';
import { useDebounce } from '@/hooks/useDebounce';
import { client } from '@/services/HTTPClient';
import { PREVIEW_HIGHLIGHT_COLOR } from '@/constants';

type CustomEntityModalProps = Readonly<{
    modal: ReturnType<typeof useQuiModal>;
}>;
const MAX_DESCRIPTION_LENGTH = 200;

export function CustomEntityModal({ modal }: CustomEntityModalProps) {
    const form = useForm();
    const [previewText, setPreviewText] = useState<string>();
    const [isTestPreviewFetching, setIsTestPreviewFetching] = useState(false);
    const [previewResponse, setPreviewResponse] = useState<RegexPreviewResponse>();
    const addToast = useQuiToasts();

    // TODO: put this into its own component + reuse
    const fetchPreviewResults = async () => {
        try {
            setIsTestPreviewFetching(true);
            const response = await client.get('/api/dataset/preview', {
                params: {
                    text: previewText,
                    regex: form.getFieldState(isTesting!.fieldName)?.value,
                },
            });

            setPreviewResponse(response.data);
        } finally {
            setIsTestPreviewFetching(false);
        }
    };
    const [isTesting, setIsTesting] = useState<{
        fieldName: string;
        value: string;
        label: string;
    }>();
    const debouncedPreviewText = useDebounce(previewText, 500);
    const debouncedFieldText = useDebounce(isTesting?.fieldName ? form.getFieldState(isTesting.fieldName)?.value : undefined, 500);
    useEffect(() => {
        setPreviewResponse(undefined);
        const fieldText = isTesting && form.getFieldState(isTesting.fieldName)?.value;
        if (fieldText && debouncedPreviewText && isTesting && debouncedFieldText === fieldText) {
            fetchPreviewResults();
        }

        // eslint-disable-next-line
    }, [debouncedFieldText, debouncedPreviewText, isTesting]);

    const closeModal = () => {
        modal.close();
    };

    const isAlphanumeric = (value: string) => {
        return /^[a-zA-Z0-9\s]+$/.test(value) ? undefined : 'Alphanumeric characters and spaces only';
    };

    return (
        <QuiModalDialog
            disableDismissOnEscapeKeyDown={false}
            disableDismissOnOutsideClick={true}
            isOpen={modal.isOpen}
            onClose={closeModal}
            title={
                <QuiBox display="flex" gap="sm" alignItems="center">
                    <QuiBadge variant="brand-purple">(.*)</QuiBadge>
                    <QuiText size="text-lg">Create Custom Entity</QuiText>
                </QuiBox>
            }
            style={{ maxWidth: 'unset', width: '100%' }}
        >
            <QuiModalContent>
                <QuiGridLayout style={{ height: '100%' }}>
                    <QuiCell style={{ height: '100%' }} width={4}>
                        <QuiBox padding="md" borderRight="stroke-base" display="flex" flexDirection="column" gap="md" style={{ height: '100%' }}>
                            General Settings
                            <div>
                                <QuiTextField
                                    disabled={!!form.getState().initialValues.displayName}
                                    name="displayName"
                                    placeholder="Name for the custom entity"
                                    validate={isAlphanumeric}
                                    label="Custom entity name"
                                    subHelperText={'Alphanumeric characters and spaces only'}
                                />
                            </div>
                            <div>
                                <QuiTextAreaField
                                    maxLength={MAX_DESCRIPTION_LENGTH}
                                    name="description"
                                    placeholder="Describe the custom entity"
                                    label={
                                        <QuiBox display="flex" gap="xs">
                                            Description <QuiText color="text-subdued">(optional)</QuiText>
                                        </QuiBox>
                                    }
                                />

                                <FormSpy subscription={{ values: true }}>
                                    {({ values }) => {
                                        return (
                                            <>
                                                <QuiBox textAlign="right" text="text-xs" color="text-subdued">
                                                    {values.description?.length ?? '0'}/{MAX_DESCRIPTION_LENGTH}
                                                </QuiBox>

                                                <CustomEntityRegexList />
                                            </>
                                        );
                                    }}
                                </FormSpy>
                            </div>
                        </QuiBox>
                    </QuiCell>
                    <QuiCell style={{ height: '100%' }} width={4}>
                        <QuiBox borderRight="stroke-base" padding="md" display="flex" flexDirection="column" gap="md" style={{ height: '100%' }}>
                            <div>Test Entry</div>
                            <QuiBox display="flex" flexDirection="column" gap="md" style={{ flex: 3 }}>
                                <QuiBox>
                                    <FormSpy subscription={{ values: true }}>
                                        {({ values }) => {
                                            return (
                                                <QuiSelect
                                                    value={isTesting || null}
                                                    onChange={(selection) => {
                                                        if (selection) {
                                                            setIsTesting({
                                                                fieldName: `entries.regexes[${selection.value}]`,
                                                                value: selection.value,
                                                                label: selection.label,
                                                            });
                                                        }
                                                    }}
                                                    options={values.entries.regexes?.filter(Boolean).map((_: string, index: number) => {
                                                        return {
                                                            fieldName: `entries.regexes[${index}]`,
                                                            value: String(index),
                                                            label: `${index + 1}`,
                                                        };
                                                    })}
                                                />
                                            );
                                        }}
                                    </FormSpy>
                                </QuiBox>
                                <textarea
                                    className="qui-textarea"
                                    placeholder="Input sample text to test your selected value."
                                    value={previewText}
                                    onChange={({ target }) => {
                                        setPreviewText(String.raw`${target.value}`);
                                    }}
                                    style={{
                                        minHeight: '100px',
                                        padding: '.5em',
                                    }}
                                />

                                <div>Results</div>
                                <QuiBox
                                    bg="background-alt"
                                    border="stroke-base"
                                    style={{ minHeight: '100px' }}
                                    color="text-brand-black-600"
                                    padding="sm"
                                >
                                    {isTestPreviewFetching && <>Loading...</>}
                                    {!isTestPreviewFetching &&
                                        previewResponse?.matches.map((segment: RegexPreviewMatch, index: number) => {
                                            return (
                                                <span
                                                    style={{
                                                        backgroundColor: segment.highlighted ? PREVIEW_HIGHLIGHT_COLOR : undefined,
                                                    }}
                                                    // eslint-disable-next-line
                                                    key={`${segment.text}-${index}`}
                                                >
                                                    {segment.text}
                                                </span>
                                            );
                                        })}
                                </QuiBox>
                            </QuiBox>
                        </QuiBox>
                    </QuiCell>
                    <QuiCell style={{ height: '100%', overflowY: 'scroll' }} width={4}>
                        <QuiBox borderRight="stroke-base" padding="md" display="flex" flexDirection="column" gap="md" style={{ height: '100%' }}>
                            Activate Custom Entity Type
                            <QuiBox padding="sm" border="stroke-base">
                                <QuiCheckboxField
                                    name="enabledAutomatically"
                                    label={<QuiBox text="text-xs">Automatically activate for all current, and new datasets and pipelines</QuiBox>}
                                />
                            </QuiBox>
                            <QuiBox display={'flex'} flexDirection={'column'}>
                                <QuiBox display={'flex'} flexDirection={'column'}>
                                    <QuiText>Datasets</QuiText>
                                    <PipelinesDatasetsTable excludePipelines isCustomEntity={true} form={form} />
                                </QuiBox>
                                <QuiBox display={'flex'} flexDirection={'column'}>
                                    <QuiText>Pipelines</QuiText>
                                    <PipelinesDatasetsTable excludeDatasets isCustomEntity={true} form={form} />
                                </QuiBox>
                            </QuiBox>
                        </QuiBox>
                    </QuiCell>
                </QuiGridLayout>
            </QuiModalContent>
            <QuiModalFooter>
                <QuiBox display={'flex'} justifyContent={'end'} className={'qui-w-100'} gap={'sm'}>
                    <QuiButton type="button" onClick={closeModal}>
                        Cancel
                    </QuiButton>
                    <QuiButton
                        type="button"
                        onClick={() => {
                            form.change('startLightRescan', false);
                            return form
                                .submit()
                                ?.then(() => closeModal())
                                .catch(() => addToast({ title: 'Failed to submit form, please try again.', variant: 'danger' }));
                        }}
                    >
                        Save Without Scanning Files
                    </QuiButton>
                    <QuiButton
                        type="button"
                        variant="brand-purple"
                        onClick={() => {
                            form.change('startLightRescan', false);
                            return form
                                .submit()
                                ?.then(() => closeModal())
                                .catch(() => addToast({ title: 'Failed to submit form, please try again.', variant: 'danger' }));
                        }}
                    >
                        Save and Scan Files
                    </QuiButton>
                </QuiBox>
            </QuiModalFooter>
        </QuiModalDialog>
    );
}
