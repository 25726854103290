import styles from './GettingStartedPlayground.module.scss';
import { PiiTypeToLabel } from '@/pages/Dataset/utils';
import { updateRules } from './Playground/state';
import { usePlaygroundContext } from './Playground/usePlaygroundContext';
import { usePIITypes } from '@/stores';
import { PiiTypeEnum } from '@/types';
import { QuiBadge, QuiBox, QuiButton, QuiIcon, QuiPopover, QuiSelect, QuiText, QuiTextInput } from '@tonicai/ui-quinine';
import { Fragment, useState } from 'react';
import { SingleValue } from 'react-select';

type CustomizeRequestProps = {
    rules: FineTunedRule[];
};

type EntitySelectOption = {
    value: string;
    label: string;
};

type IncludeExcludeOption = {
    value: string;
    label: string;
};

type PopoverProps = {
    onClose: () => void;
    rules: FineTunedRule[];
};

export type FineTunedRule = {
    entity: PiiTypeEnum;
    isIncluded: boolean;
    regex: string;
};

type AddFineTunedRuleState = {
    entity?: EntitySelectOption;
    includeOrExclude: IncludeExcludeOption;
    regex: string;
};

type AddOrEditRuleProps = {
    onCancel: () => void;
    onSave: (rule: FineTunedRule) => void;
    ruleToEdit?: FineTunedRule;
};

function AddOrEditRule({ onCancel, onSave, ruleToEdit }: AddOrEditRuleProps) {
    const [entityBeingAdded, setEntityBeingAdded] = useState<AddFineTunedRuleState>({
        entity: ruleToEdit?.entity
            ? {
                  value: PiiTypeEnum[ruleToEdit.entity],
                  label: PiiTypeToLabel[ruleToEdit.entity].label,
              }
            : undefined,
        includeOrExclude: (ruleToEdit?.isIncluded ?? true) ? { label: 'Include', value: 'Include' } : { label: 'Excluded', value: 'Excluded' },
        regex: ruleToEdit?.regex ?? '',
    });

    const options = usePIITypes()
        .map((piiType) => {
            return {
                value: piiType,
                label: PiiTypeToLabel[PiiTypeEnum[piiType]].label,
            };
        })
        .sort((a, b) => a.label.localeCompare(b.label));

    const onSelectEntity = (entity: SingleValue<EntitySelectOption>) => {
        const newValue = { ...entityBeingAdded!, entity: entity! };
        setEntityBeingAdded(newValue);
    };

    const onIncludeExcludeChange = (includeOrExclude: SingleValue<IncludeExcludeOption>) => {
        const newValue = {
            ...entityBeingAdded!,
            includeOrExclude: includeOrExclude!,
        };
        setEntityBeingAdded(newValue);
    };

    const onRegexChange = (regex: string) => {
        const newValue = { ...entityBeingAdded!, regex };
        setEntityBeingAdded(newValue);
    };

    const handleOnKeyUpForAddRegexInput = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            saveRule();
        }
    };

    const saveRule = () => {
        const newRule = {
            entity: entityBeingAdded.entity!.value as PiiTypeEnum,
            regex: entityBeingAdded.regex,
            isIncluded: entityBeingAdded.includeOrExclude.value === 'Include',
        };
        onSave(newRule);
    };

    return (
        <QuiBox display={'flex'} alignItems={'center'} padding={'md'} gap={'xs'}>
            <QuiSelect<EntitySelectOption>
                className={styles.entityLabelSelect}
                placeholder={'Select entity type'}
                options={options}
                value={entityBeingAdded.entity}
                onChange={onSelectEntity}
            />
            <QuiSelect<IncludeExcludeOption>
                className={styles.includeExcludeSelect}
                options={[
                    { label: 'Include', value: 'Include' },
                    { label: 'Exclude', value: 'Exclude' },
                ]}
                value={entityBeingAdded.includeOrExclude}
                onChange={onIncludeExcludeChange}
            />
            <QuiTextInput
                style={{ width: 190 }}
                placeholder={'Add a regex here'}
                value={entityBeingAdded.regex}
                onKeyUp={handleOnKeyUpForAddRegexInput}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onRegexChange(e.target.value)}
            />
            <QuiButton onClick={onCancel} variant={'minimal'} size={'sm'} iconLeft={'x'} />
            <QuiButton
                disabled={entityBeingAdded.entity == null || entityBeingAdded.regex === ''}
                variant={'minimal'}
                size={'sm'}
                iconLeft={'check'}
                onClick={saveRule}
            />
        </QuiBox>
    );
}

function PopoverContent({ onClose, rules }: PopoverProps) {
    const [isAddingNewEntity, setIsAddingNewEntity] = useState<boolean>(false);
    const [isEditingEntity, setIsEditingEntity] = useState<boolean>(false);
    const [idxOfRuleBeingEdited, setIdxOfRuleBeingEdited] = useState<number>();
    const storeOptions = usePlaygroundContext();

    const onDeleteRule = (idx: number) => {
        const newRules = [...rules.slice(0, idx), ...rules.slice(idx + 1)];
        updateRules(storeOptions.store, newRules);
    };

    const onSaveNewRule = (newRule: FineTunedRule) => {
        updateRules(storeOptions.store, [...rules, newRule]);
        setIsAddingNewEntity(false);
    };

    const onSaveEditToExistingRule = (newRule: FineTunedRule) => {
        const newRules = [...rules.slice(0, idxOfRuleBeingEdited!), newRule, ...rules.slice(idxOfRuleBeingEdited! + 1)];
        updateRules(storeOptions.store, newRules);
        setIsAddingNewEntity(false);
        setIdxOfRuleBeingEdited(undefined);
    };

    const onEditRule = (idx: number) => {
        setIsAddingNewEntity(false);
        setIdxOfRuleBeingEdited(idx);
        setIsEditingEntity(true);
    };

    const onCancelEditRule = () => {
        setIsEditingEntity(false);
        setIdxOfRuleBeingEdited(undefined);
    };

    const handleClearRules = () => {
        if (rules.length === 0) return;
        updateRules(storeOptions.store, []);
    };
    return (
        <QuiBox display={'flex'} flexDirection={'column'} className={styles.fineTuneSettings} style={{ width: 615, minHeight: 415, maxHeight: 800 }}>
            <QuiBox display={'flex'} justifyContent={'space-between'} alignItems={'center'} borderBottom={'stroke-base'}>
                <QuiBox display={'flex'} gap={'md'} padding={'md'}>
                    <QuiIcon icon={'sliders'} />
                    <QuiText>Fine-Tune Results</QuiText>
                </QuiBox>
                <QuiBox display={'flex'} gap={'2xs'} padding={'xs'}>
                    <QuiButton
                        onClick={() => setIsAddingNewEntity(true)}
                        disabled={isAddingNewEntity}
                        variant={'outline-default'}
                        style={{
                            padding: '4px 6px',
                        }}
                        iconRight={'plus'}
                    >
                        Add Rule
                    </QuiButton>
                    <QuiButton
                        iconRight={'trash-2'}
                        onClick={() => handleClearRules()}
                        variant={'outline-default'}
                        style={{
                            padding: '4px 6px',
                        }}
                    >
                        Clear Rules
                    </QuiButton>
                    <QuiButton
                        onClick={onClose}
                        variant={'minimal'}
                        style={{
                            padding: '4px 6px',
                        }}
                        iconLeft={'x'}
                    />
                </QuiBox>
            </QuiBox>
            <QuiBox style={{ overflow: 'auto' }} display={'flex'} flexDirection={'column'} flexGrow={'1'}>
                <QuiBox display={'flex'} flexDirection={'column'} style={{ overflow: 'auto' }} />
                {isAddingNewEntity && <AddOrEditRule onCancel={() => setIsAddingNewEntity(false)} onSave={onSaveNewRule} />}
                {rules.map((rule: FineTunedRule, idx: number) => (
                    <Fragment key={`${rule.regex}-${rule.entity}`}>
                        {isEditingEntity && idxOfRuleBeingEdited === idx && (
                            <AddOrEditRule ruleToEdit={rule} onCancel={onCancelEditRule} onSave={onSaveEditToExistingRule} />
                        )}
                        {(!isEditingEntity || idxOfRuleBeingEdited !== idx) && (
                            <QuiBox display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{ padding: '0 16px' }} gap={'xs'}>
                                <QuiBox display={'flex'} gap={'xs'} alignItems={'center'}>
                                    <QuiText
                                        style={{
                                            width: 190,
                                            padding: '0 16px',
                                        }}
                                        ellipsisOverflow={true}
                                    >
                                        {PiiTypeToLabel[rule.entity].label}
                                    </QuiText>
                                    <QuiBox
                                        style={{
                                            width: 120,
                                            padding: '0 16px',
                                        }}
                                        display={'flex'}
                                    >
                                        <QuiIcon icon={rule.isIncluded ? 'check-circle' : 'x-circle'} />
                                        &nbsp;
                                        <QuiText>{rule.isIncluded ? 'Included' : 'Excluded'}</QuiText>
                                    </QuiBox>
                                    <QuiText
                                        style={{
                                            width: 190,
                                            padding: '0 16px',
                                        }}
                                    >
                                        {rule.regex}
                                    </QuiText>
                                </QuiBox>
                                <QuiBox style={{ marginLeft: -3 }} display={'flex'}>
                                    <QuiButton onClick={() => onDeleteRule(idx)} variant={'minimal'} iconLeft={'trash'} />
                                    <QuiButton onClick={() => onEditRule(idx)} variant={'minimal'} iconLeft={'edit-3'} />
                                </QuiBox>
                            </QuiBox>
                        )}
                    </Fragment>
                ))}
                {rules.length === 0 && !isEditingEntity && !isAddingNewEntity && (
                    <QuiBox
                        alignSelf={'center'}
                        style={{ width: '75%', marginTop: '25px' }}
                        gap={'xs'}
                        flexDirection={'column'}
                        padding={'lg'}
                        display={'flex'}
                        alignItems={'center'}
                        flexGrow={'1'}
                    >
                        <QuiIcon icon={'sliders'} color={'text-subdued'} size={36} />
                        <QuiText weight={'bold'}>No rules found</QuiText>
                        <QuiText>Rules are regex expressions that can add or exclude specific values from identification.</QuiText>
                    </QuiBox>
                )}
            </QuiBox>
        </QuiBox>
    );
}

export function CustomizeNer({ rules }: CustomizeRequestProps) {
    const [showPopover, setShowPopover] = useState<boolean>(false);

    const buttonContent =
        rules.length === 0 ? (
            'Fine-tune Results'
        ) : (
            <>
                Fine-tune Results&nbsp;
                <QuiBadge variant={'positive-filled'} size={'sm'}>
                    {rules.length}
                </QuiBadge>
            </>
        );

    return (
        <QuiPopover
            padding={'none'}
            content={<PopoverContent onClose={() => setShowPopover(false)} rules={rules} />}
            placement={'bottom-start'}
            hideArrow={true}
            isOpen={showPopover}
            onClose={() => setShowPopover(false)}
        >
            <QuiButton onClick={() => setShowPopover(true)} style={{ background: 'var(--qui-color-background-neutral)' }} iconLeft={'sliders'}>
                {buttonContent}
            </QuiButton>
        </QuiPopover>
    );
}
