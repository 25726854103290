import { FineTunedRule } from '@/components/GettingStartedPlayground/CustomizeNer';
import { EntityGeneratorTypeSelection } from '@/components/GettingStartedPlayground/Playground/EntityGeneratorTypeSelection';
import styles from '@/components/GettingStartedPlayground/Playground/PlaygroundFileEditorContainer.module.scss';
import { PiiTypeToLabel } from '@/pages/Dataset/utils';
import { PiiTypeEnum, PiiTypeGeneratorState } from '@/types';
import { useAtomValue } from 'jotai';
import { generatorSetupAtom, redactFileResponseAtom, redactFileResponseStatusAtom, updateGeneratorSetup } from './state';
import { usePlaygroundContext } from './usePlaygroundContext';
import { PreviewWrapper } from './PreviewWrapper';
import classNames from 'classnames';
import { QuiBox, QuiButton, QuiIcon, QuiPopover } from '@tonicai/ui-quinine';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

type PreviewProps = {
    rules: FineTunedRule[];
    onClear?: () => void;
    handleCreateDataset?: () => void;
};

type LabelButtonPopoverProps = {
    idx: string;
    label: PiiTypeEnum;
    val: string;
    operation: PiiTypeGeneratorState;
    onChange: (operation: PiiTypeGeneratorState) => void;
};

const styleClasses = {
    Off: styles.previewRedactionInOffState,
    Redaction: styles.previewRedaction,
    Synthesis: styles.previewSynthesis,
};

function LabelButtonPopover({ idx, label, val, operation, onChange }: LabelButtonPopoverProps) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <QuiPopover
            keepChildFocusOnOpen={false}
            key={idx}
            padding={'sm'}
            isOpen={isOpen}
            content={
                <EntityGeneratorTypeSelection
                    currentValue={operation}
                    setGeneratorSetup={onChange}
                    label_description={PiiTypeToLabel[label].description}
                />
            }
            variant="default"
            onClose={() => setIsOpen(false)}
        >
            <QuiButton
                onClick={() => setIsOpen(true)}
                variant={'minimal'}
                key={idx}
                className={classNames(styleClasses[operation], styles.labelButton, 'fs-mask')}
            >
                {operation !== 'Off' && (
                    <>
                        <QuiBox>
                            <QuiIcon size={'sm'} icon={operation === 'Synthesis' ? 'shuffle' : 'eye-off'} />
                        </QuiBox>
                        &nbsp;
                    </>
                )}
                {val}
            </QuiButton>
        </QuiPopover>
    );
}

export function PlaygroundFilePreviewContainer({ rules, onClear, handleCreateDataset }: PreviewProps) {
    const storeOptions = usePlaygroundContext();

    const responseStatus = useAtomValue(redactFileResponseStatusAtom, storeOptions);
    const data = useAtomValue(redactFileResponseAtom, storeOptions);
    const generatorSetup = useAtomValue(generatorSetupAtom, storeOptions);

    const [labels, setLabels] = useState<Element[]>([]);
    useEffect(() => {
        if (data == null) return;
        const currentLabels = document.querySelectorAll('span.textual_interactive_label');
        currentLabels.forEach((label: Element) => {
            label.innerHTML = '';
        });
        setLabels(Array.from(currentLabels));
    }, [data]);

    const handleGeneratorsetupChange = (entityLabel: PiiTypeEnum, operation: PiiTypeGeneratorState) => {
        if (entityLabel == null) return;
        const newGeneratorSetup = new Map(generatorSetup);
        newGeneratorSetup.set(entityLabel as PiiTypeEnum, operation);
        updateGeneratorSetup(storeOptions.store, newGeneratorSetup);
    };

    //The data-idx values returned from server are ever-increasing.  The first request could return indexes 0 to 10.  But the next request will then start at index 11.
    const startingIdx = labels.length > 0 ? Math.min(...labels.map((l) => parseInt(l.getAttribute('data-idx') ?? '0', 10))) : 0;

    return (
        <PreviewWrapper
            onClear={onClear}
            previewData={data?.redactedData}
            responseStatus={responseStatus}
            rules={rules}
            hideCustomizeNerResults={true}
            handleCreateDataset={handleCreateDataset}
        >
            <QuiBox className={classNames(styles.playgroundFileContainer, styles.ignorePadding, 'fs-mask')}>
                <div style={{ height: '100%' }} dangerouslySetInnerHTML={{ __html: data?.redactedData ?? '' }} />
            </QuiBox>
            {Array.from(labels).map((label: Element) => {
                const idx = label.getAttribute('data-idx');
                if (idx != null) {
                    //To support CSV we render in a table.  The first two indexes of data.deIdentifyResults are the row and column indexes.  For a text file the row/column are both 0.
                    //To find the row/column we are in we look at the data-row and data-col attributes of <td> parent of the span
                    const tdElement = label.closest('td');
                    const rowIdx = tdElement?.getAttribute('data-row');
                    const colIdx = tdElement?.getAttribute('data-column');

                    if (rowIdx == null || colIdx == null) return undefined;

                    const result = data?.deIdentifyResults[parseInt(rowIdx, 10)][parseInt(colIdx, 10)][parseInt(idx, 10) - startingIdx];
                    const piiType = result!.label as PiiTypeEnum;
                    const operation = generatorSetup.get(piiType) || 'Redaction';
                    const val = operation === 'Redaction' ? piiType : operation === 'Synthesis' ? result?.syntheticNewValue : result?.text;
                    return createPortal(
                        <LabelButtonPopover
                            idx={idx}
                            val={val ?? ''}
                            label={piiType}
                            operation={operation}
                            onChange={(operation) => handleGeneratorsetupChange(piiType, operation)}
                        />,
                        label,
                        rowIdx + '-' + colIdx + '-' + idx
                    );
                }
                return undefined;
            })}
        </PreviewWrapper>
    );
}
