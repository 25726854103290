import { fetchUserApiKeys, revokeApiKey, useUserAPIKeys } from '@/stores';
import { UserApiKey } from '@/types';
import { copyToClipboard, formatDateTime } from '@/utils';
import { UserAPIKeyForm } from './UserAPIKeyForm';
import {
    QuiBox,
    QuiIconEnum,
    QuiModalContent,
    QuiModalDialog,
    QuiText,
    QuiIcon,
    QuiButton,
    QuiButtonLink,
    useQuiModal,
    QuiSpinner,
} from '@tonicai/ui-quinine';
import { ReactNode, useEffect, useState } from 'react';
import { instrumentation } from '@/instrumentation/instrumentation';

type RevokeButtonProps = {
    id: string;
};
function RevokeButton({ id }: RevokeButtonProps) {
    const [confirming, setConfirming] = useState(false);
    const text = confirming ? 'Confirm' : 'Revoke';
    const variant = confirming ? 'danger' : 'outline-danger';

    const handleRevoke = () => {
        if (confirming) {
            revokeApiKey(id).then(() => {
                instrumentation.revokeAPIKey(id);
            });
        } else {
            setConfirming(true);
        }
    };

    return (
        <QuiButton variant={variant} onClick={handleRevoke}>
            {text}
        </QuiButton>
    );
}

function Key({ apiKey, key }: { apiKey: UserApiKey; key: string }) {
    return (
        <QuiBox display="flex" border="stroke-base" borderRadius="md" padding="sm" bg="background-base" gap="md" key={key}>
            <QuiBox flexGrow="1" flexDirection="column" display="flex">
                <QuiText weight="bold">{apiKey.name}</QuiText>
                <QuiText color="text-subdued">{`Last 4 of Key: ${apiKey.key.substring(apiKey.key.length - 4, apiKey.key.length)}`}</QuiText>
                <QuiText color="text-subdued">{`Created At: ${apiKey.createdDate ? formatDateTime(new Date(apiKey.createdDate)) : '--'}`}</QuiText>
                <QuiText color="text-subdued">{`Last Used: ${apiKey.lastUsed ? formatDateTime(new Date(apiKey.lastUsed)) : 'Never used'}`}</QuiText>
            </QuiBox>
            <RevokeButton id={apiKey.id} />
        </QuiBox>
    );
}

function NewKey({ apiKey }: { apiKey: UserApiKey }) {
    return (
        <QuiBox display={'flex'} flexDirection={'column'} gap={'sm'}>
            <QuiText>{`API Key ${apiKey.name} created`}</QuiText>
            <QuiBox display={'flex'} gap={'sm'} justifyContent={'space-between'}>
                <QuiBox
                    style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
                    padding={'md'}
                    borderRadius={'sm'}
                    border={'stroke-base'}
                    bg={'surface-neutral'}
                >
                    <QuiText>{apiKey.key}</QuiText>
                </QuiBox>
                <QuiButton
                    onClick={() => {
                        copyToClipboard(apiKey.key);
                    }}
                    iconLeft="clipboard"
                    variant={'outline-default'}
                />
            </QuiBox>
            <QuiText color={'text-subdued'}>This is the only time you'll be able to see this key, so keep a copy of it somewhere safe.</QuiText>
        </QuiBox>
    );
}

type ApiKeyModalProps = {
    children: ReactNode;
};

export function ApiKeyModal({ children }: ApiKeyModalProps) {
    const [isCreatingNewApiKey, setIsCreatingNewApiKey] = useState(false);
    const [newKey, setNewKey] = useState<UserApiKey | null>(null);

    const { fetchStatus, userAPIKeys } = useUserAPIKeys();

    const modal = useQuiModal();

    useEffect(() => {
        fetchUserApiKeys();
    }, []);

    useEffect(() => {
        if (modal.isOpen) {
            setIsCreatingNewApiKey(false);
            setNewKey(null);
            fetchUserApiKeys();
        }
    }, [modal.isOpen]);

    const handleNewKeyCreated = (key: UserApiKey) => {
        setNewKey(key);
        setIsCreatingNewApiKey(false);
    };

    return (
        <>
            <div onClick={modal.open}>{children}</div>

            <QuiModalDialog disableDismissOnEscapeKeyDown={false} isOpen={modal.isOpen} onClose={modal.close}>
                <QuiModalContent style={{ width: 500 }}>
                    <QuiBox display={'flex'} flexDirection={'column'} gap={'lg'}>
                        <QuiBox display={'flex'} flexDirection={'column'} gap={'sm'}>
                            <QuiBox display={'flex'} gap={'sm'} alignItems={'center'}>
                                <QuiBox padding={'sm'} borderRadius={'sm'} bg={'surface-neutral'}>
                                    <QuiIcon size={'md'} icon={'key'} />
                                </QuiBox>
                                <QuiText size={'text-md'}>API Keys</QuiText>
                            </QuiBox>
                            <QuiText color={'text-subdued'}>Create and manage your API keys.</QuiText>
                        </QuiBox>
                        <QuiBox display={'flex'} flexDirection={'column'} gap={'lg'} style={{ maxHeight: 600, overflow: 'auto' }}>
                            {(fetchStatus === 'success' || fetchStatus === 'refreshing') && newKey && <NewKey apiKey={newKey} />}
                            {(fetchStatus === 'success' || fetchStatus === 'refreshing') &&
                                userAPIKeys
                                    .filter((a) => newKey == null || newKey.id != a.id)
                                    .sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime())
                                    .map((key) => <Key key={key.id} apiKey={key} />)}
                            {fetchStatus === 'loading' && (
                                <QuiBox padding={'xl'} display={'flex'} justifyContent={'center'}>
                                    <QuiSpinner />
                                </QuiBox>
                            )}
                            {fetchStatus === 'error' && <QuiText color={'text-danger'}>Error fetching API keys</QuiText>}
                        </QuiBox>
                        {isCreatingNewApiKey && <UserAPIKeyForm setNewKey={handleNewKeyCreated} handleCancel={() => setIsCreatingNewApiKey(false)} />}
                        {!isCreatingNewApiKey && (
                            <QuiBox display={'flex'} gap={'lg'} alignItems={'center'}>
                                <QuiButton variant={'primary'} onClick={() => setIsCreatingNewApiKey(true)}>
                                    Create API Key
                                </QuiButton>
                                <QuiButtonLink
                                    target="_blank"
                                    rel="noreferrer"
                                    to="https://docs.tonic.ai/textual"
                                    iconLeft={QuiIconEnum.ExternalLink}
                                >
                                    View Docs
                                </QuiButtonLink>
                            </QuiBox>
                        )}
                    </QuiBox>
                </QuiModalContent>
            </QuiModalDialog>
        </>
    );
}
