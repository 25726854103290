import { PageTitle } from '@/components/PageTitle/PageTitle';
import { ParseJobFileParseJobs } from '@/components/ParseJobFileParseJobs/ParseJobFileParseJobs';
import { Tabs } from '@/components/Tabs/Tabs';
import { PipelineEntityConfigForm } from '@/pages/ParseJob/PipelineEntityConfigForm';
import { QuiBox, QuiButton, QuiFlexBoxColumn, useQuiModal } from '@tonicai/ui-quinine';
import CustomEntityForm from '../CustomEntities/CustomEntityForm';
import { FilesTableLocal } from '../FileParseJob/FilesTableLocal';
import { useEffect, useState } from 'react';
import { FileStatistics } from '@/types';
import { fetchFileStats } from '@/stores';
import { usePipelineContext } from '@/components/PipelineLayout/usePipelineContext';
import { PipelineFileStatistics } from '@/components/PipelineFileStatistics/PipelineFileStatistics';

export function FileUploadPipeline() {
    const { parseJobConfig } = usePipelineContext();
    const [fileStatistics, setFileStatistics] = useState<FileStatistics>();
    useEffect(() => {
        fetchFileStats(parseJobConfig.id).then(setFileStatistics);
    }, [parseJobConfig.id]);
    const formModal = useQuiModal();
    return (
        <QuiFlexBoxColumn gap="md">
            <PageTitle icon="list" title="Overview" />
            <Tabs.Container defaultTab="files">
                <QuiBox display="flex" alignItems="center" justifyContent="space-between">
                    <Tabs.TabTriggers>
                        <Tabs.TabTrigger data-test="pipeline-files-tab" icon="inbox" id="files">
                            Files
                        </Tabs.TabTrigger>
                        <Tabs.TabTrigger data-test="generator-config" icon="eye" id="generator-config">
                            Generator Config
                        </Tabs.TabTrigger>
                    </Tabs.TabTriggers>

                    <QuiButton onClick={formModal.open} style={{ marginBottom: '.75em' }}>
                        Create Custom Entity Type
                    </QuiButton>
                </QuiBox>

                <Tabs.TabContent id="files">
                    <QuiBox display={'flex'} gap={'lg'}>
                        <FilesTableLocal />
                        <PipelineFileStatistics statistics={fileStatistics} />
                    </QuiBox>
                </Tabs.TabContent>
                <Tabs.TabContent id="runs">
                    <ParseJobFileParseJobs />
                </Tabs.TabContent>
                <Tabs.TabContent id="generator-config">
                    <PipelineEntityConfigForm />
                </Tabs.TabContent>
            </Tabs.Container>
            <CustomEntityForm formModal={formModal} />
        </QuiFlexBoxColumn>
    );
}
