import { ApiKeyModal } from '@/components/ApiKeyModal/ApiKeyModal';
import { useUserAPIKeys } from '@/stores';
import { copyToClipboard } from '@/utils';
import {
    QuiBadge,
    QuiBox,
    QuiButton,
    QuiButtonLink,
    QuiIcon,
    QuiIconEnum,
    QuiNavButton,
    QuiPopover,
    QuiText,
    useDismissableQuiToasts,
    useQuiModal,
} from '@tonicai/ui-quinine';
import { ReactNode, useEffect, useRef, useState } from 'react';
import styles from './GettingStartedChecklist.module.scss';
import useChecklistState, { checklistSteps } from './useChecklistState';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { useWindowSize } from 'usehooks-ts';
import Confetti from 'react-confetti';
import { Pulsar } from '../Pulsar/Pulsar';
import { useNavigate } from 'react-router-dom';
import { instrumentation } from '@/instrumentation/instrumentation';

const INSTALL_TEXT = 'pip install tonic-textual';

function ChecklistButton({ children, onClick, disabled }: { children: ReactNode; onClick: () => void; disabled?: boolean }) {
    return (
        <button
            type="button"
            disabled={disabled}
            style={{
                border: 0,
                backgroundColor: 'unset',
                padding: 0,
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
            }}
            onClick={onClick}
        >
            {children}
        </button>
    );
}

export default function GettingStartedChecklist() {
    const modal = useQuiModal();
    const checklistState = useChecklistState();
    const { addToast, dismissToast } = useDismissableQuiToasts();
    const toastRef = useRef<string>();
    const { width, height } = useWindowSize();
    const completedItemCount = [
        checklistState.testPlayground,
        checklistState.installSDK,
        checklistState.generateAPIKey,
        checklistState.testSDK,
    ].filter(Boolean).length;

    const navigate = useNavigate();
    const [clickedOnce, setClickedOnce] = useState(false);

    const { userAPIKeys } = useUserAPIKeys();

    //As soon as the first API key is generated, set the generateAPIKey state to true
    //It will remain true even if API key is deleted later
    useEffect(() => {
        if (checklistState.generateAPIKey) return;

        if (userAPIKeys.length > 0) {
            checklistState.setGenerateAPIKey(true);
            instrumentation.gettingStartedChecklist(checklistSteps.generateAPIKey);
        }
    }, [userAPIKeys, checklistState]);

    const showConfetti =
        checklistState.testPlayground &&
        checklistState.installSDK &&
        checklistState.generateAPIKey &&
        checklistState.testSDK &&
        !checklistState.confettiShown;

    const handleClick = () => {
        modal.open();
        setClickedOnce(true);
        navigate('/');
    };

    return (
        <>
            {showConfetti && (
                <Confetti
                    recycle={false}
                    numberOfPieces={1000}
                    width={width}
                    height={height}
                    onConfettiComplete={() => checklistState.setConfettiShown(true)}
                />
            )}
            <QuiPopover
                isOpen={modal.isOpen}
                onClose={() => {
                    modal.close();
                }}
                content={
                    <QuiBox flexGrow="1" display="flex" flexDirection="column" gap="md" style={{ width: '400px', maxWidth: '400px' }}>
                        <QuiBox display="flex" flexDirection="column" gap="xs">
                            <QuiBox text="text-md" weight="medium" display="flex" gap="md" alignItems="center">
                                <QuiBadge iconLeft={QuiIconEnum.LifeBuoy} variant="brand-purple" size="sm" />
                                Getting Started Checklist
                            </QuiBox>

                            <QuiBox color="text-subdued">Use this checklist to guide you through Tonic Textual</QuiBox>
                        </QuiBox>

                        <ProgressBar value={completedItemCount} max={4} />
                        <QuiBox text="text-xs" color="text-subdued">
                            Complete the tasks below
                        </QuiBox>

                        <QuiBox display="flex" gap="md" padding="md none" borderBottom="stroke-base">
                            <QuiButtonLink
                                target="_blank"
                                rel="noreferrer"
                                to="https://docs.tonic.ai/textual"
                                size="sm"
                                iconLeft={QuiIconEnum.ExternalLink}
                            >
                                View Docs
                            </QuiButtonLink>
                        </QuiBox>

                        <QuiBox display="flex" gap="sm">
                            {!checklistState.testPlayground && <QuiBox className={styles.circleNumber}>1</QuiBox>}
                            {!!checklistState.testPlayground && (
                                <QuiBox className={styles.circleCheck} bg="surface-positive-filled">
                                    <QuiIcon color="text-filled" size="xs" icon={QuiIconEnum.Check} />
                                </QuiBox>
                            )}
                            <ChecklistButton
                                onClick={() => {
                                    checklistState.setActiveStep(checklistSteps.testPlayground);
                                    modal.close();
                                }}
                                disabled={checklistState.testPlayground}
                            >
                                <QuiText {...(!!checklistState.testPlayground && { style: { textDecoration: 'line-through' } })}>
                                    Test sensitive value detection & synthesis
                                </QuiText>
                                <QuiIcon icon={QuiIconEnum.ChevronRight} />
                            </ChecklistButton>
                        </QuiBox>

                        <QuiBox display="flex" justifyContent="space-between">
                            <QuiBox display="flex" gap="sm">
                                {!checklistState.installSDK && <QuiBox className={styles.circleNumber}>2</QuiBox>}
                                {!!checklistState.installSDK && (
                                    <QuiBox className={styles.circleCheck} bg="surface-positive-filled">
                                        <QuiIcon color="text-filled" size="xs" icon={QuiIconEnum.Check} />
                                    </QuiBox>
                                )}

                                <QuiBox display="flex" gap="sm" flexDirection="column">
                                    <QuiText
                                        color={checklistState.installSDK ? 'text-base--disabled' : undefined}
                                        {...(checklistState.installSDK && { style: { textDecoration: 'line-through' } })}
                                    >
                                        Install the SDK
                                    </QuiText>
                                    <QuiBox display="flex" justifyContent="space-between">
                                        <QuiText
                                            as="code"
                                            monospace
                                            size="text-sm"
                                            style={{
                                                display: 'block',
                                            }}
                                            color={checklistState.installSDK ? 'text-base--disabled' : undefined}
                                            {...(checklistState.installSDK && { style: { textDecoration: 'line-through' } })}
                                        >
                                            {INSTALL_TEXT}
                                        </QuiText>
                                    </QuiBox>
                                </QuiBox>
                            </QuiBox>

                            <QuiButton
                                onClick={() => {
                                    if (toastRef.current) dismissToast(toastRef.current);
                                    copyToClipboard(INSTALL_TEXT);
                                    checklistState.setInstallSDK(true);
                                    instrumentation.gettingStartedChecklist(checklistSteps.installSDK);

                                    toastRef.current = addToast({
                                        title: 'Copied install command',
                                        variant: 'positive',
                                    });
                                }}
                                type="button"
                                iconLeft="clipboard"
                                size="sm"
                            />
                        </QuiBox>

                        <QuiBox display="flex" justifyContent="space-between" alignItems="center">
                            <QuiBox display="flex" gap="sm" style={{ overflow: 'hidden', width: '100%' }}>
                                <div>
                                    {!checklistState.generateAPIKey && <QuiBox className={styles.circleNumber}>3</QuiBox>}
                                    {!!checklistState.generateAPIKey && (
                                        <QuiBox className={styles.circleCheck} bg="surface-positive-filled">
                                            <QuiIcon color="text-filled" size="xs" icon={QuiIconEnum.Check} />
                                        </QuiBox>
                                    )}
                                </div>

                                <QuiBox display="flex" gap="sm" flexDirection="column" style={{ overflow: 'hidden', width: '100%' }}>
                                    <ApiKeyModal>
                                        <ChecklistButton
                                            onClick={() => {
                                                /*do nothing*/
                                            }}
                                            disabled={checklistState.generateAPIKey}
                                        >
                                            <QuiText {...(!!checklistState.generateAPIKey && { style: { textDecoration: 'line-through' } })}>
                                                Set up your API access
                                            </QuiText>
                                            <QuiIcon icon={QuiIconEnum.ChevronRight} />
                                        </ChecklistButton>
                                    </ApiKeyModal>
                                </QuiBox>
                            </QuiBox>
                        </QuiBox>

                        <QuiBox display="flex" gap="sm">
                            {!checklistState.testSDK && <QuiBox className={styles.circleNumber}>4</QuiBox>}
                            {!!checklistState.testSDK && (
                                <QuiBox className={styles.circleCheck} bg="surface-positive-filled">
                                    <QuiIcon color="text-filled" size="xs" icon={QuiIconEnum.Check} />
                                </QuiBox>
                            )}

                            <ChecklistButton
                                onClick={() => {
                                    checklistState.setActiveStep(checklistSteps.testSDK);
                                    modal.close();
                                }}
                                disabled={checklistState.testSDK}
                            >
                                <QuiText {...(!!checklistState.testSDK && { style: { textDecoration: 'line-through' } })}>
                                    Issue a redaction call from the SDK or API
                                </QuiText>
                                <QuiIcon icon={QuiIconEnum.ChevronRight} />
                            </ChecklistButton>
                        </QuiBox>
                    </QuiBox>
                }
            >
                <Pulsar hide={clickedOnce}>
                    <QuiNavButton
                        className={'qui-inset-none-sm'}
                        onClick={handleClick}
                        iconLeft={QuiIconEnum.LifeBuoy}
                        style={{ width: 'fit-content' }}
                    >
                        Getting Started
                    </QuiNavButton>
                </Pulsar>
            </QuiPopover>
        </>
    );
}
