import { UploadFilesToExternalSourceModal } from '@/pages/Dataset/UploadFilesToExternalSourceModal';
import { client } from '@/services/HTTPClient';
import { DatasetFile, JobStatus } from '@/types';
import { QuiButton, useQuiModal } from '@tonicai/ui-quinine';
import { getDownloadFileDataUrl } from '@/stores/datasets';
import { useCallback } from 'react';

type FileDownloadButtonProps = Readonly<{
    file: DatasetFile;
    jobStatus: JobStatus;
}>;

export function FileDownloadButton({ file, jobStatus }: FileDownloadButtonProps) {
    const externalUploadDialog = useQuiModal();

    const url = getDownloadFileDataUrl(file.fileId, file.datasetId);

    const handleFileDownload = useCallback(() => {
        if (file.fileSource === 'Local') {
            window.open(url);
        } else {
            externalUploadDialog.open();
        }
    }, [externalUploadDialog, url, file.fileSource]);

    if (jobStatus !== JobStatus.COMPLETED) {
        return null;
    }

    return (
        <>
            <QuiButton type="button" size="sm" iconLeft={file.fileSource == 'Local' ? 'download' : 'upload-cloud'} onClick={handleFileDownload}>
                {file.fileSource == 'Local' ? 'Download File' : `Upload to ${file.fileSource}`}
            </QuiButton>

            {file.fileSource !== 'Local' && externalUploadDialog.isOpen && (
                <UploadFilesToExternalSourceModal
                    onClick={async () => client.get(url)}
                    fileSource={file.fileSource}
                    specificFile={file}
                    isOpen
                    onClose={externalUploadDialog.close}
                />
            )}
        </>
    );
}
