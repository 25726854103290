import { EditorPlaceholder } from '@/components/GettingStartedPlayground/Playground/EditorPlaceholder';
import { createDataset, datasetsAtom, fetchDatasets, uploadFiles } from '@/stores';
import { QuiBox, QuiButton, QuiIcon, QuiText, useQuiToasts } from '@tonicai/ui-quinine';
import { useAtomValue } from 'jotai';
import { AllSelection } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { useCallback, useEffect, useRef } from 'react';
import { Preview } from './Preview';
import { createProsemirrorEditor, schema } from './prosemirror';
import {
    blockTextContentAtom,
    blockTextOffsetAtom,
    clearDeidResponse,
    deidResponseAtom,
    responseStaleOrMissingAtom,
    rulesAtom,
    updateBlockTextContent,
    updateRules,
    fileAtom,
    clearFileUploadState,
    redactFileResponseStatusAtom,
} from './state';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { usePlaygroundContext } from './usePlaygroundContext';
import { PlaygroundFileEditorContainer } from './PlaygroundFileEditorContainer';
import { PlaygroundFilePreviewContainer } from './PlaygroundFilePreviewContainer';
import useChecklistState, { checklistSteps } from '@/components/GettingStartedChecklist/useChecklistState';
import { instrumentation } from '@/instrumentation/instrumentation';
import { useNavigate } from 'react-router-dom';

type EditorProps = {
    onContentChange?: (content: string) => void;
    extraAppClassName?: string;
    extraContainerClassName?: string;
    spellCheck?: boolean;
    onClear?: () => void;
};

export function Editor({ onContentChange, extraAppClassName, extraContainerClassName, spellCheck, onClear }: EditorProps) {
    const storeOptions = usePlaygroundContext();

    // Atom values
    const deidResponse = useAtomValue(deidResponseAtom, storeOptions);
    const blockTextOffset = useAtomValue(blockTextOffsetAtom, storeOptions);
    const rules = useAtomValue(rulesAtom, storeOptions);
    const blockTextContent = useAtomValue(blockTextContentAtom, storeOptions);
    const responseStaleOrMissing = useAtomValue(responseStaleOrMissingAtom, storeOptions);
    const selectedFile = useAtomValue(fileAtom, storeOptions);
    const redactFileResponseStatus = useAtomValue(redactFileResponseStatusAtom, storeOptions);
    const checklistState = useChecklistState();
    const navigate = useNavigate();
    const addToast = useQuiToasts();
    const datasets = useAtomValue(datasetsAtom);

    // Refs for interacting with dom outside of React lifecycle
    const containerRef = useRef<HTMLDivElement | null>(null);
    const editorViewRef = useRef<EditorView | null>(null);

    const editorStateRef = useRef(createProsemirrorEditor());
    const isEditorVisible = selectedFile == null;

    useEffect(() => {
        if (!containerRef.current) return;

        editorViewRef.current = new EditorView(containerRef.current, {
            state: editorStateRef.current,
            dispatchTransaction(tr) {
                const isPasteEvent = tr.getMeta('paste') === true;

                const editorView = editorViewRef.current;

                if (!editorView) return;

                editorView.updateState(editorView.state.apply(tr));

                const blockTextContent: string[] = [];
                editorView.state.doc.descendants((node) => {
                    if (node.type.name !== 'paragraph') return true;
                    blockTextContent.push(node.textContent);
                    return false;
                });
                updateBlockTextContent(storeOptions.store, blockTextContent, isPasteEvent);
            },
        });

        return () => {
            editorViewRef.current?.destroy();
            editorViewRef.current = null;
        };
    }, [storeOptions.store, isEditorVisible]);

    useEffect(() => {
        if (!editorViewRef.current) return;

        const transaction = editorViewRef.current.state.tr;

        if (transaction.doc.content.size > 0) {
            transaction.removeMark(0, transaction.doc.content.size, schema.mark('redaction'));
        }

        transaction.doc.descendants((node, position, _parent, index) => {
            if (node.type.name === 'paragraph' && node.isTextblock) {
                const blockOffset = blockTextOffset[index] ?? 0;
                const textLength = node.textContent.length;

                const results = deidResponse?.deIdentifyResults ?? [];

                results.forEach(({ start, end, label }) => {
                    if (start >= blockOffset && end <= textLength + blockOffset) {
                        transaction.addMark(
                            start + position - blockOffset + 1,
                            end + position - blockOffset + 1,
                            schema.mark('redaction', {
                                'data-label': label,
                            })
                        );
                    }
                });
                return false;
            }
            return true;
        });

        if (onContentChange != null) {
            const textContent: string[] = [];
            transaction.doc.descendants((node) => {
                if (node.type.name === 'paragraph' && node.isTextblock) {
                    textContent.push(node.textContent);
                }
            });

            if (textContent[0] !== '') {
                checklistState.setTestPlayground(true);
                instrumentation.gettingStartedChecklist(checklistSteps.testPlayground);
            }
            onContentChange(textContent.join('\\n'));
        }

        return editorViewRef.current.dispatch(transaction);
    }, [deidResponse, blockTextOffset, checklistState, onContentChange]);

    const clearEditor = useCallback(() => {
        if (editorViewRef.current) {
            clearDeidResponse(storeOptions.store);

            const tr = editorViewRef.current.state.tr;
            tr?.setSelection(new AllSelection(editorViewRef.current.state.doc));
            tr?.deleteSelection();
            editorViewRef.current.dispatch(tr);

            editorViewRef.current?.focus();
            updateRules(storeOptions.store, []);
            onClear?.();
        }
        clearFileUploadState(storeOptions.store);
    }, [storeOptions.store, onClear]);

    const editorClassName = classNames(styles.editor, {
        [styles.staleMarks]: responseStaleOrMissing,
        [styles.freshMarks]: !responseStaleOrMissing,
    });

    const leftSideTitle = selectedFile?.name ?? 'Original Content';

    const handleCreateDataset = async () => {
        await fetchDatasets();

        if (selectedFile == null) return;

        const re = new RegExp(`^Playground Dataset ([0-9]+)$`);

        const numbers = datasets
            .filter((dataset) => re.test(dataset.name))
            .map((dataset) => re.exec(dataset.name)?.[1])
            .map((match) => parseInt(match ?? '0'));
        const maxNumber = Math.max(0, ...numbers);

        const id = await createDataset(`Playground Dataset ${maxNumber + 1}`);

        if (id === '' || id == null) {
            addToast({
                title: 'Could not create dataset.  Please create one manually.',
                variant: 'danger',
                action: { onClick: () => navigate('datasets'), text: 'Go to Datasets' },
            });
        }

        try {
            await uploadFiles([selectedFile], id);
            navigate(`/datasets/${id}`);
        } catch {
            addToast({
                title: 'Could not create dataset.  Please create one manually.',
                variant: 'danger',
                action: { onClick: () => navigate('datasets'), text: 'Go to Datasets' },
            });
        }
    };

    return (
        <QuiBox className={extraContainerClassName} display="flex" flexDirection="column" gap="sm">
            <div className={classNames(styles.app, extraAppClassName)}>
                {(selectedFile == null || redactFileResponseStatus !== 'success') && (
                    <>
                        <QuiBox display={'flex'} flexDirection={'column'}>
                            <QuiBox className={styles.editorColumnTopBar} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <QuiBox display={'flex'} gap={'sm'}>
                                    <QuiIcon icon={'file-text'} />
                                    <QuiText>{leftSideTitle}</QuiText>
                                </QuiBox>
                                <QuiButton iconRight={'trash-2'} onClick={clearEditor}>
                                    Clear
                                </QuiButton>
                            </QuiBox>
                            <div className={styles.editorColumn}>
                                <div className={styles.editorBackdrop} />
                                {blockTextContent.join('').length === 0 && selectedFile == null && (
                                    <EditorPlaceholder editorViewRef={editorViewRef} />
                                )}
                                {isEditorVisible && (
                                    <div spellCheck={spellCheck} className={classNames(editorClassName, 'fs-mask')} ref={containerRef} />
                                )}
                                {selectedFile != null && <PlaygroundFileEditorContainer />}
                            </div>
                        </QuiBox>
                        <Preview rules={rules} />
                    </>
                )}
                {selectedFile != null && redactFileResponseStatus === 'success' && (
                    <PlaygroundFilePreviewContainer handleCreateDataset={handleCreateDataset} onClear={clearEditor} rules={rules} />
                )}
            </div>
        </QuiBox>
    );
}
