import { CustomEntity } from '@/types/custom-entities';
import { useGetQuery } from '@/hooks/useGetQuery';
import { client } from '@/services/HTTPClient';
import {
    QuiBox,
    QuiButton,
    QuiFlexBoxColumn,
    QuiIcon,
    QuiIconEnum,
    QuiLink,
    QuiMenu,
    QuiMenuButton,
    QuiMenuItem,
    QuiMenuItems,
    QuiTextInput,
    useQuiModal,
} from '@tonicai/ui-quinine';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { ENTITIES_ENDPOINT } from '../CustomEntities/CustomEntities';
import CustomEntityForm from '../CustomEntities/CustomEntityForm';
import CustomEntityTypes from './CustomEntityTypes';
import { StartChatButton } from '@/components/StartChatButton/StartChatButton';
import { Dataset, DatasetFormState, DatasetPiiInfo, deprecatedEntities, PiiTypeEnum, PiiTypeGeneratorState } from '@/types';
import './GeneratorSetup.scss';
import { RedactionTypes } from './RedactionTypes';
import UndetectedEntityTypes from './UndetectedEntityTypes';
import { aggregatePiiTypeCounts, getPercentRedacted, getPiiTypeInfo, getTotalRedactionsWithUnits, sortPiiTypeByLabel } from './utils';

type DatasetSettingsProps = Readonly<{
    dataset: Dataset;
}>;

export function GeneratorSetup({ dataset }: DatasetSettingsProps) {
    const form = useForm<DatasetFormState>();
    const { submitting } = useFormState({ subscription: { submitting: true } });
    const keys = [...new Set([...dataset.files.flatMap((f) => f.piiTypes)])].sort();
    const typeOrTypes = keys.length === 1 ? 'Type' : 'Types';
    const [keyFilterInput, setKeyFilterInput] = useState<string>();
    const customEntities = useGetQuery<CustomEntity[]>(client, ENTITIES_ENDPOINT);
    const { data: datasetPiiInfo } = useGetQuery<DatasetPiiInfo>(client, `/api/dataset/${dataset.id}/pii_info`);

    const detectedEntities = useMemo(() => {
        if (!datasetPiiInfo) return [];
        const entities = new Set();
        const piiTypes = Object.keys(Object.values(datasetPiiInfo.filePiiInfo).map((e) => e.piiTypeCounts));
        for (const piiType of piiTypes) {
            entities.add(piiType);
        }
        return [...entities];
    }, [datasetPiiInfo]);

    const undetectedEntities = useMemo(() => {
        const allEntities = Object.keys(dataset.datasetGeneratorMetadata);

        return allEntities
            .filter((entity) => !detectedEntities.includes(entity) && !deprecatedEntities.includes(entity))
            .map((entity) => getPiiTypeInfo(entity as PiiTypeEnum))
            .sort((a, b) => a.value.localeCompare(b.value));
    }, [detectedEntities, dataset]);
    const customEntityMap = customEntities.data?.reduce(
        (acc, entity) => {
            acc[entity.name] = entity;
            return acc;
        },
        {} as Record<string, CustomEntity>
    );

    const filteredKeys = useMemo(() => {
        const nonZeroKeys = keys.filter((key) => {
            const piiTypeCount = datasetPiiInfo ? aggregatePiiTypeCounts(datasetPiiInfo, key) : 0;
            return !!piiTypeCount;
        });
        if (!keyFilterInput) return nonZeroKeys;

        const filtered = nonZeroKeys.filter((key) => {
            if (!Object.values(PiiTypeEnum).includes(key as PiiTypeEnum) && customEntityMap?.[key] === undefined) {
                return false;
            }
            const { description, label } = getPiiTypeInfo(key as PiiTypeEnum);
            return description.toLowerCase().includes(keyFilterInput) || label.toLowerCase().includes(keyFilterInput);
        });
        return sortPiiTypeByLabel(filtered);
        // eslint-disable-next-line
    }, [keys, keyFilterInput]);

    const activeCustomEntities = customEntities.data?.filter((customEntity) => {
        return customEntity.datasetIds.includes(dataset.id);
    });

    const inactiveCustomEntities = customEntities.data?.filter((customEntity) => {
        return !customEntity.datasetIds.includes(dataset.id);
    });

    const undetectedCustomEntities = activeCustomEntities?.filter((customEntity) => {
        return !filteredKeys.includes(customEntity.name);
    });

    const filteredUndetectedKeys = useMemo(() => {
        if (!keyFilterInput) return undetectedEntities;

        return undetectedEntities.filter(({ description, label }) => {
            return description.toLowerCase().includes(keyFilterInput) || label.toLowerCase().includes(keyFilterInput);
        });
    }, [keyFilterInput, undetectedEntities]);

    const bulkChangeGenerators = useCallback(
        (newGeneratorState: PiiTypeGeneratorState) => {
            form.batch(() => {
                filteredKeys.forEach((key) => {
                    form.change(`generatorSetup.${key}` as keyof DatasetFormState, newGeneratorState);
                });
                filteredUndetectedKeys.forEach(({ value }) => {
                    form.change(`generatorSetup.${value}` as keyof DatasetFormState, newGeneratorState);
                });
                activeCustomEntities?.forEach((entity) => {
                    form.change(`generatorSetup.${entity.name}` as keyof DatasetFormState, newGeneratorState);
                });
            });
            form.submit();
        },
        // eslint-disable-next-line
        [form, filteredKeys, filteredUndetectedKeys]
    );

    const formModal = useQuiModal();

    const activeKeys = Object.entries(dataset.generatorSetup).filter(([k, f]) => f !== 'Off' && detectedEntities.includes(k)).length;
    return (
        <QuiBox display="flex" gap="md" flexDirection="column">
            <QuiBox display="flex" gap="md">
                <QuiBox
                    bg="background-base"
                    borderRadius="md"
                    padding="md"
                    display="flex"
                    flexDirection="column"
                    gap="sm"
                    border="stroke-base"
                    style={{ flex: 1 }}
                >
                    <span className={'redaction-box-title'}>Sensitive words</span>
                    <span className={'redaction-box-count'}>{getTotalRedactionsWithUnits(dataset)}</span>

                    <QuiBox display="flex" gap="xl" alignItems="center">
                        <span className={'redaction-box-percent'}>{getPercentRedacted(dataset)}% of Scanned Data</span>
                    </QuiBox>
                </QuiBox>

                <QuiBox
                    borderRadius="md"
                    padding="md"
                    display="flex"
                    flexDirection="row"
                    gap="sm"
                    border="stroke-base"
                    alignItems="center"
                    flexGrow="1"
                    bg="background-base"
                    style={{ flex: 1 }}
                >
                    <QuiBox display="flex" gap="sm" alignItems="start">
                        <div>
                            <QuiIcon icon={QuiIconEnum.LifeBuoy} />
                        </div>
                        <div>
                            <QuiFlexBoxColumn>
                                <QuiBox text="text-md" weight="medium">
                                    How to redact sensitive data
                                </QuiBox>
                                <QuiBox text="text-sm" color="text-subdued">
                                    <QuiLink external variant="currentcolor" iconRight="external-link" to="https://docs.tonic.ai/textual">
                                        View documentation
                                    </QuiLink>
                                    &nbsp;
                                    <StartChatButton />
                                </QuiBox>
                            </QuiFlexBoxColumn>
                        </div>
                    </QuiBox>
                </QuiBox>
            </QuiBox>

            <QuiBox alignItems="center" display="flex" justifyContent="space-between" gap="sm">
                <QuiTextInput
                    iconLeft={QuiIconEnum.Search}
                    placeholder="Filter entity by name..."
                    value={keyFilterInput}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.value.toLowerCase();
                        setKeyFilterInput(value);
                    }}
                />
                <QuiBox bg="background-base" style={{ width: '120px' }}>
                    <QuiMenu>
                        <QuiMenuButton iconRight="chevron-down">Bulk Edit</QuiMenuButton>
                        <QuiMenuItems>
                            <QuiMenuItem disabled={submitting} onClick={() => bulkChangeGenerators('Synthesis')}>
                                Synthesize
                            </QuiMenuItem>
                            <QuiMenuItem disabled={submitting} onClick={() => bulkChangeGenerators('Redaction')}>
                                Redact
                            </QuiMenuItem>
                            <QuiMenuItem disabled={submitting} onClick={() => bulkChangeGenerators('Off')}>
                                Off
                            </QuiMenuItem>
                        </QuiMenuItems>
                    </QuiMenu>
                </QuiBox>
                <QuiButton onClick={formModal.open}>Create Custom Entity Type</QuiButton>
            </QuiBox>

            <QuiBox display="flex" flexDirection="column" gap="xs">
                {dataset.files.length > 0 && (
                    <QuiBox display="flex" gap="sm" flexDirection="row" justifyContent="space-between">
                        <span className={'sensitive-count'}>
                            {filteredKeys.length} Sensitive Data {typeOrTypes} Found
                        </span>
                        <span className={'redaction-count'}>
                            Redacting {activeKeys} of {keys.length}
                        </span>
                    </QuiBox>
                )}

                <RedactionTypes keys={filteredKeys} dataset={dataset} customEntityMap={customEntityMap} />
                <UndetectedEntityTypes
                    undetectedEntities={filteredUndetectedKeys}
                    undetectedCustomEntities={undetectedCustomEntities}
                    customEntityMap={customEntityMap}
                />
                {/*
                 *TODO: pull from BE
                 */}
                <CustomEntityTypes customEntities={inactiveCustomEntities} />
            </QuiBox>
            <CustomEntityForm formModal={formModal} />
        </QuiBox>
    );
}
