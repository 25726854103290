import useEntityOptions from '@/pages/Dataset/useEntityOptions';
import { getPiiTypeInfo } from '@/pages/Dataset/utils';
import { PiiTypeEnum } from '@/types';
import { CustomEntity } from '@/types/custom-entities';
import { QuiBadge, QuiBox, QuiFlexBoxColumn, QuiIcon } from '@tonicai/ui-quinine';

type GeneratorConfigProps = Readonly<{
    piiType?: PiiTypeEnum;
    disabled?: boolean;
    customEntity?: CustomEntity;
}>;

export function GeneratorConfig({ piiType, disabled, customEntity }: GeneratorConfigProps) {
    const { EntityOptionButtons, EntityOptionMetadata } = useEntityOptions({
        piiType: (customEntity?.name as PiiTypeEnum) || piiType,
        disabled,
        automaticallySubmit: false,
    });

    return (
        <QuiBox>
            <QuiBox padding="xs sm" display="flex" justifyContent="space-between" alignItems="center">
                <QuiBox alignItems="center" gap="md" display="inline-flex">
                    {!!customEntity && (
                        <>
                            <QuiBadge>(.*)</QuiBadge>

                            <QuiFlexBoxColumn>
                                <QuiBox text="text-sm" weight="medium">
                                    {customEntity.displayName}
                                </QuiBox>
                                <QuiBox text="text-xs" color="text-subdued">
                                    {customEntity.description}
                                </QuiBox>
                            </QuiFlexBoxColumn>
                        </>
                    )}
                    {!customEntity && piiType && (
                        <>
                            <QuiIcon icon={getPiiTypeInfo(piiType)?.icon ?? 'help-circle'} />

                            <QuiFlexBoxColumn>
                                <QuiBox text="text-sm" weight="medium">
                                    {getPiiTypeInfo(piiType)?.label}
                                </QuiBox>
                                <QuiBox text="text-xs" color="text-subdued">
                                    {getPiiTypeInfo(piiType)?.description}
                                </QuiBox>
                            </QuiFlexBoxColumn>
                        </>
                    )}
                </QuiBox>

                <QuiBox display="flex" gap="md" alignItems="center">
                    <EntityOptionButtons />
                </QuiBox>
            </QuiBox>
            <EntityOptionMetadata />
        </QuiBox>
    );
}
