import { FileDrop } from '@/components/GettingStartedPlayground/Playground/FileDrop';
import { schema } from '@/components/GettingStartedPlayground/Playground/prosemirror';
import { Samples } from '@/components/GettingStartedPlayground/Playground/Samples';
import { clearDeidResponse, updateSelectedFile } from '@/components/GettingStartedPlayground/Playground/state';
import styles from './styles.module.scss';
import { usePlaygroundContext } from '@/components/GettingStartedPlayground/Playground/usePlaygroundContext';
import { QuiBox } from '@tonicai/ui-quinine';
import { useCallback, useState } from 'react';
import { DOMParser as ProseDOMParser } from 'prosemirror-model';
import { AllSelection } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import ChecklistPopover from '@/components/GettingStartedChecklist/ChecklistPopover';
import useChecklistState, { checklistSteps } from '@/components/GettingStartedChecklist/useChecklistState';

function htmlStringToProseMirrorNode(htmlString: string) {
    const dom = new DOMParser().parseFromString(htmlString, 'text/html');
    return new ProseDOMParser(schema, []).parse(dom);
}

type EditorPlaceholderProps = {
    editorViewRef: React.MutableRefObject<EditorView | null>;
};

export function EditorPlaceholder({ editorViewRef }: EditorPlaceholderProps) {
    const storeOptions = usePlaygroundContext();

    const setEditorContent = useCallback(
        (content: string) => {
            if (!editorViewRef) return;

            const editorView = editorViewRef.current;

            if (!editorView) return;

            const tr = editorViewRef.current?.state.tr;

            if (!tr) return;

            tr.setSelection(new AllSelection(editorView.state.doc));
            tr.replaceSelectionWith(htmlStringToProseMirrorNode(content));
            editorView.dispatch(tr);

            clearDeidResponse(storeOptions.store);
        },
        [storeOptions.store, editorViewRef]
    );

    const checklistState = useChecklistState();
    const [isShowingInstruction, setIsShowingInstruction] = useState(true);

    return (
        <>
            <div className={styles.placeholder}>
                <ChecklistPopover
                    onClose={() => {
                        setIsShowingInstruction(false);
                    }}
                    isOpen={isShowingInstruction && checklistState.activeStep === checklistSteps.testPlayground && !checklistState.testPlayground}
                    content={
                        <ChecklistPopover.Content
                            title="Test sensitive value detection & synthesis"
                            content="Enter sample free text to see how Textual automatically identifies and synthesizes sensitive values."
                        />
                    }
                >
                    <QuiBox text="mono-text-md" color="text-base--disabled" padding="lg">
                        Start typing to see how the model identifies, tags, and synthesizes...
                    </QuiBox>
                </ChecklistPopover>
                <FileDrop onFileSelect={(file: File) => updateSelectedFile(storeOptions.store, file)} />
            </div>
            <div style={{ position: 'absolute', bottom: 0 }}>
                <Samples setEditorContent={setEditorContent} />
            </div>
        </>
    );
}
