import { QuiBox, QuiButton, QuiButtonExternalLink } from '@tonicai/ui-quinine';
import { useEffect } from 'react';
import { fetchUserApiKeys, useUserAPIKeys } from '@/stores';
import { SDK_DOCUMENTATION_URL } from '@/constants';
import { ApiKeyModal } from '@/components/ApiKeyModal/ApiKeyModal';

export function ApiKeys() {
    const { userAPIKeys } = useUserAPIKeys();

    useEffect(() => {
        fetchUserApiKeys();
    }, []);

    // Don't mutate userAPIKeys directly
    const sortedApiKeys = [...userAPIKeys].sort((a, b) => {
        return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
    });

    return (
        <QuiBox bg="background-base" borderRadius="md" border="stroke-base">
            <QuiBox padding="md" gap="md" display="flex" flexDirection="column" borderBottom={sortedApiKeys.length !== 0 ? 'stroke-base' : undefined}>
                <QuiBox text="text-xs" weight="medium">
                    🔑 API Keys
                </QuiBox>
                <QuiBox color="text-base">
                    The Textual API lets you fetch redacted data via a simple Python script.
                    <br />
                    <QuiButtonExternalLink
                        variant={'minimal'}
                        href={SDK_DOCUMENTATION_URL}
                        openInNewTab={true}
                        iconRight="external-link"
                        style={{ width: 'fit-content', padding: 0 }}
                    >
                        View Documentation
                    </QuiButtonExternalLink>
                </QuiBox>
                <ApiKeyModal>
                    <QuiButton style={{ width: '100%' }} iconLeft={'key'}>
                        Create an API Key
                    </QuiButton>
                </ApiKeyModal>
            </QuiBox>
        </QuiBox>
    );
}
