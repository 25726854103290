import { useState } from 'react';

export function useFadeInOpenState<T>(initialState: T, className: string, time: number): [T, (val: T) => void, string] {
    const [open, setOpen] = useState<T>(initialState);
    const [openClass, setOpenClass] = useState('');
    const _setOpen = (val: T) => {
        if (val) {
            setTimeout(() => setOpenClass(className), 1);
            setOpen(val);
        } else {
            setOpenClass('');
            setTimeout(() => setOpen(val), time);
        }
    };
    return [open, _setOpen, openClass];
}
