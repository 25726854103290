import { useLocalStorage } from 'usehooks-ts';

export const checklistSteps = {
    testPlayground: 'getting-started-test-playground',
    installSDK: 'getting-started-install-sdk',
    generateAPIKey: 'getting-started-generate-api-key',
    testSDK: 'getting-started-test-sdk',
};

const activeChecklistStep = 'getting-started-active-checklist-step';
const gettingStarted = 'getting-started-show-getting-started';
const confetti = 'getting-started-confetti-has-been-show';

export default function useChecklistState() {
    const [testPlayground, setTestPlayground] = useLocalStorage(checklistSteps.testPlayground, false);
    const [installSDK, setInstallSDK] = useLocalStorage(checklistSteps.installSDK, false);
    const [generateAPIKey, setGenerateAPIKey] = useLocalStorage(checklistSteps.generateAPIKey, false);
    const [testSDK, setTestSDK] = useLocalStorage(checklistSteps.testSDK, false);
    const [activeStep, setActiveStep] = useLocalStorage(activeChecklistStep, '');
    const [showGettingStarted, setShowGettingStarted] = useLocalStorage(gettingStarted, true);
    const [confettiShown, setConfettiShown] = useLocalStorage(confetti, false);
    return {
        testPlayground,
        setTestPlayground,
        installSDK,
        setInstallSDK,
        generateAPIKey,
        setGenerateAPIKey,
        testSDK,
        setTestSDK,
        activeStep,
        setActiveStep,
        showGettingStarted,
        setShowGettingStarted,
        confettiShown,
        setConfettiShown,
    };
}
