import { useDatasetContext } from '@/contexts/DatasetContext';
import { ENTITIES_ENDPOINT } from '@/pages/CustomEntities/CustomEntities';
import { client } from '@/services/HTTPClient';
import { DatasetFormState, deprecatedEntities, PiiTypeGeneratorState } from '@/types';
import { CustomEntity } from '@/types/custom-entities';
import { UseQueryResult } from 'react-query';
import { useForm } from 'react-final-form';
import { useState } from 'react';
import { QuiSwitch } from '@tonicai/ui-quinine';

type CustomEntityActivationSwitchProps = {
    entity: CustomEntity;
    customEntityQuery: UseQueryResult<CustomEntity[], void>;
};

const getCurrentStates = (generatorSetup: Record<string, PiiTypeGeneratorState>): Set<string> => {
    const states = Object.entries(generatorSetup)
        .filter(([piiType, state]) => !deprecatedEntities.includes(piiType) && state !== undefined && state !== null)
        .map(([, state]) => state);
    return new Set(states);
};

export default function CustomEntityActivationSwitch({ entity, customEntityQuery }: CustomEntityActivationSwitchProps) {
    const { dataset } = useDatasetContext();
    const form = useForm<DatasetFormState>();
    const [isActive, setIsActive] = useState(entity.datasetIds.includes(dataset.id));

    const handleEntityActivation = async (value: boolean) => {
        setIsActive(value);
        const datasetIds = [...entity.datasetIds.filter((id) => id !== dataset.id), ...(value ? [dataset.id] : [])];
        await client.put(ENTITIES_ENDPOINT, { ...entity, datasetIds });
        customEntityQuery.refetch();
        if (!value) {
            form.change(`generatorSetup.${entity.name}` as keyof DatasetFormState, undefined);
            return;
        }
        const states = getCurrentStates(form.getState().values.generatorSetup);
        if (states.size !== 1) {
            form.change(`generatorSetup.${entity.name}` as keyof DatasetFormState, 'Redaction');
            return;
        }
        const state = states.values().next().value;
        form.change(`generatorSetup.${entity.name}` as keyof DatasetFormState, state);
    };
    return <QuiSwitch checked={isActive} onChange={handleEntityActivation} />;
}
