import { instrumentation } from '@/instrumentation/instrumentation';
import { LabelCustomList } from '@/types/api_request_record';
import { atom } from 'jotai';
import { nanoid } from 'nanoid';
import { client } from '@/services/HTTPClient';
import axios, { AxiosError, AxiosResponse, isCancel } from 'axios';
import { FetchStatus, PiiTypeEnum, PiiTypeGeneratorState } from '@/types';
import { JotaiStore, globalStore } from '@/stores';
import { hasOpenAiKeyAtom } from '@/stores';
import { FineTunedRule } from '@/components/GettingStartedPlayground/CustomizeNer';

export type PlaygroundStatus = Extract<FetchStatus, 'init' | 'loading' | 'error' | 'success'>;
export const REDACT_FILE_STATUS_FAILURE_REASONS = [
    'Success',
    'JobFailed',
    'JobCanceled',
    'JobTookTooLong',
    'RequestCancelled',
    'JobFailedToUpload',
    'RequestFailedWith500',
    'Unknown',
];

type RedactedTextChunk = {
    id: string;
    text: string;
    label?: string;
    score?: number;
    redactedText: string;
    syntheticText: string;
};

export type PreviewData = {
    id: string;
    chunks: RedactedTextChunk[];
};

type SynthesisRequest = {
    text: string;
};

type SingleDetectionResult = {
    start: number;
    end: number;
    label: PiiTypeEnum;
    text: string;
    score: number;
    jsonPath?: string;
};

type PlaygroundDetectionResult = {
    start: number;
    end: number;
    newStart: number;
    newEnd: number;
    label: string;
    text: string;
    newText: string;
    score: number;
    language: string;
};

type CombinedPlaygroundDetectionResult = {
    start: number;
    end: number;
    newStart: number;
    newEnd: number;
    label: string;
    text: string;
    redactedNewValue: string;
    syntheticNewValue: string;
    score: number;
    language: string;
};

type RedactedFromApiResponse = {
    originalText: string;
    redactedText: string;
    usage: number;
    deIdentifyResults: SingleDetectionResult[];
};

type PlaygroundRedactionFromApiResponse = {
    originalText: string;
    redactedText: string;
    syntheticText: string;
    usage: number;
    redactedDeIdentifyResults: PlaygroundDetectionResult[];
    syntheticDeIdentifyResults: PlaygroundDetectionResult[];
    language: string;
};

type CombinedPlaygroundRedactionResponseFromApi = {
    originalText: string;
    redactedText: string;
    syntheticText: string;
    usage: number;
    deIdentifyResults: CombinedPlaygroundDetectionResult[];
    language: string;
};

type PlaygroundFileRedactionResponseFromApi = {
    previewData: string;
    redactedData: string;
    redactedDeIdentifyResults: PlaygroundDetectionResult[][][];
    syntheticDeIdentifyResults: PlaygroundDetectionResult[][][];
};

type CombinedPlaygroundFileRedactionResponseFromApi = {
    previewData: string;
    redactedData: string;
    deIdentifyResults: CombinedPlaygroundDetectionResult[][][];
};

// In milliseconds. Bumped this up from 500 so that slow typers don't get rate-limited as often
const REQUEST_DELAY = 800;

/**
 * ATOMS
 */

export const abortControllerAtom = atom<AbortController>(new AbortController());

export const blockTextContentAtom = atom<string[]>([]);
export const fileAtom = atom<File>();

export const rulesAtom = atom<FineTunedRule[]>([]);
export const allowAtom = atom<Map<PiiTypeEnum, LabelCustomList>>(new Map());
export const blockAtom = atom<Map<PiiTypeEnum, LabelCustomList>>(new Map());
export const generatorSetupAtom = atom<Map<PiiTypeEnum, PiiTypeGeneratorState>>(new Map());

export const sampleTextSelectedAtom = atom<boolean>(false);

export const blockTextContentEmptyAtom = atom<boolean>((get) => {
    return (
        get(blockTextContentAtom)
            .map((s) => s.trim())
            .join('')
            .trim().length === 0
    );
});

export const responseStaleOrMissingAtom = atom((get) => {
    const deidResponse = get(deidResponseAtom);

    if (!deidResponse) return true;

    return deidResponse.originalText !== get(blockTextContentAtom).join(' ');
});

export const blockTextOffsetAtom = atom((get) => {
    let offset = 0;
    return (get(blockTextContentAtom) ?? []).map((content, index) => {
        const currentBlockOffset = offset + index;
        offset += content.length;
        return currentBlockOffset;
    });
});

export const deidResponseAtom = atom<CombinedPlaygroundRedactionResponseFromApi | null>(null);

export const timeoutIdAtom = atom<null | number>(null);

export const deidResponseStatusAtom = atom<PlaygroundStatus>('init');

export const llmSynthesisEnabledAtom = atom(false);

// Take into account the value of whether the user has an OpenAI API key set or not
// this is the source of trutch for whether to use the LLM Synthesis endpoint or not
// We should probably just make it a URL parameter on the endpoint that has a ?llm=true
// param or something like that and then only use LLM Synthesis on the backend if that's
// set to true AND the user has an OpenAI API key set in the backend.
export const llmSynthesisActuallyEnabledAtom = atom((get) => {
    return get(llmSynthesisEnabledAtom) && globalStore.get(hasOpenAiKeyAtom);
});

export const llmSynthesisAbortControllerAtom = atom<AbortController | null>(null);

export const llmSynthesisResponseStatusAtom = atom<PlaygroundStatus>('init');

export const llmSynthesisResponseAtom = atom<RedactedFromApiResponse | null>(null);

export const languageAtom = atom<string>((get) => {
    const deidResponse = get(deidResponseAtom);
    return deidResponse?.language ?? 'en';
});

export const previewDataAtom = atom<PreviewData[] | string | null>((get) => {
    const deidResponse = get(deidResponseAtom);
    const blockTextContent = get(blockTextContentAtom);
    const blockTextOffsets = get(blockTextOffsetAtom);
    const llmSynthesisEnabled = get(llmSynthesisActuallyEnabledAtom);
    const llmSynthesisResponse = get(llmSynthesisResponseAtom);

    // If LLM Synthesis is enabled & we've got a response back from the LLM synthesis
    // endpoint, return the LLM Synthesis result
    if (llmSynthesisEnabled && llmSynthesisResponse) return llmSynthesisResponse.redactedText;

    // If LLM Synthesis is _not_ enabled, and we have a deidResponse, return the
    // redacted text chunks after formatting them for the preview
    if (!llmSynthesisEnabled && deidResponse) {
        return blockTextOffsets.map((_, index) => {
            const content = blockTextContent[index];

            return {
                id: nanoid(),
                chunks: getRedactionTextChunksForPlayground(content, deidResponse, blockTextOffsets?.[index] ?? 0),
            };
        });
    }

    // In all other cases, return null
    return null;
});

export const previewStatusAtom = atom<PlaygroundStatus>((get) => {
    const deidResponseStatus = get(deidResponseStatusAtom);
    const llmSynthesisEnabled = get(llmSynthesisActuallyEnabledAtom);
    const llmSynthesisResponseFetchStatus = get(llmSynthesisResponseStatusAtom);
    const blockTextContentEmpty = get(blockTextContentEmptyAtom);
    const previewData = get(previewDataAtom);

    if (blockTextContentEmpty) return 'init';

    if (llmSynthesisEnabled && llmSynthesisResponseFetchStatus === 'success' && previewData) return 'success';
    if (llmSynthesisEnabled && llmSynthesisResponseFetchStatus === 'error') return 'error';
    if (llmSynthesisEnabled && llmSynthesisResponseFetchStatus === 'loading') return 'loading';

    if (!llmSynthesisEnabled && deidResponseStatus === 'error') return 'error';
    if (!llmSynthesisEnabled && deidResponseStatus === 'loading') return 'loading';
    if (!llmSynthesisEnabled && deidResponseStatus === 'success' && previewData) return 'success';

    return 'init';
});

/**
 * Internal functions
 */

export function getRedactionTextChunksForPlayground(
    text: string,
    response: CombinedPlaygroundRedactionResponseFromApi,
    offset: number
): RedactedTextChunk[] {
    const textChunks: RedactedTextChunk[] = [];

    let currentChunkString = '';
    let inRedaction: boolean | null = false;
    let previousRedaction: CombinedPlaygroundDetectionResult | undefined = undefined;

    for (let i = 0; i < text.length; i++) {
        const actualI = i + offset;
        const detectedEntities = response.deIdentifyResults;
        const currentRedaction = detectedEntities.find((d) => {
            return actualI >= d.start && actualI < d.end;
        });

        const currentIndexInRedaction = currentRedaction !== undefined;

        if (currentIndexInRedaction !== inRedaction) {
            if (currentChunkString.length > 0) {
                textChunks.push({
                    id: nanoid(),
                    text: currentChunkString,
                    label: previousRedaction?.label,
                    score: previousRedaction?.score,
                    syntheticText: previousRedaction?.syntheticNewValue ?? '',
                    redactedText: previousRedaction?.redactedNewValue ?? '',
                });
                currentChunkString = '';
            }
            previousRedaction = currentRedaction;
            inRedaction = currentIndexInRedaction;
        }

        currentChunkString += text.charAt(i);

        if (i === text.length - 1) {
            textChunks.push({
                id: nanoid(),
                text: currentChunkString,
                label: previousRedaction?.label,
                score: previousRedaction?.score,
                syntheticText: previousRedaction?.syntheticNewValue ?? '',
                redactedText: previousRedaction?.redactedNewValue ?? '',
            });
        }
    }

    return textChunks;
}

function fetchLLMSynthesisResponse(store: JotaiStore) {
    if (store.get(llmSynthesisActuallyEnabledAtom) === false) return;

    const blockTextContent = store.get(blockTextContentAtom);
    const blockTextContentEmpty = store.get(blockTextContentEmptyAtom);

    if (blockTextContentEmpty) return;

    store.get(llmSynthesisAbortControllerAtom)?.abort();
    const abortController = new AbortController();

    store.set(llmSynthesisAbortControllerAtom, abortController);
    store.set(llmSynthesisResponseStatusAtom, 'loading');

    client
        .post<RedactedFromApiResponse, AxiosResponse<RedactedFromApiResponse>, SynthesisRequest>(
            '/api/synthesis',
            { text: blockTextContent.join(' ') },
            {
                signal: abortController.signal,
            }
        )
        .then(({ data }) => {
            store.set(llmSynthesisResponseAtom, data);
            store.set(llmSynthesisResponseStatusAtom, 'success');
        })
        .catch((error) => {
            if (!isCancel(error)) {
                store.set(llmSynthesisResponseStatusAtom, 'error');
            }
        });
}

/**
 * API
 */

export function toggleLLMSynthesis(store: JotaiStore) {
    store.set(llmSynthesisEnabledAtom, (current) => !current);
    fetchDeidentifyResponse(store);
}

export function clearDeidResponse(store: JotaiStore) {
    store.set(deidResponseAtom, null);
    store.set(llmSynthesisResponseAtom, null);
    store.get(abortControllerAtom).abort();
    store.set(abortControllerAtom, new AbortController());
}

export function fetchDeidentifyResponse(store: JotaiStore) {
    const labelAllowLists = store.get(allowAtom);
    const labelBlockLists = store.get(blockAtom);
    const llmSynthesisActuallyEnabled = store.get(llmSynthesisActuallyEnabledAtom);

    if (llmSynthesisActuallyEnabled) {
        fetchLLMSynthesisResponse(store);
        return;
    }

    const text = store.get(blockTextContentAtom).join(' ');

    if (text.trim().length === 0) return;

    // Cancel any existing requests (Which shoudn't exist, but just in case...)
    const currentAbortController = store.get(abortControllerAtom);
    currentAbortController.abort();
    const abortController = new AbortController();
    store.set(abortControllerAtom, abortController);

    // Set the fetch status to loading
    store.set(deidResponseStatusAtom, 'loading');

    const allow = {};
    const block = {};

    let numAllowLists = 0;
    if (labelAllowLists) {
        labelAllowLists.forEach((value: LabelCustomList, key: string) => {
            Object.assign(allow, { [key]: value });
            numAllowLists += value.regexes.length;
        });
    }

    let numBlockLists = 0;
    if (labelBlockLists) {
        labelBlockLists.forEach((value: LabelCustomList, key: string) => {
            Object.assign(block, { [key]: value });
            numBlockLists += value.regexes.length;
        });
    }

    client
        .post<PlaygroundRedactionFromApiResponse>(
            '/api/playground',
            {
                text: store.get(blockTextContentAtom).join(' '),
                labelAllowLists: allow,
                labelBlockLists: block,
                generatorSetup: {},
            },
            {
                signal: store.get(abortControllerAtom).signal,
            }
        )
        .then(({ data }) => {
            //This request runs the text through the system twice, once for everything redacted and once for everything synthesized.
            //The entities are the same in both runs except for the replaced value (newText) is different so we join it all together before saving.
            const combinedData: CombinedPlaygroundRedactionResponseFromApi = {
                originalText: data.originalText,
                redactedText: data.redactedText,
                syntheticText: data.syntheticText,
                usage: data.usage,
                deIdentifyResults: data.redactedDeIdentifyResults.map((result, index) => {
                    return {
                        start: result.start,
                        end: result.end,
                        newStart: result.newStart,
                        newEnd: result.newEnd,
                        label: result.label,
                        text: result.text,
                        score: result.score,
                        language: result.language,
                        redactedNewValue: result.newText,
                        syntheticNewValue: data.syntheticDeIdentifyResults[index].newText,
                    };
                }),
                language: data.redactedDeIdentifyResults.length > 0 ? data.redactedDeIdentifyResults[0].language : 'en',
            };

            if (text) {
                const isSample = store.get(sampleTextSelectedAtom);

                instrumentation.welcomePlaygroundInteraction(text.length, isSample, false, 0, numAllowLists, numBlockLists);
                updateSampleTextSelected(store, false);
            }

            store.set(deidResponseAtom, combinedData);
            store.set(deidResponseStatusAtom, 'success');
        })
        .catch((e) => {
            if (!isCancel(e)) {
                store.set(deidResponseStatusAtom, 'error');
            }
        });
}

export const redactFileResponseAtom = atom<CombinedPlaygroundFileRedactionResponseFromApi | null>(null);
export const redactFileResponseStatusAtom = atom<PlaygroundStatus>('init');
export const redactFileStatusAtom = atom<string>('Success');

export function fetchRedactFileResponse(store: JotaiStore) {
    const labelAllowLists = store.get(allowAtom);
    const labelBlockLists = store.get(blockAtom);

    const file = store.get(fileAtom);
    if (file == null) return;

    // Cancel any existing requests (Which shoudn't exist, but just in case...)
    const currentAbortController = store.get(abortControllerAtom);
    currentAbortController.abort();
    const abortController = new AbortController();
    store.set(abortControllerAtom, abortController);

    // Set the fetch status to loading
    store.set(redactFileResponseStatusAtom, 'loading');

    const allow = {};
    const block = {};

    let numAllowLists = 0;
    if (labelAllowLists) {
        labelAllowLists.forEach((value: LabelCustomList, key: string) => {
            Object.assign(allow, { [key]: value });
            numAllowLists += value.regexes.length;
        });
    }

    let numBlockLists = 0;
    if (labelBlockLists) {
        labelBlockLists.forEach((value: LabelCustomList, key: string) => {
            Object.assign(block, { [key]: value });
            numBlockLists += value.regexes.length;
        });
    }

    const metadata = {
        fileName: file.name,
        csvConfig: {},
        labelBlockLists: block,
        labelAllowLists: allow,
    };

    const metadataBlob = new Blob([JSON.stringify(metadata)], {
        type: 'application/json',
    });

    // order matters here, file must be the last item
    const payload = new FormData();
    payload.append('document', metadataBlob);
    payload.append('file', file, file.name);

    client
        .post<PlaygroundFileRedactionResponseFromApi>('/api/playground/file', payload, {
            signal: store.get(abortControllerAtom).signal,
        })
        .then(({ data }) => {
            instrumentation.welcomePlaygroundInteraction(data.previewData.length, false, true, 0, numAllowLists, numBlockLists);

            const combinedData: CombinedPlaygroundFileRedactionResponseFromApi = {
                previewData: data.previewData,
                redactedData: data.redactedData,
                deIdentifyResults: data.redactedDeIdentifyResults.map((row: PlaygroundDetectionResult[][], rowIdx: number) => {
                    return row.map((column: PlaygroundDetectionResult[], colIdx: number) => {
                        return column.map((result: PlaygroundDetectionResult, idx: number) => {
                            return {
                                start: result.start,
                                end: result.end,
                                newStart: result.newStart,
                                newEnd: result.newEnd,
                                label: result.label,
                                text: result.text,
                                score: result.score,
                                language: result.language,
                                redactedNewValue: result.newText,
                                syntheticNewValue: data.syntheticDeIdentifyResults[rowIdx][colIdx][idx].newText,
                            };
                        });
                    });
                }),
            };

            store.set(redactFileResponseAtom, combinedData);
            store.set(redactFileResponseStatusAtom, 'success');
            store.set(redactFileStatusAtom, 'Success');
        })
        .catch((err: Error | AxiosError) => {
            if (!isCancel(err)) {
                store.set(redactFileResponseStatusAtom, 'error');

                if (axios.isAxiosError(err)) {
                    const axiosError = err as AxiosError;
                    if (axiosError.status == 500) {
                        store.set(redactFileStatusAtom, 'RequestFailedWith500');
                    } else if (axiosError.status == 400) {
                        const status = axiosError.response?.data?.toString();
                        store.set(redactFileStatusAtom, status ?? 'Unknown');
                    }
                } else {
                    store.set(redactFileStatusAtom, 'Unknown');
                }
            } else {
                store.set(redactFileStatusAtom, 'RequestCancelled');
            }
        });
}

export function updateSelectedFile(store: JotaiStore, file: File | null) {
    store.set(fileAtom, file ?? undefined);
    fetchRedactFileResponse(store);
}

export function clearFileUploadState(store: JotaiStore) {
    store.set(fileAtom, undefined);
    store.set(redactFileResponseStatusAtom, 'init');
    store.set(redactFileStatusAtom, 'Success');
    store.set(redactFileResponseAtom, null);
}

export function updateSampleTextSelected(store: JotaiStore, isSample: boolean) {
    store.set(sampleTextSelectedAtom, isSample);
}

export function updateGeneratorSetup(store: JotaiStore, generatorSetup: Map<PiiTypeEnum, PiiTypeGeneratorState>) {
    store.set(generatorSetupAtom, generatorSetup);

    const text = store.get(blockTextContentAtom).join(' ');
    const isSample = store.get(sampleTextSelectedAtom);

    const labelAllowLists = store.get(allowAtom);
    const labelBlockLists = store.get(blockAtom);

    let numAllowLists = 0;
    if (labelAllowLists) {
        labelAllowLists.forEach((value: LabelCustomList) => {
            numAllowLists += value.regexes.length;
        });
    }

    let numBlockLists = 0;
    if (labelBlockLists) {
        labelBlockLists.forEach((value: LabelCustomList) => {
            numBlockLists += value.regexes.length;
        });
    }

    let numNonRedactedEntities = 0;
    generatorSetup.forEach((value: PiiTypeGeneratorState) => {
        if (value !== 'Redaction') {
            numNonRedactedEntities += 1;
        }
    });

    instrumentation.welcomePlaygroundInteraction(text.length, isSample, false, numNonRedactedEntities, numAllowLists, numBlockLists);
}

export function updateRules(store: JotaiStore, rules: FineTunedRule[]) {
    store.set(rulesAtom, rules);
    const allow = new Map<PiiTypeEnum, LabelCustomList>();
    const block = new Map<PiiTypeEnum, LabelCustomList>();

    for (const rule of rules) {
        if (rule.isIncluded) {
            allow.set(rule.entity, {
                regexes: [...(allow.get(rule.entity)?.regexes ?? []), rule.regex],
                strings: [],
            });
        } else {
            block.set(rule.entity, {
                regexes: [...(block.get(rule.entity)?.regexes ?? []), rule.regex],
                strings: [],
            });
        }
    }
    store.set(allowAtom, allow);
    store.set(blockAtom, block);
}

export function updateBlockTextContent(store: JotaiStore, blockTextContent: string[], isPasteEvent?: boolean) {
    const currentValue = store.get(blockTextContentAtom).join(' ');
    const newValue = blockTextContent.join(' ');

    if (currentValue === newValue) return;

    store.set(blockTextContentAtom, blockTextContent);

    store.set(abortControllerAtom, (abortController) => {
        abortController.abort();
        return new AbortController();
    });

    if (isPasteEvent) {
        store.set(timeoutIdAtom, (currentTimeoutId) => {
            if (currentTimeoutId !== null) {
                window.clearTimeout(currentTimeoutId);
            }

            fetchDeidentifyResponse(store);

            return null;
        });
    } else {
        store.set(timeoutIdAtom, (currentTimeoutId) => {
            if (currentTimeoutId !== null) {
                window.clearTimeout(currentTimeoutId);
            }

            return window.setTimeout(() => {
                fetchDeidentifyResponse(store);
            }, REQUEST_DELAY);
        });
    }
}
