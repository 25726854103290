const syftScriptContent = `
!(function (t) {
    if (((window.syftc = t), window.syft)) return;
    (window.syft = []),
        ['identify', 'track', 'page', 'signup'].forEach(function (t) {
            window.syft[t] = function () {
                var s = [].slice.call(arguments);
                s.unshift(t), window.syft.push(s);
            };
        });
    var s = document.createElement('script');
    (s.async = !0), s.setAttribute('src', 'https://cdn.syftdata.com/syftnext/syft.umd.js'), (document.body || document.head).appendChild(s);
})({ sourceId: 'clqzwh5zw0007ib08yfcmhi1a', autoTrackEnabled: false });
`;

const syftEnabled =
    window.location.hostname === 'solar.tonic.ai' ||
    window.location.hostname === 'textual.tonic.ai' ||
    sessionStorage.getItem('solar.hosted-syft-test') !== null;

const scriptId = 'syft-script-loader';

function identifySignup(email: string, firstName?: string, lastName?: string) {
    window.syft?.identify(email, {
        source: 'signup',
        type: 'Email Sign-up',
        firstName,
        lastName,
    });
}

function identifySSOSignup(email: string, userCreatedDate: string, firstName?: string, lastName?: string) {
    try {
        const timestampUserCreated = new Date(userCreatedDate).valueOf();
        const now = new Date().valueOf();
        const millisecondsSinceAccountCreation = now - timestampUserCreated;

        if (millisecondsSinceAccountCreation < 3000) {
            window.syft?.identify(email, {
                source: 'signup',
                type: 'SSO Sign-up',
                firstName,
                lastName,
            });
        }
    } catch {
        // Do nothing if date parsing fails
    }
}

function identifySurveySubmission(email: string, firstName?: string, lastName?: string, title?: string) {
    window.syft?.identify(email, {
        source: 'form_submit',
        type: 'Survey',
        firstName,
        lastName,
        title,
    });
}

function init() {
    if (!syftEnabled) return;

    // Add Syft tracking script on hosted only
    if (document.getElementById(scriptId) === null) {
        const syftScriptTag = document.createElement('script');
        syftScriptTag.id = scriptId;
        syftScriptTag.async = true;
        syftScriptTag.defer = true;
        syftScriptTag.innerHTML = syftScriptContent;
        document.body.appendChild(syftScriptTag);
    }
}

export const syft = {
    init,
    identifySignup,
    identifySSOSignup,
    identifySurveySubmission,
};
