import { useCurrentUser, useDatasets, useParseJobs } from '@/stores';
import {
    QuiBadge,
    QuiBox,
    QuiFlexBoxColumn,
    QuiIcon,
    QuiMenu,
    QuiMenuButton,
    QuiMenuItem,
    QuiMenuItems,
    QuiSwitchField,
    QuiTextInput,
} from '@tonicai/ui-quinine';
import dayjs from 'dayjs';
import { FormApi } from 'final-form';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './PipelinesDatasetsTable.module.scss';

enum TableSortOrder {
    NAME_ASC = 'name-asc',
    NAME_DESC = 'name-desc',
    UPDATED_NEWEST = 'updated-newest',
    UPDATED_OLDEST = 'updated-oldest',
}

type TableData = {
    type: 'dataset' | 'pipeline';
    lastUpdated: Date;
    name: string;
    id: string;
};

type PipelinesDatasetsTableProps = Readonly<{
    excludeDatasets?: boolean;
    excludePipelines?: boolean;
    filterable?: boolean;
    isCustomEntity?: boolean;
    form?: FormApi;
}>;

export function PipelinesDatasetsTable({ excludeDatasets, excludePipelines, isCustomEntity, form }: PipelinesDatasetsTableProps) {
    const user = useCurrentUser();
    const parseJobs = useParseJobs();
    const datasets = useDatasets();
    const [queryString, setQueryString] = useState('');
    const [sortOrder, setSortOrder] = useState<TableSortOrder>(TableSortOrder.UPDATED_NEWEST);

    const data = useMemo<TableData[]>(() => {
        let d: TableData[] = [];

        if (!excludeDatasets) {
            datasets.forEach((dataset) => {
                d.push({
                    type: 'dataset',
                    lastUpdated: dayjs(dataset.lastUpdated).toDate(),
                    name: dataset.name,
                    id: dataset.id,
                });
            });
        }

        if (!excludePipelines) {
            (parseJobs ?? []).forEach((parseJob) => {
                d.push({
                    type: 'pipeline',
                    lastUpdated: dayjs(parseJob.lastModifiedDate).toDate(),
                    name: parseJob.name,
                    id: parseJob.id,
                });
            });
        }

        // Use search query to filter data
        if (queryString) {
            const query = queryString.toLowerCase();
            const queryWords = query
                .split(' ')
                .map((word) => word.trim())
                .filter((word) => word.length > 0);
            d = d.filter((item) => queryWords.some((word) => item.name.toLowerCase().includes(word)));
        }

        return d.sort((a, b) => {
            if (sortOrder === 'name-asc') {
                return a.name.localeCompare(b.name);
            } else if (sortOrder === 'name-desc') {
                return b.name.localeCompare(a.name);
            }

            if (sortOrder === 'updated-newest') {
                return a.lastUpdated.valueOf() > b.lastUpdated.valueOf() ? -1 : 1;
            } else {
                return a.lastUpdated.valueOf() < b.lastUpdated.valueOf() ? -1 : 1;
            }
        });
    }, [excludeDatasets, excludePipelines, sortOrder, datasets, parseJobs, queryString]);

    const bulkAction = (value: boolean) => {
        form?.batch(() => {
            data.map((item) => {
                form.change(`${item.type}.${item.id}.enabled`, value);
            });
        });
    };

    return (
        <QuiFlexBoxColumn gap="lg">
            {/*
             * TODO: this should not be in this component
             */}
            <QuiBox display="flex" gap="sm">
                <QuiBox flexGrow="1">
                    <QuiTextInput
                        value={queryString}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setQueryString(e.target.value);
                        }}
                        placeholder="Filter by name..."
                    />
                </QuiBox>

                {/*
                 * TODO: this should not be in this component
                 */}
                {isCustomEntity && (
                    <QuiMenu>
                        <QuiMenuButton iconRight="chevron-down">Bulk action</QuiMenuButton>
                        <QuiMenuItems>
                            <QuiMenuItem
                                onClick={() => {
                                    bulkAction(true);
                                }}
                            >
                                Enable
                            </QuiMenuItem>
                            <QuiMenuItem
                                onClick={() => {
                                    bulkAction(false);
                                }}
                            >
                                Disable
                            </QuiMenuItem>
                        </QuiMenuItems>
                    </QuiMenu>
                )}
            </QuiBox>

            <QuiBox border="stroke-base" borderRadius="md" overflow="hidden">
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th {...(isCustomEntity && { colSpan: 2 })}>
                                <button
                                    type="button"
                                    onClick={() => {
                                        if (sortOrder === TableSortOrder.NAME_ASC) {
                                            setSortOrder(TableSortOrder.NAME_DESC);
                                        } else {
                                            setSortOrder(TableSortOrder.NAME_ASC);
                                        }
                                    }}
                                >
                                    <span>Name</span>
                                    {sortOrder === TableSortOrder.NAME_ASC ? <QuiIcon size="sm" color="text-subdued" icon="arrow-up" /> : null}
                                    {sortOrder === TableSortOrder.NAME_DESC ? <QuiIcon size="sm" color="text-subdued" icon="arrow-down" /> : null}
                                </button>
                            </th>

                            {/*
                             * TODO component should only accept 1-2 props-- 'data' and 'columns'
                             */}
                            {!isCustomEntity && (
                                <th>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            if (sortOrder === TableSortOrder.UPDATED_NEWEST) {
                                                setSortOrder(TableSortOrder.UPDATED_OLDEST);
                                            } else {
                                                setSortOrder(TableSortOrder.UPDATED_NEWEST);
                                            }
                                        }}
                                    >
                                        <span>Last Updated</span>
                                        {sortOrder === TableSortOrder.UPDATED_NEWEST && <QuiIcon size="sm" color="text-subdued" icon="arrow-up" />}
                                        {sortOrder === TableSortOrder.UPDATED_OLDEST && <QuiIcon size="sm" color="text-subdued" icon="arrow-down" />}
                                    </button>
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 ? (
                            <>
                                {data.map((item) => {
                                    const linkTo = item.type === 'dataset' ? `/datasets/${item.id}` : `/pipelines/${item.id}`;
                                    const date = item.lastUpdated;

                                    return (
                                        <tr key={item.id}>
                                            <td
                                                {...(isCustomEntity && {
                                                    colSpan: 2,
                                                })}
                                            >
                                                <QuiBox display="flex" alignItems="center" justifyContent="space-between" padding="none md">
                                                    <Link className={styles.nameLink} to={linkTo}>
                                                        {item.type === 'dataset' ? (
                                                            <QuiBadge size="xs" iconLeft="database" variant="brand-purple" />
                                                        ) : (
                                                            <QuiBadge size="xs" iconLeft="git-branch" variant="brand-purple" />
                                                        )}
                                                        <QuiFlexBoxColumn>
                                                            <span>{item.name}</span>
                                                            <QuiBox color="text-subdued" text="text-xs">
                                                                {item.type === 'dataset' ? 'Dataset' : 'Pipeline'}
                                                            </QuiBox>
                                                        </QuiFlexBoxColumn>
                                                    </Link>

                                                    {/*
                                                     *TODO: probably state should sync with the auto enable state?
                                                     */}
                                                    {isCustomEntity && <QuiSwitchField name={`${item.type}.${item.id}.enabled`} />}
                                                </QuiBox>
                                            </td>

                                            {!isCustomEntity && (
                                                <td>
                                                    <QuiBox text="text-xs" color="text-base">{`Edited ${dayjs(date).fromNow()} by ${
                                                        user?.displayName ?? user?.userName
                                                    }`}</QuiBox>
                                                </td>
                                            )}
                                        </tr>
                                    );
                                })}
                            </>
                        ) : (
                            <tr>
                                <td colSpan={2}>
                                    <QuiBox padding="md" text="text-sm" color="text-subdued" textAlign="center">
                                        {queryString.trim().length === 0 ? 'No projects found' : `No projects match "${queryString}"`}
                                    </QuiBox>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </QuiBox>
        </QuiFlexBoxColumn>
    );
}
