import { useGetQuery } from '@/hooks/useGetQuery';
import { client } from '@/services/HTTPClient';
import { LabelCustomList } from '@/types/api_request_record';
import { CustomEntity } from '@/types/custom-entities';
import { useQuiModal } from '@tonicai/ui-quinine';
import mutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { ENTITIES_ENDPOINT } from './CustomEntities';
import { CustomEntityModal } from './CustomEntityModal';

type Props = { editEntity?: CustomEntity; formModal: ReturnType<typeof useQuiModal> };

type CustomEntityFormData = {
    dataset: Record<string, { enabled: boolean }>;
    datasetIds: string[];
    description?: string;
    displayName: string;
    enabledAutomatically: boolean;
    entries: LabelCustomList;
    id: string;
    name: string;
    pipeline: Record<string, { enabled: boolean }>;
    pipelineIds: string[];
    startLightRescan: boolean;
    userId: string;
};

export default function CustomEntityForm({ editEntity, formModal }: Props) {
    const customEntityQuery = useGetQuery<CustomEntity[]>(client, ENTITIES_ENDPOINT, {}, { enabled: false });
    return (
        <Form<CustomEntityFormData>
            mutators={{ ...mutators }}
            onSubmit={async (data) => {
                const datasetIds = Object.entries(data.dataset)
                    .filter(([, value]) => !!value.enabled)
                    .map(([id]) => id);

                const pipelineIds = Object.entries(data.pipeline)
                    .filter(([, value]) => !!value.enabled)
                    .map(([id]) => id);

                const regexes = data.entries?.regexes.filter(Boolean);
                const formData = {
                    datasetIds,
                    pipelineIds,
                    entries: { regexes },
                    displayName: data.displayName,
                    name: data.name,
                    description: data.description,
                    enabledAutomatically: data.enabledAutomatically ?? false,
                    startLightRescan: data.startLightRescan,
                } as CustomEntityFormData;
                if (editEntity) {
                    await client.put(ENTITIES_ENDPOINT, {
                        ...formData,
                        id: editEntity.id,
                    });
                } else {
                    await client.post(ENTITIES_ENDPOINT, formData);
                }
                return customEntityQuery.refetch();
            }}
            initialValues={
                {
                    ...{
                        pipeline: editEntity ? Object.fromEntries(editEntity?.pipelineIds.map((id) => [id, { enabled: true }])) : {},
                        dataset: editEntity ? Object.fromEntries(editEntity?.datasetIds.map((id) => [id, { enabled: true }])) : {},
                        entries: { regexes: [''] },
                    },
                    ...editEntity,
                } as CustomEntityFormData
            }
        >
            {() => <CustomEntityModal modal={formModal} />}
        </Form>
    );
}
