import { useFloating, autoUpdate, offset, flip, shift, useHover, useFocus, useDismiss, useRole, useInteractions } from '@floating-ui/react';
import { useFadeInOpenState } from '@/hooks/useFadeInOpenState';
import styles from './Pulsar.module.scss';
import { ReactNode } from 'react';

interface IProps {
    children: ReactNode;
    hide?: boolean;
}

export function Pulsar({ children, hide }: IProps) {
    const [open, _setOpen] = useFadeInOpenState<boolean>(false, 'open', 500);

    const setOpen = (val: boolean) => {
        _setOpen(val);
    };

    const { refs, context } = useFloating({
        open: open,
        onOpenChange: setOpen,
        placement: 'right-end',
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(4),
            flip({
                fallbackAxisSideDirection: 'start',
            }),
            shift(),
        ],
    });

    const hover = useHover(context, { move: true });
    const focus = useFocus(context);
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: 'tooltip' });

    const { getReferenceProps } = useInteractions([hover, focus, dismiss, role]);

    if (hide) {
        return children;
    }

    return (
        <div className={styles.root} ref={refs.setReference} {...getReferenceProps}>
            {children}
            <svg className={styles.pulsar} viewBox="0 0 40 40">
                <circle id="bkg" cx="20" cy="20" r="12" />
                <circle id="inner" cx="20" cy="20" r="6" />
                <circle id="middle" cx="20" cy="20" r="9" />
                <circle id="outer" cx="20" cy="20" r="12" />
            </svg>
        </div>
    );
}
