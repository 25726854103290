import { AxiosInstance } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';

export const mutationMethods = {
    PUT: 'put',
    POST: 'post',
    DELETE: 'delete',
};
export default function useMutate<TResponse, TError, TBody>({
    client,
    url,
    method,
    config,
    headers = {},
}: {
    client: AxiosInstance;
    url: string;
    method: (typeof mutationMethods)[keyof typeof mutationMethods];
    config?: UseMutationOptions<TResponse, TError, TBody>;
    headers?: Record<string, string>;
}) {
    return useMutation<TResponse, TError, TBody>(
        async (data: TBody) => {
            if (!client) {
                return null;
            }

            return (
                await client(url, {
                    method,
                    data,
                    headers,
                })
            ).data;
        },
        { ...config }
    );
}
