import CustomEntityActivationSwitch from '@/pages/Dataset/CustomEntityActivationSwitch';
import { CustomEntityLabel } from '@/pages/Dataset/CustomEntityRow';
import { CustomEntity } from '@/types/custom-entities';
import { QuiBox, QuiButton, QuiIcon, QuiIconEnum, QuiPopover, useQuiModal } from '@tonicai/ui-quinine';
import { useGetQuery } from '@/hooks/useGetQuery';
import { PersonAgeGeneratorMetadataField } from '@/pages/Dataset/PersonAgeGeneratorMetadataField';
import { client } from '@/services/HTTPClient';
import { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import { Dataset, DatasetFormState, DatasetPiiInfo, GeneratorMetadataProps, PiiTypeEnum } from '@/types';
import { DateTimeGeneratorMetadataField } from './DateTimeGeneratorMetadataField';
import { LocationGeneratorMetadataField } from './LocationGeneratorMetadataField';
import { NameGeneratorMetadataField } from './NameGeneratorMetadataField';
import { RedactionTypeExamples } from './RedactionTypeExamples';
import { RegexListFieldModal } from './RegexListFieldModal';
import useEntityOptions from './useEntityOptions';
import CustomEntityForm from '../CustomEntities/CustomEntityForm';
import { ENTITIES_ENDPOINT } from '../CustomEntities/CustomEntities';
import { aggregatePiiTypeCounts, aggregatePiiTypeExamples, getPiiTypeInfo } from './utils';

type RedactionTypeOptionsProps = Readonly<{
    piiType: PiiTypeEnum;
    dataset: Dataset;
    customEntity: CustomEntity | undefined;
}>;

export const piiTypeMetadataComponents: Partial<Record<PiiTypeEnum, React.FC<GeneratorMetadataProps>>> = {
    [PiiTypeEnum.DOB]: DateTimeGeneratorMetadataField,
    [PiiTypeEnum.DATE_TIME]: DateTimeGeneratorMetadataField,
    [PiiTypeEnum.PERSON_AGE]: PersonAgeGeneratorMetadataField,
    [PiiTypeEnum.LOCATION]: LocationGeneratorMetadataField,
    [PiiTypeEnum.LOCATION_ADDRESS]: LocationGeneratorMetadataField,
    [PiiTypeEnum.LOCATION_ZIP]: LocationGeneratorMetadataField,
    [PiiTypeEnum.LOCATION_STATE]: LocationGeneratorMetadataField,
    [PiiTypeEnum.LOCATION_CITY]: LocationGeneratorMetadataField,
    [PiiTypeEnum.PERSON]: NameGeneratorMetadataField,
    [PiiTypeEnum.NAME_GIVEN]: NameGeneratorMetadataField,
    [PiiTypeEnum.NAME_FAMILY]: NameGeneratorMetadataField,
};

export function NerEntityLabel({ piiType }: { piiType: PiiTypeEnum }) {
    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <QuiIcon icon={getPiiTypeInfo(piiType)?.icon ?? 'help-circle'} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className={'pii-type-label'}>{getPiiTypeInfo(piiType)?.label}</span>
                <span className={'pii-type-description'}>{getPiiTypeInfo(piiType)?.description}</span>
            </div>
        </div>
    );
}

export function RedactionTypeOptions({ piiType, dataset, customEntity }: RedactionTypeOptionsProps) {
    const customEntityQuery = useGetQuery<CustomEntity[]>(client, ENTITIES_ENDPOINT);
    const [isPreviewPopoverOpen, setIsPreviewPopoverOpen] = useState<boolean>(false);

    const form = useForm<DatasetFormState>();
    const formModal = useQuiModal();
    const isCustomEntityType = piiType.startsWith('CUSTOM_') && customEntity && customEntityQuery;
    const { data: datasetPiiInfo } = useGetQuery<DatasetPiiInfo>(client, `/api/dataset/${dataset.id}/pii_info`, {}, { enabled: false });

    // There is a documented issue with stale form state when dynamically
    // altering a form's fields
    //
    // A repro of the issue is available at the codesandbox in this comment:
    // https://github.com/final-form/react-final-form/issues/984#issuecomment-1305448553
    //
    // The workaround is from here:
    // https://github.com/final-form/final-form/issues/151#issuecomment-425867172
    //
    // If this winds up introducing another bug, then we can try rendering all
    // possible fields at the first initialization and hiding the ones which
    // are empty
    useEffect(() => {
        const isNewFormField = !form.getState().values.generatorSetup[piiType];
        if (isNewFormField) {
            form.setConfig('keepDirtyOnReinitialize', false);
            form.reset();
            form.setConfig('keepDirtyOnReinitialize', true);
        }
    }, [form, piiType]);
    const { EntityOptionButtons, EntityOptionMetadata } = useEntityOptions({
        piiType,
    });
    const piiTypeExamples = datasetPiiInfo ? aggregatePiiTypeExamples(datasetPiiInfo, customEntity?.name ?? piiType) : [];
    const piiTypeCount = datasetPiiInfo ? aggregatePiiTypeCounts(datasetPiiInfo, customEntity?.name ?? piiType) : 0;

    return (
        <QuiBox key={piiType} gap="sm" flexDirection="column" display="flex" bg="background-base" border="stroke-base" borderRadius="md" padding="md">
            <QuiBox display="flex" alignItems="center" justifyContent="space-between">
                {isCustomEntityType ? (
                    <CustomEntityLabel displayName={customEntity?.displayName} description={customEntity.description} />
                ) : (
                    <NerEntityLabel piiType={piiType} />
                )}

                <QuiBox display="flex" gap="sm" alignItems="center">
                    <QuiBox display="flex" gap="sm" alignItems="center">
                        <QuiPopover
                            isOpen={isPreviewPopoverOpen}
                            onClose={() => setIsPreviewPopoverOpen(false)}
                            hideArrow={true}
                            placement={'bottom-start'}
                            content={<RedactionTypeExamples examples={piiTypeExamples} piiType={piiType} totalExamples={piiTypeCount} />}
                        >
                            <QuiButton
                                iconRight={isPreviewPopoverOpen ? QuiIconEnum.X : QuiIconEnum.Eye}
                                size={'sm'}
                                variant={isPreviewPopoverOpen ? 'outline-primary' : 'outline-default'}
                                onClick={() => (isPreviewPopoverOpen ? setIsPreviewPopoverOpen(false) : setIsPreviewPopoverOpen(true))}
                            >
                                {piiTypeCount}
                            </QuiButton>
                        </QuiPopover>
                        <EntityOptionButtons />
                    </QuiBox>
                    {isCustomEntityType ? (
                        <>
                            <QuiButton iconLeft={QuiIconEnum.Settings} size="sm" onClick={formModal.open} />
                            <CustomEntityActivationSwitch entity={customEntity} customEntityQuery={customEntityQuery} />
                        </>
                    ) : (
                        <>
                            <RegexListFieldModal initialEntityType={piiType} listType="labelAllowLists" buttonIcon={QuiIconEnum.PlusCircle} />
                            <RegexListFieldModal initialEntityType={piiType} listType="labelBlockLists" buttonIcon={QuiIconEnum.Slash} />
                        </>
                    )}
                </QuiBox>
            </QuiBox>
            <EntityOptionMetadata />
            <CustomEntityForm editEntity={customEntity} formModal={formModal} />
        </QuiBox>
    );
}
