import useChecklistState from '@/components/GettingStartedChecklist/useChecklistState';
import { ForgotPasswordPage } from '@/pages/ForgotPassword/ForgotPasswordPage';
import { RequestExplorer } from '@/pages/RequestExplorer/RequestExplorer';
import { ResetPasswordPage } from '@/pages/ResetForgottenPassword/ResetPasswordPage';
import { Route, Routes } from 'react-router-dom';
import { AppLayout } from '../AppLayout/AppLayout';
import { AuthGuard } from '../AuthGuard/AuthGuard';
import { FullScreenLoading } from '../FullScreenLoading/FullScreenLoading';
import { useEffect, useRef, useState } from 'react';
import { useAtomValue } from 'jotai';
import {
    authInitializedAtom,
    refreshTokenTimeoutAtom,
    initAuth,
    refreshAccessToken,
    ssoCallbackDataAtom,
    setSSOCallbackData,
    ssoSignIn,
    currentUserAtom,
} from '@/stores';
import { fetchEnvironmentModels, fetchEnvironmentVariables, useIsHostedProd } from '@/stores';
import { useQuiToasts } from '@tonicai/ui-quinine';
import { useDPRMonitor } from '@/hooks/useDPRMonitor';
import { useGoogleAnalytics } from '@/hooks/useGoogleAnalytics';
import { loadIntercomOnHosted } from '@/hosted_tracking';
import { hubspot } from '@/instrumentation/hubspot';
import { syft } from '@/instrumentation/syft';

// Pages
import { instrumentation } from '@/instrumentation/instrumentation';
import CustomEntities from '@/pages/CustomEntities/CustomEntities';
import { OnboardingSurvey } from '@/pages/OnboardingSurvey/OnboardingSurveyPage';
import { authResponseAtom, globalStore, hashEmail } from '@/stores';
import { Billing } from '@/pages/Billing/Billing';
import { Dataset } from '@/pages/Dataset/Dataset';
import { Datasets } from '@/pages/Datasets/Datasets';
import { DatasetSettings } from '@/pages/DatasetSettings/DatasetSettings';
import { FileParseJob } from '@/pages/FileParseJob/FileParseJob';
import { FileParseJobFile } from '@/pages/FileParseJobFile/FileParseJobFile';
import { Help } from '@/pages/Help/Help';
import { Home } from '@/pages/Home/Home';
import { NotFound } from '@/pages/NotFound/NotFound';
import { ParseJob } from '@/pages/ParseJob/ParseJob';
import { ParseJobs } from '@/pages/ParseJobs/ParseJobs';
import { ParseSettings } from '@/pages/ParseSettings/ParseSettings';
import { ResetPassword } from '@/pages/ResetPassword/ResetPassword';
import { SignIn } from '@/pages/SignIn/SignIn';
import { SignUp } from '@/pages/SignUp/SignUp';
import { SSOCallback } from '@/pages/SSOCallback/SSOCallback';
import { SystemSettings } from '@/pages/SystemSettings/SystemSettings';
import { ParseJobConfigGuard } from '../ParseJobConfigGuard/ParseJobConfigGuard';
import { fetchVersion } from '@/stores/version';
import { DatasetGuard } from '../DatasetGuard/DatasetGuard';
import { QueryClient, QueryClientProvider } from 'react-query';

export function App() {
    const authInitialized = useAtomValue(authInitializedAtom);
    const refreshTokenTimeout = useAtomValue(refreshTokenTimeoutAtom);
    const ssoCallbackData = useAtomValue(ssoCallbackDataAtom);
    const user = useAtomValue(currentUserAtom);
    const addToast = useQuiToasts();
    const [initialized, setInitialized] = useState(false);
    const [hasChecklistBeenChecked, setHasChecklistBeenChecked] = useState(false);
    const isHostedProd = useIsHostedProd();
    const initializedIntercomRef = useRef(false);
    const checklistState = useChecklistState();
    const queryClient = new QueryClient();
    useGoogleAnalytics();

    // Init hubspot as soon as the app is mounted.
    useEffect(() => {
        hubspot.init(window.location.pathname);
        syft.init();
    }, []);

    useEffect(() => {
        if (hasChecklistBeenChecked) return;

        const isCompleted = checklistState.testPlayground && checklistState.installSDK && checklistState.generateAPIKey && checklistState.testSDK;
        if (isCompleted && checklistState.showGettingStarted) {
            checklistState.setShowGettingStarted(false);
            instrumentation.gettingStartedChecklist('getting-started-checklist-completed');
        }
        setHasChecklistBeenChecked(true);
    }, [hasChecklistBeenChecked, checklistState]);

    // Monitor when the user changes their browser zoom level
    // or moves this window to a different monitor with a different DPR.
    // We should not use this hook anymore than a total of _once_ per app.
    useDPRMonitor();

    useEffect(() => {
        if (initialized) return;

        Promise.all([initAuth(), loadIntercomOnHosted(), fetchEnvironmentVariables(), fetchEnvironmentModels(), fetchVersion()])
            .catch(console.error)
            .finally(() => {
                setInitialized(true);
            });
    }, [initialized]);

    useEffect(() => {
        if (ssoCallbackData === null) return;

        if (ssoCallbackData.type === 'error') {
            addToast({
                title: `Error signing in: ${
                    ssoCallbackData.errorDescription?.length || 0 > 128
                        ? ssoCallbackData.errorDescription?.substring(0, 125) + '...'
                        : ssoCallbackData.errorDescription
                }`,
                variant: 'danger',
            });
            console.error(`Error signing in: ${ssoCallbackData.errorDescription}`);
            return;
        }

        ssoSignIn(ssoCallbackData.token, ssoCallbackData.provider, ssoCallbackData.searchParams).finally(() => {
            setSSOCallbackData(null);
        });
    }, [ssoCallbackData, addToast]);

    useEffect(() => {
        if (refreshTokenTimeout === null) {
            return;
        }

        const timeout = window.setTimeout(() => {
            refreshAccessToken();
        }, refreshTokenTimeout);

        return () => {
            window.clearTimeout(timeout);
        };
    }, [refreshTokenTimeout]);

    useEffect(() => {
        if (isHostedProd && 'Intercom' in window && typeof window['Intercom'] === 'function' && initializedIntercomRef.current === false) {
            initializedIntercomRef.current = true;
            window?.Intercom('boot', {
                app_id: 'l9jilb9x',
                email: typeof user?.userName === 'string' ? user.userName : undefined,
            });
        }
    }, [isHostedProd, user]);

    const authResponse = globalStore.get(authResponseAtom);
    useEffect(() => {
        if (authResponse?.amplitudeApiKey && authResponse.amplitudeApiUrl && authResponse.user?.userName) {
            const email = isHostedProd ? authResponse.user?.userName : hashEmail(authResponse.user?.userName);
            instrumentation.init(authResponse.amplitudeApiKey, authResponse.amplitudeApiUrl, email);
        }
    }, [authResponse, isHostedProd]);

    // Show a loading screen until we've checked if the user is authenticated &
    // completed fetching any initial data necessary for rendering the app (e.g. env
    // vars or any other data that's stored on the server).
    if (!authInitialized || !initialized) {
        return <FullScreenLoading />;
    }

    return (
        <QueryClientProvider client={queryClient}>
            <Routes>
                <Route path="/signup/forgotpassword" element={<ForgotPasswordPage />} />
                <Route path="/signup/resetpassword" element={<ResetPasswordPage />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/passwordreset" element={<ResetPassword />} />
                <Route path="/sso/callback/:ssoProvider" element={<SSOCallback />} />
                <Route element={<AuthGuard />}>
                    <Route path="/onboarding-survey" element={<OnboardingSurvey />} />
                    <Route element={<AppLayout />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/pipelines" element={<ParseJobs />} />
                        <Route path="/pipelines/:parseJobConfigId" element={<ParseJobConfigGuard />}>
                            <Route index element={<ParseJob />} />
                            <Route path="settings" element={<ParseSettings />} />
                            <Route path="runs/:fileParseJobId" element={<FileParseJob />} />
                            <Route path="runs/:fileParseJobId/files/:fileParseResultId" element={<FileParseJobFile />} />
                        </Route>
                        <Route path="/datasets" element={<Datasets />} />
                        <Route path="/datasets/:datasetId" element={<DatasetGuard />}>
                            <Route index element={<Dataset />} />
                            <Route path="settings" element={<DatasetSettings />} />
                        </Route>
                        <Route path="/billing" element={<Billing />} />
                        <Route path="/system-settings" element={<SystemSettings />} />
                        <Route path="/help" element={<Help />} />
                        <Route path="/request-explorer" element={<RequestExplorer />} />
                        <Route path="/request-explorer/:requestId" element={<RequestExplorer />} />
                        <Route path="/custom-entities" element={<CustomEntities />} />
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Route>
            </Routes>
        </QueryClientProvider>
    );
}
