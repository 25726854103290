import { FineTunedRule } from '@/components/GettingStartedPlayground/CustomizeNer';
import { PiiTypeToLabel } from '@/pages/Dataset/utils';
import { EntityGeneratorTypeSelection } from './EntityGeneratorTypeSelection';
import { PiiTypeEnum, PiiTypeGeneratorState } from '@/types';
import { QuiBox, QuiButton, QuiIcon, QuiPopover } from '@tonicai/ui-quinine';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { useState } from 'react';
import { generatorSetupAtom, previewDataAtom, previewStatusAtom, updateGeneratorSetup } from './state';
import styles from './styles.module.scss';
import { usePlaygroundContext } from './usePlaygroundContext';
import { PreviewWrapper } from './PreviewWrapper';

type PreviewProps = {
    rules: FineTunedRule[];
};

export function Preview({ rules }: PreviewProps) {
    const storeOptions = usePlaygroundContext();
    const generatorSetup = useAtomValue(generatorSetupAtom, storeOptions);

    const responseStatus = useAtomValue(previewStatusAtom, storeOptions);
    const previewData = useAtomValue(previewDataAtom, storeOptions) ?? [];

    const [clickedLabelId, setClickedLabelId] = useState<string | null>(null);

    const setGeneratorState = (newState: PiiTypeGeneratorState, entityLabel?: string) => {
        if (entityLabel == null) return;
        const newGeneratorSetup = new Map(generatorSetup);
        newGeneratorSetup.set(entityLabel as PiiTypeEnum, newState);
        updateGeneratorSetup(storeOptions.store, newGeneratorSetup);
    };

    const styleClasses = {
        Off: styles.previewRedactionInOffState,
        Redaction: styles.previewRedaction,
        Synthesis: styles.previewSynthesis,
    };

    if (typeof previewData === 'string') {
        return (
            <PreviewWrapper previewData={previewData} rules={rules} responseStatus={responseStatus}>
                {previewData}
            </PreviewWrapper>
        );
    }

    return (
        <PreviewWrapper previewData={previewData} responseStatus={responseStatus} rules={rules}>
            {previewData.map((p) => (
                <p key={p.id}>
                    {p.chunks.length === 0 && <br />}
                    {p.chunks.map((c) => {
                        if (c.label && c.score) {
                            const operation = generatorSetup.get(c.label as PiiTypeEnum) || 'Redaction';
                            const value = operation === 'Synthesis' ? c.syntheticText : operation === 'Redaction' ? c.label : c.text;

                            return (
                                <QuiPopover
                                    keepChildFocusOnOpen={false}
                                    key={c.id}
                                    padding={'sm'}
                                    isOpen={clickedLabelId === c.id}
                                    content={
                                        <EntityGeneratorTypeSelection
                                            currentValue={generatorSetup.get(c.label as PiiTypeEnum) || 'Redaction'}
                                            setGeneratorSetup={(v) => setGeneratorState(v, c.label)}
                                            label_description={PiiTypeToLabel[c.label].description}
                                        />
                                    }
                                    variant="default"
                                    onClose={() => setClickedLabelId(null)}
                                >
                                    <QuiButton
                                        onClick={() => setClickedLabelId(c.id)}
                                        variant={'minimal'}
                                        key={c.id}
                                        className={classNames(styleClasses[operation], styles.labelButton, 'fs-mask')}
                                    >
                                        <QuiBox>
                                            <QuiIcon
                                                size={'sm'}
                                                icon={operation === 'Synthesis' ? 'shuffle' : operation === 'Redaction' ? 'eye-off' : 'eye'}
                                            />
                                        </QuiBox>
                                        &nbsp;
                                        {value}
                                    </QuiButton>
                                </QuiPopover>
                            );
                        } else {
                            return (
                                <span className={'fs-mask'} key={c.id}>
                                    {c.text}
                                </span>
                            );
                        }
                    })}
                </p>
            ))}
        </PreviewWrapper>
    );
}
