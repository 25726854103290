import { CodeBlock } from '@/components/CodeBlock/CodeBlock';
import { DeleteDialog } from '@/components/DeleteDialog/DeleteDialog';
import { PageTitle } from '@/components/PageTitle/PageTitle';
import tableStyles from '@/components/Table/Table.module.scss';
import { useGetQuery } from '@/hooks/useGetQuery';
import useMutate, { mutationMethods } from '@/hooks/useMutate';
import { client } from '@/services/HTTPClient';
import { CustomEntity } from '@/types/custom-entities';
import { copyToClipboard } from '@/utils';
import {
    QuiBadge,
    QuiBox,
    QuiButton,
    QuiCell,
    QuiFlexBoxColumn,
    QuiGridLayout,
    QuiIconEnum,
    QuiTooltip,
    useQuiModal,
    useQuiToasts,
} from '@tonicai/ui-quinine';
import { useState } from 'react';
import CustomEntityForm from './CustomEntityForm';

export const ENTITIES_ENDPOINT = '/api/custom_pii_entities';
export default function CustomEntities() {
    const formModal = useQuiModal();
    const [editEntity, setEditEntity] = useState<CustomEntity>();
    const [deletingEntity, setDeletingEntity] = useState<CustomEntity>();
    const customEntityQuery = useGetQuery<CustomEntity[]>(client, ENTITIES_ENDPOINT);
    const isEmptyState = customEntityQuery.data && customEntityQuery.data.length === 0;
    const addToast = useQuiToasts();
    const deleteModal = useQuiModal();
    const deleteEntity = useMutate({
        client,
        url: `${ENTITIES_ENDPOINT}?name=${deletingEntity?.name}`,
        method: mutationMethods.DELETE,
    });

    return (
        <QuiBox maxWidth="wide" flexGrow="1" padding="md" display="flex" flexDirection="column" gap="lg">
            <QuiGridLayout gap="md">
                <QuiCell width={3}>
                    <QuiBox display="flex" flexDirection="column" gap="lg" maxWidth="tablet">
                        <QuiFlexBoxColumn
                            style={{
                                minHeight: 'calc(100vh - 44px - 2rem)',
                            }}
                            gap="lg"
                        >
                            <PageTitle
                                title={
                                    <QuiBox display="flex" alignItems="center" gap="sm" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                                        <QuiBadge size="lg" variant="brand-purple">
                                            (.*)
                                        </QuiBadge>
                                        Custom Entity Types
                                    </QuiBox>
                                }
                            >
                                Create custom entity types to use in your datasets, pipelines, or from the SDK.
                            </PageTitle>

                            <div>
                                <QuiButton
                                    variant="brand-purple"
                                    onClick={() => {
                                        setEditEntity(undefined);
                                        formModal.open();
                                    }}
                                >
                                    Create Custom Entity Type
                                </QuiButton>
                            </div>
                        </QuiFlexBoxColumn>
                    </QuiBox>
                </QuiCell>

                <QuiCell width={9}>
                    <QuiBox margin="lg" bg="background-base" border="stroke-filled" borderRadius="md" text="text-sm" className={tableStyles.wrapper}>
                        <table className={tableStyles.table}>
                            <thead style={{ position: 'sticky', top: '0px' }}>
                                <tr>
                                    <th>
                                        <QuiBox text="text-sm" color="text-subdued" weight={'normal'} style={{ whiteSpace: 'nowrap' }}>
                                            Name
                                        </QuiBox>
                                    </th>
                                    <th>
                                        <QuiBox text="text-sm" color="text-subdued" weight={'normal'}>
                                            Regular Expression Value
                                        </QuiBox>
                                    </th>
                                    <th>
                                        <QuiBox text="text-sm" color="text-subdued" weight={'normal'}>
                                            Activated for
                                        </QuiBox>
                                    </th>
                                    <th />
                                </tr>
                            </thead>

                            <tbody>
                                {isEmptyState && !customEntityQuery.isLoading && (
                                    <tr>
                                        <td colSpan={4}>
                                            <QuiBox padding="xl" color="text-base" textAlign="center">
                                                No custom entities found
                                            </QuiBox>
                                        </td>
                                    </tr>
                                )}
                                {customEntityQuery.data &&
                                    customEntityQuery.data.map((entity) => (
                                        <tr key={entity.id}>
                                            <td>{entity.displayName}</td>
                                            <td
                                                style={{
                                                    maxWidth: '0px',
                                                    width: '100%',
                                                }}
                                            >
                                                <QuiBox gap="sm" display="flex">
                                                    {entity.entries.regexes?.map((regex) => (
                                                        <CodeBlock
                                                            language="javascript"
                                                            key={`${entity.id}-${regex}`}
                                                            code={regex}
                                                            preStyle={{
                                                                textOverflow: 'ellipsis',
                                                                overflow: 'hidden',
                                                            }}
                                                            style={{
                                                                padding: '.25em',
                                                                whiteSpace: 'nowrap',
                                                                textOverflow: 'ellipsis',
                                                                overflow: 'hidden',
                                                            }}
                                                        />
                                                    ))}
                                                </QuiBox>
                                            </td>
                                            <td>
                                                <QuiBox gap="sm" display="flex">
                                                    <QuiTooltip
                                                        content={`Enabled for ${entity.datasetIds?.length ?? 0} dataset${
                                                            entity.datasetIds?.length !== 1 ? 's' : ''
                                                        }`}
                                                    >
                                                        <QuiBadge iconLeft={QuiIconEnum.Database} variant="neutral">
                                                            {String(entity.datasetIds?.length ?? 0)}
                                                        </QuiBadge>
                                                    </QuiTooltip>
                                                    <QuiTooltip
                                                        content={`Enabled for ${entity.pipelineIds?.length ?? 0} pipeline${
                                                            entity.pipelineIds?.length !== 1 ? 's' : ''
                                                        }`}
                                                    >
                                                        <QuiBadge iconLeft={QuiIconEnum.GitBranch} variant="neutral">
                                                            {String(entity.pipelineIds?.length ?? 0)}
                                                        </QuiBadge>
                                                    </QuiTooltip>
                                                </QuiBox>
                                            </td>
                                            <td>
                                                <QuiBox display="flex" gap="sm">
                                                    <QuiTooltip content="Copy custom entity type identifier">
                                                        <QuiButton
                                                            size="sm"
                                                            iconLeft={QuiIconEnum.Clipboard}
                                                            onClick={() => {
                                                                copyToClipboard(entity.name);
                                                            }}
                                                        />
                                                    </QuiTooltip>
                                                    <QuiTooltip content="Edit custom entity">
                                                        <QuiButton
                                                            size="sm"
                                                            iconLeft={QuiIconEnum.Edit2}
                                                            onClick={() => {
                                                                setEditEntity(entity);
                                                                formModal.open();
                                                            }}
                                                        />
                                                    </QuiTooltip>
                                                    <QuiTooltip content="Delete custom entity">
                                                        <QuiButton
                                                            size="sm"
                                                            iconLeft={QuiIconEnum.Trash2}
                                                            onClick={() => {
                                                                setDeletingEntity(entity);
                                                                deleteModal.open();
                                                            }}
                                                        />
                                                    </QuiTooltip>
                                                </QuiBox>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </QuiBox>
                </QuiCell>
            </QuiGridLayout>

            {deletingEntity && (
                <DeleteDialog
                    title="Delete Entity Confirmation"
                    isOpen={deleteModal.isOpen}
                    closeDialog={deleteModal.close}
                    deleteMethod={async () => {
                        try {
                            await deleteEntity.mutateAsync({});
                            addToast({
                                title: 'Dataset deleted successfully',
                                variant: 'positive',
                            });
                        } catch (e) {
                            addToast({
                                title: 'Dataset could not be deleted successfully',
                                variant: 'danger',
                            });
                        } finally {
                            customEntityQuery.refetch();
                        }
                        setDeletingEntity(undefined);
                    }}
                    itemType={'entity'}
                    itemName={deletingEntity!.displayName}
                />
            )}

            <CustomEntityForm editEntity={editEntity} formModal={formModal} />
        </QuiBox>
    );
}
