export type Rectangle = {
    x: number;
    y: number;
    width: number;
    height: number;
};

export type PdfRectangle = Rectangle & {
    pageNumber: number;
};

export enum PdfRedactionType {
    None = 'None',
    AutomaticRedaction = 'AutomaticRedaction',
    AddRedaction = 'AddRedaction',
    RemoveRedaction = 'RemoveRedaction',
}

export type PdfRedactionArea = PdfRectangle & {
    id?: string; //if undefined and sent to backend, a new PdfRedactionArea will be generated
};

export type PdfDimensions = {
    width: number;
    height: number;
    orientation?: number;
};

export type PdfCoordinates = {
    x: number;
    y: number;
};

export type FileRedactions = {
    templateId?: string;
    redactions: PdfRedaction[];
};

export type PdfRedaction = {
    id?: string; //if undefined and sent to backend, a new PdfRedaction will be generated
    piiTypeLabel?: string;
    source?: PdfAndImageRedactionSource;
    type: PdfRedactionType;
    areas: PdfRedactionArea[];
    isSelected?: boolean;
    exampleRedaction?: string;
};

export type PdfRedactionTemplate = {
    id: string;
    name: string;
    datasetId: string;
    redactions: PdfRedaction[];
};

export type PdfWord = PdfRectangle & {
    content: string;
    index: number;
};

export type PdfRedactionTemplateBase = {
    id: string;
    name: string;
    datasetId: string;
};

export enum PdfAndImageRedactionSource {
    Unknown = 'Unknown',
    DetectedDuringJob = 'DetectedDuringJob',
    AttachedToFile = 'AttachedToFile',
    AttachedToTemplate = 'AttachedToTemplate',
}

export type ManualPdfRedactionType = PdfRedactionType.AddRedaction | PdfRedactionType.RemoveRedaction;
