import { CustomizeNer, FineTunedRule } from '@/components/GettingStartedPlayground/CustomizeNer';
import {
    PlaygroundStatus,
    PreviewData,
    fileAtom,
    blockTextContentEmptyAtom,
    languageAtom,
} from '@/components/GettingStartedPlayground/Playground/state';
import styles from '@/components/GettingStartedPlayground/Playground/styles.module.scss';
import { datasetCreationStatusAtom } from '@/stores';
import { QuiBox, QuiText, QuiIcon, QuiSpinner, QuiButton, QuiPopover } from '@tonicai/ui-quinine';
import { ReactNode, useState } from 'react';
import { usePlaygroundContext } from './usePlaygroundContext';
import { useAtomValue } from 'jotai';
import classNames from 'classnames';

type PreviewWrapperProps = Readonly<{
    children: ReactNode;
    rules: FineTunedRule[];
    responseStatus: PlaygroundStatus;
    previewData: string | PreviewData[] | undefined;
    onClear?: () => void;
    hideCustomizeNerResults?: boolean;
    handleCreateDataset?: () => void;
}>;

type InternalWrapperPropes = {
    children: ReactNode;
    rules: FineTunedRule[];
    onClear?: () => void;
    hideCustomizeNerResults?: boolean;
    handleCreateDataset?: () => void;
};

const rtlLanguages = new Set<string>(['ar', 'he', 'fa', 'ur', 'az']);

function InternalWrapper({ children, rules, onClear, hideCustomizeNerResults, handleCreateDataset }: InternalWrapperPropes) {
    const language = useAtomValue(languageAtom);
    const [fakeDownloadOpen, setFakeDownloadOpen] = useState(false);

    const datasetCreationStatus = useAtomValue(datasetCreationStatusAtom);

    return (
        <QuiBox display={'flex'} flexDirection={'column'}>
            <QuiBox className={styles.previewColumnTopBar} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <QuiBox display={'flex'} gap={'sm'}>
                    <QuiIcon icon={'file-text'} />
                    <QuiText>Results</QuiText>
                </QuiBox>
                <QuiBox display={'flex'} gap={'xs'}>
                    {!hideCustomizeNerResults && <CustomizeNer rules={rules} />}
                    {handleCreateDataset && (
                        <QuiPopover
                            content={
                                <QuiBox gap={'md'} display={'flex'} flexDirection={'column'}>
                                    <QuiText size={'text-md'}>To download redacted files</QuiText>
                                    <QuiButton
                                        spinner={datasetCreationStatus === 'loading'}
                                        iconLeft={'navigation'}
                                        variant={'primary'}
                                        onClick={handleCreateDataset}
                                    >
                                        Create a Dataset
                                    </QuiButton>
                                </QuiBox>
                            }
                            isOpen={fakeDownloadOpen}
                            onClose={() => setFakeDownloadOpen(false)}
                        >
                            <QuiButton onClick={() => setFakeDownloadOpen(true)} iconRight={'download'}>
                                Download
                            </QuiButton>
                        </QuiPopover>
                    )}
                    {onClear != null && (
                        <QuiButton iconRight={'trash-2'} onClick={onClear}>
                            Clear
                        </QuiButton>
                    )}
                </QuiBox>
            </QuiBox>
            <QuiBox
                text="mono-text-lg"
                bg="background-neutral"
                border="stroke-base"
                borderRadius="md"
                padding="lg"
                gap="md"
                display="flex"
                flexDirection="column"
                className={classNames(styles.previewWrapper, rtlLanguages.has(language) ? styles.rtl : '')}
            >
                {children}
            </QuiBox>
        </QuiBox>
    );
}

export function PreviewWrapper({
    rules,
    responseStatus,
    children,
    previewData,
    onClear,
    hideCustomizeNerResults,
    handleCreateDataset,
}: PreviewWrapperProps) {
    const storeOptions = usePlaygroundContext();
    const selectedFile = useAtomValue(fileAtom, storeOptions);
    const blockTextEmpty = useAtomValue(blockTextContentEmptyAtom, storeOptions);

    if (responseStatus === 'error' && selectedFile == null) {
        return (
            <InternalWrapper
                onClear={onClear}
                rules={rules}
                hideCustomizeNerResults={hideCustomizeNerResults}
                handleCreateDataset={handleCreateDataset}
            >
                <QuiBox display="flex" alignItems="center" gap="sm" color="text-danger">
                    <QuiIcon size="sm" icon="alert-octagon" />
                    <span>An error occured, please try again.</span>
                </QuiBox>
            </InternalWrapper>
        );
    }

    if (responseStatus === 'loading' && selectedFile == null) {
        return (
            <InternalWrapper
                onClear={onClear}
                rules={rules}
                hideCustomizeNerResults={hideCustomizeNerResults}
                handleCreateDataset={handleCreateDataset}
            >
                <QuiBox display="flex" alignItems="center" gap="sm">
                    <QuiSpinner size="sm" />
                    <span>Loading preview...</span>
                </QuiBox>
            </InternalWrapper>
        );
    }

    if (
        (selectedFile == null && (!previewData || blockTextEmpty)) ||
        (selectedFile != null && typeof previewData === 'string' && previewData.length === 0)
    ) {
        return (
            <InternalWrapper
                onClear={onClear}
                rules={rules}
                hideCustomizeNerResults={hideCustomizeNerResults}
                handleCreateDataset={handleCreateDataset}
            >
                <QuiText size="text-md" color="text-base--disabled">
                    Results...
                </QuiText>
            </InternalWrapper>
        );
    }
    return (
        <InternalWrapper onClear={onClear} rules={rules} hideCustomizeNerResults={hideCustomizeNerResults} handleCreateDataset={handleCreateDataset}>
            {children}
        </InternalWrapper>
    );
}
