import { useIsMounted } from '@/hooks/useIsMounted';
import { createUserAPIKey } from '@/stores';
import { QuiBox, QuiButton, QuiSubmitButton, QuiTextField } from '@tonicai/ui-quinine';
import { Form } from 'react-final-form';
import { UserApiKey } from '@/types';
import { FORM_ERROR } from 'final-form';
import { Message } from '@/components/Message/Message';
import { instrumentation } from '@/instrumentation/instrumentation';

type UserAPIKeyFormProps = {
    handleCancel: () => void;
    setNewKey: (key: UserApiKey) => void;
};

export function UserAPIKeyForm({ handleCancel, setNewKey }: UserAPIKeyFormProps) {
    const isMounted = useIsMounted();

    return (
        <Form<{ name: string }>
            initialValues={{ name: '' }}
            onSubmit={async (values) => {
                return await createUserAPIKey(values.name)
                    .then((key) => {
                        instrumentation.createAPIKey(key.id);
                        if (isMounted()) {
                            setNewKey(key);
                        }
                        return undefined;
                    })
                    .catch(() => {
                        return {
                            [FORM_ERROR]: 'Could not create API key.',
                        };
                    });
            }}
        >
            {({ handleSubmit, submitError, values }) => (
                <form onSubmit={handleSubmit}>
                    <QuiBox display="flex" gap="md" flexDirection="column">
                        <QuiBox display="flex" gap="sm">
                            <QuiTextField style={{ flexGrow: 1 }} autoComplete="off" name="name" placeholder={'API Key name'} />
                            <QuiBox gap={'xs'} display={'flex'} alignSelf={'flex-end'}>
                                <QuiButton onClick={handleCancel} variant={'default'} iconLeft={'x'} />
                                <QuiSubmitButton variant={'primary'} iconLeft={'check'} disabled={values.name == null || values.name.length === 0} />
                            </QuiBox>
                        </QuiBox>

                        {submitError && <Message variant="error">{submitError ?? 'Something went wrong'}</Message>}
                    </QuiBox>
                </form>
            )}
        </Form>
    );
}
