import { QuiBadge, QuiBox, QuiButton, QuiIconEnum, QuiPopover } from '@tonicai/ui-quinine';
import { cloneElement, ComponentProps, ReactElement, ReactNode } from 'react';

type Props = {
    content: ReactElement<typeof Content>;
} & ComponentProps<typeof QuiPopover>;

export default function ChecklistPopover({ content, children, ...props }: Props) {
    return (
        <QuiPopover
            content={cloneElement(content, { onClose: props.onClose })}
            disableDismissOnOutsideClick
            hideArrow
            style={{
                backgroundColor: 'var(--qui-color-brand-purple-100)',
                border: 'solid 1px var(--qui-color-brand-purple-300)',
                padding: '.5em',
            }}
            {...props}
        >
            {children}
        </QuiPopover>
    );
}

type ContentProps = {
    title: ReactNode;
    content: ReactNode;
    onClose?: () => void;
};

function Content({ title, content, onClose }: ContentProps) {
    return (
        <QuiBox style={{ maxWidth: '350px' }} text="text-sm">
            <QuiBox display="flex">
                <QuiBox display="flex" alignItems="start">
                    <QuiBadge style={{ margin: '.5em' }} size="xs" variant="brand-purple-dark" iconLeft={QuiIconEnum.LifeBuoy} />
                    <QuiBox padding="xs" display="flex" flexDirection="column" gap="sm">
                        <QuiBox>{title}</QuiBox>
                        <QuiBox text="text-xs">{content}</QuiBox>
                    </QuiBox>
                </QuiBox>
                <QuiButton size="xs" variant="minimal" iconLeft={QuiIconEnum.X} onClick={onClose} />
            </QuiBox>
        </QuiBox>
    );
}
ChecklistPopover.Content = Content;
