import { QuiBox, QuiButton, QuiIconEnum, QuiTextField } from '@tonicai/ui-quinine';
import { useFieldArray } from 'react-final-form-arrays';

export function CustomEntityRegexList() {
    const fieldName = 'entries.regexes';
    const { fields } = useFieldArray(fieldName);
    const finalFieldIndex = fields.length ? fields.length - 1 : 0;

    return (
        <QuiBox display="flex" flexDirection="column" gap="md">
            <QuiBox weight="medium">Keywords, Phrases, or Regexes</QuiBox>
            <QuiBox color="text-subdued">For each entry, you can provide a regular expression that matches the values to include.</QuiBox>
            {fields.map((name: string, index: number) => {
                return (
                    <QuiBox display="flex" alignItems="center" gap="sm" key={name} margin="xs none">
                        <div style={{ marginRight: '1em' }}>{index + 1}</div>
                        <QuiTextField
                            style={{ width: '100%' }}
                            name={name}
                            // @ts-expect-error quinine doesn't know about onFocus event
                            onFocus={() => {
                                if (index === finalFieldIndex && finalFieldIndex < 9) {
                                    fields.push(undefined);
                                }
                            }}
                            onBlur={() => {
                                if (index !== finalFieldIndex && !fields.value[index]) {
                                    fields.remove(index);
                                }
                            }}
                        />
                        {index !== finalFieldIndex && (
                            <QuiButton
                                iconRight={QuiIconEnum.Trash}
                                onClick={() => {
                                    fields.remove(index);
                                }}
                            />
                        )}
                    </QuiBox>
                );
            })}
            {finalFieldIndex === 9 && <>You may add a max of 10 regexes</>}
        </QuiBox>
    );
}
